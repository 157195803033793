import React, { useState } from 'react';

const ExpressionSaveButton = ({ onSave, mode, navigate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
        await onSave();
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <div className="fixed top-[130px] left-[-30px] w-full flex justify-end">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <img src="/loading.gif" alt="Loading..." className="w-16 h-16" />
        </div>
      )}
      <div className="flex space-x-4">
        <button
          onClick={() => navigate('/expression')}
          className="w-[100px] h-[48px] bg-[#666B81] text-white rounded-[12px] text-center"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          목록가기
        </button>
        <button
          onClick={handleSave}
          className="w-[100px] h-[48px] bg-[#3677F6] text-white rounded-[12px] text-center"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 700,
          }}
          disabled={isLoading}
        >
          저장하기
        </button>
      </div>
    </div>
  );
};


export default ExpressionSaveButton;