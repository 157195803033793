import styles from "./OptionalTextField.module.css";

const OptionalTextField = ({ prop, prop1, value, onChange }) => {
  return (
    <div className={styles.textField}>
      <b className={styles.b}>
        <span>{prop}</span>
      </b>
      <input
        className={styles.field}
        placeholder={prop1}
        type="text"
        maxLength="15"
        autoCorrect="off"
        autoComplete="off"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default OptionalTextField;
