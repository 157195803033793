import styles from "./MustTextField.module.css";

const MustTextField = ({ prop, prop1, type = "text", value, onChange }) => {
  return (
    <div className={styles.textField}>
      <b className={styles.b}>
        <span>{prop}</span>
        <span className={styles.span}>*</span>
      </b>
      <input
        className={styles.field}
        placeholder={prop1}
        type={type}
        value={value} // Controlled input value
        onChange={onChange} // onChange handler to update parent component's state
        maxLength="15"
      />
    </div>
  );
};

export default MustTextField;
