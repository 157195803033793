import React from 'react';
import trashIcon from '../../assets/admin/icons/trash.png';

const ExpressionForm = ({ 
  expression, 
  onExpressionChange, 
  languages, 
  selectedLanguage, 
  onLanguageChange,
  onLanguageReset
}) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onExpressionChange({ 
      ...expression,
      [name]: value, 
    });
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    onLanguageChange(newLanguage);
  };

  const handleLanguageReset = () => {
    onLanguageReset(selectedLanguage); // 언어 및 관련 데이터를 초기화
  };

  // selectedLanguage에 따라 구분 번호를 결정하는 함수
  const getLanguageIndex = (language) => {
    switch (language) {
      case 'kor':
        return 1;
      case 'eng':
        return 2;
      case 'jpn':
        return 3;
      default:
        return '';
    }
  };

  const inputStyle = {
    height: '48px',
    padding: '18px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
  };

  return (
    <div className='mb-4 relative bg-white p-5 rounded-lg shadow-md'>
      {/* 구분 1, 2, 3... */}
      <h3
        className='text-left mb-4'
        style={{
          fontFamily: 'Pretendard',
          fontSize: '18px',
          fontWeight: 700,
        }}
      >
        구분 {getLanguageIndex(selectedLanguage)}
      </h3>

      <div className='flex items-center space-x-4'>
        {/* Language Section */}
        <div>
          <label 
            className="block text-left mb-2.5"
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            LANGUAGE
          </label>
          <select
            name="language"
            value={selectedLanguage || ''}
            onChange={onLanguageChange}
            className="border rounded-md mb-4"
            style={{
              width: '119px',
              height: '48px',
              padding: '4px 14px',
              borderRadius: '12px',
              border: '1px solid #E2E8EF',
            }}
            disabled
          >
            {languages.map((lang, index) => (
              <option key={index} value={lang}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* Trash Icon Button */}
        <button
          onClick={handleLanguageReset}
          className="absolute right-[20px] top-0 mt-4 mr-4"
          style={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
          }}
          disabled={!selectedLanguage}
        >
          <img src={trashIcon} alt="Delete" className="w-6 h-6" />
        </button>
      </div>

      {/* 표현언어 */}
      <label
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        1. 표현언어
      </label>
      <div className='flex items-center space-x-4 mb-4 mt-2.5'>
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          언어
        </label>
        <input
          type="text"
          name="expressive_language"
          placeholder="표현언어를 입력하세요."
          value={expression.expressive_language || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          의미
        </label>
        <input
          type="text"
          name="expressive_language_mean"
          placeholder="표현언어의 의미를 입력하세요."
          value={expression.expressive_language_mean || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
      </div>

      {/* 예문 */}
      <label
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        2. 예문
      </label>
      <div className='flex items-center space-x-4 mb-4 mt-2.5'>
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          예문
        </label>
        <input
          type="text"
          name="example_sentence"
          placeholder="예문을 입력하세요."
          value={expression.example_sentence || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          해석
        </label>
        <input
          type="text"
          name="example_sentence_mean"
          placeholder="예문의 해석을 입력하세요."
          value={expression.example_sentence_mean || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
      </div>

      {/* 동의어 */}
      <label
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        3. 동의어
      </label>
      <div className='flex items-center space-x-4 mb-4 mt-2.5'>
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          단어
        </label>
        <input
          type="text"
          name="synonyms"
          placeholder="동의어를 입력하세요."
          value={expression.synonyms || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          의미
        </label>
        <input
          type="text"
          name="synonyms_mean"
          placeholder="동의어의 뜻을 입력하세요."
          value={expression.synonyms_mean || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
      </div>

      {/* 반의어 */}
      <label
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        4. 반의어
      </label>
      <div className='flex items-center space-x-4 mb-4 mt-2.5'>
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          단어
        </label>
        <input
          type="text"
          name="antonyms"
          placeholder="반의어를 입력하세요."
          value={expression.antonyms || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
        <label 
          className="block w-[28px] text-left"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          의미
        </label>
        <input
          type="text"
          name="antonyms_mean"
          placeholder="반의어의 뜻을 입력하세요."
          value={expression.antonyms_mean || ''}
          onChange={handleInputChange}
          className="border rounded-md flex-grow"
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default ExpressionForm;