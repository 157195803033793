import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Btn.module.css";
import { useNavigate } from "react-router-dom";

const Btn = ({
  className = "",
  btnAlignSelf,
  btnBorder,
  btnFlex,
  btnBackground,
  prop,
  divWidth,
  divFontWeight,
  divColor,
  divMinWidth,
  to,
  onClick,
}) => {
  const navigate = useNavigate();
  const btnStyle = useMemo(() => {
    return {
      alignSelf: btnAlignSelf,
      border: btnBorder,
      flex: btnFlex,
      background: btnBackground,
    };
  }, [btnAlignSelf, btnBorder, btnFlex, btnBackground]);

  const divStyle = useMemo(() => {
    return {
      width: divWidth,
      fontWeight: divFontWeight,
      color: divColor,
      minWidth: divMinWidth,
    };
  }, [divWidth, divFontWeight, divColor, divMinWidth]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      navigate(to);
    }
  };

  return (
    <button
      className={[styles.btn, className].join(" ")}
      style={btnStyle}
      onClick={handleClick}
    >
      <div className={styles.div} style={divStyle}>
        {prop}
      </div>
    </button>
  );
};

Btn.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,

  /** Style props */
  btnAlignSelf: PropTypes.any,
  btnBorder: PropTypes.any,
  btnFlex: PropTypes.any,
  btnBackground: PropTypes.any,
  divWidth: PropTypes.any,
  divFontWeight: PropTypes.any,
  divColor: PropTypes.any,
  divMinWidth: PropTypes.any,
};

export default Btn;
