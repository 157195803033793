import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId, clearUserId } from "../actions";

const SocialApple = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const account = urlParams.get("account");
    const accessToken = urlParams.get("accessToken");
    const onProfile = urlParams.get("onprofile");
    const onBoard = urlParams.get("onboarding");
    const userId = urlParams.get("id");
    if (accessToken) {
      console.log(userId);
      console.log(accessToken);
      dispatch(setUserId(accessToken));
      navigate("/home");
    } else if (onProfile === "false" || account === "false") {
      navigate("/social-login", {
        state: {
          onBoard,
          userId,
        },
      });
    } else if (onBoard === "false") {
      navigate("/nickname", {
        state: {
          userId,
        },
      });
    }
  }, []);

  return <div>로그인 실패</div>;
};

export default SocialApple;
