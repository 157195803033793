import bgImage from "./bg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";

const Conversation = () => {
  const location = useLocation();
  const { script, id } = location.state || {};
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8";
  };

  const handleGoStudy = () => {
    navigate("/study");
  };

  const ProgressBar = ({ filledSections }) => {
    const totalSections = 6;
    const gapWidth = 4;

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (selectedDifficulty) {
      case "E":
        return "e.png";
      case "N":
        return "n.png";
      case "D":
        return "d.png";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  useEffect(() => {
    if (isModalVisible2) {
      const timer = setTimeout(() => {
        setisModalVisible2(false);
        navigate("/quizb");
      }, 2000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isModalVisible2]);

  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'
  const handleMarkAction = () => {
    setSelectedMark(!selectedMark);
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  // State to track the current selected image ("x" or "o") or none
  const [selected, setSelected] = useState(null);

  // Handle clicking on an image
  const handleClick = (image) => {
    if (selected === image) {
      // If the same image is clicked again, toggle it back to "before" state
      setSelected(null);
    } else {
      // Otherwise, set the clicked image as the selected one
      setSelected(image);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoNext = () => {
    navigate("/studypoint", { state: { id: id } });
  };

  // Step 1: Clean up the script by removing "\n" and splitting based on speakers.
  const cleanedScript = script.script.replace(/\n/g, "").split(/=(\/[A-Z]\/)/g);

  // Step 2: Create a helper function to format conversation.
  const formatConversation = (lines) => {
    const elements = [];
    let currentSpeaker = null;

    lines.forEach((line, index) => {
      if (line.startsWith("/")) {
        currentSpeaker = line; // Store the current speaker
      } else if (line.trim() !== "") {
        // Set alignment and color based on the current speaker
        const alignment =
          currentSpeaker === "/A/"
            ? "justify-start bg-[#fff] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0" // Left-aligned bubble
            : "justify-end bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-[18px]"; // Right-aligned bubble

        elements.push(
          <div
            key={index}
            className={`flex ${alignment} py-[10px] px-[16px] break-words max-w-[70%]`}
            style={{ minWidth: "fit-content" }}
          >
            <div className="text-[14px] leading-[160%] font-sans text-[#000]">
              {line.trim()} {/* The conversation text */}
            </div>
          </div>
        );
      }
    });
    return elements;
  };

  return (
    <div
      className="relative flex flex-col bg-[#fff] overflow-hidden"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        height: "100vh", // Ensure full viewport height
        width: "100vw", // Ensure full viewport width
        overflow: "hidden", // Prevent scrolling
      }}
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="absolute w-full h-full bg-black opacity-0 z-10"></div>
      {/* Header */}
      <div className="relative flex items-center px-[16px] h-[50px]">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] p-[10px] z-40">
          <img
            width="60"
            height="60"
            src="x.png"
            alt="icon"
            onClick={toggleModal}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-40 text-white font-sans text-center">
          Conversation
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "78%" }}
        >
          <img
            width="40"
            height="40"
            src="link.png"
            alt="icon"
            onClick={handleLink}
          />
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "88%" }}
        >
          <img
            width="45"
            height="45"
            src="ddd.png"
            alt="icon"
            onClick={toggleOverlay}
          />
        </div>
      </div>
      {/* Progress Bar */}
      <ProgressBar filledSections={1} />
      {/* Content */}
      <div className="flex justify-end flex-1 mr-[10px] mt-[10px] z-50">
        <LanguageSwitcher2></LanguageSwitcher2>
      </div>
      <div className="absolute top-[15%] left-1/2 transform -translate-x-1/2 flex flex-col items-start justify-start w-[90%] gap-[20px] z-40">
        {formatConversation(cleanedScript)}
      </div>
      {/* Footer */}
      <div className="fixed bottom-[15%] w-full px-[10px] z-50">
        <div className="absolute left-0 top-[78%] w-full flex flex-col items-center justify-center">
          <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoBack}
            >
              <img
                className="relative top-[50%] mb-[50px]"
                width="18"
                height="18"
                src="Group 1261155146I46_187;328_5268.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={toggleOverlay2}
            >
              <div className="absolute left-0 top-0 flex flex-col items-center justify-start gap-[8px]">
                <div className="relative w-[50px] h-[50px] shrink-0">
                  <img
                    className="relative left-0 top-0"
                    width="50"
                    height="50"
                    src="Group 1261155148I46_191;426_15471.png"
                  ></img>
                  <img
                    className="absolute left-[39.72%] right-[38.6%] top-[32.15%] bottom-[32.5%]"
                    width="10"
                    height="17"
                    src={getImageSrc()}
                  ></img>
                </div>
                <div className="self-stretch text-[12px] leading-[100%] font-sans text-[#fff] text-center">
                  난이도
                </div>
              </div>
            </div>
            <div
              className="relative w-[100px] h-[100px] shrink-0 flex"
              onClick={handleGoClick}
            >
              <img
                className="absolute top-[-30px]"
                width="100"
                height="100"
                src="play.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={handleMarkAction}
            >
              <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
              <div className="absolute left-[9px] top-[57px] w-[32px] h-[12px] text-[12px] leading-[100%] font-sans text-[#fff] text-center">
                북마크
              </div>
              <img
                className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[calc(50%+-9px)]"
                width="13"
                height="24"
                src={getMarkImage()}
              ></img>
            </div>
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoNext}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="Group 1261155146I46_201;328_5268.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            언어 설정
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          {/* Text Container 2 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            오류 신고
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalVisible && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={() => setModalVisible(false)}
        >
          <div
            className="w-[60%] h-[120px] flex flex-col rounded-[10px] overflow-hidden bg-[#faf8f6]"
            onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
          >
            {/* Modal Content */}
            <div className="flex-1 flex items-center justify-center pt-[20px] pb-[20px] px-0">
              <div className="text-[14px] leading-[155%] font-['Pretendard_Variable'] font-medium text-[#000] text-center">
                학습을 종료하시나요?
              </div>
            </div>
            {/* Button Row */}
            <div className="h-[46px] flex border-t border-[#c5d0dd]">
              <div className="flex-1 h-full flex items-center justify-center border-r border-[#c5d0dd]">
                <button
                  className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#666b81] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                  onClick={handleModalAction1}
                >
                  취소
                </button>
              </div>
              <div className="flex-1 h-full flex items-center justify-center">
                <button
                  className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#3677f6] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                  onClick={handleGoStudy}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conversation;
