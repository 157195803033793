import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Make sure to install react-toastify
import { useDispatch, useSelector } from "react-redux";
import { addItems, setItems } from "./actions";

const Express = () => {
  const [selected, setSelected] = useState({
    gender: null,
  });

  const handleSelect = (category, value) => {
    setSelected((prev) => ({
      ...prev,
      [category]: value,
    }));
  };
  const [data, setData] = useState([
    // Example items
    { item: "Item 1", type: "구동사" },
    { item: "Item 2", type: "관용구" },
    // Add more items as needed
  ]);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items); // Access items from Redux state
  const [page, setPage] = useState(1); // Manage page or data set state
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const loadingMessage = "Scroll Down for more!";
  const typeList = [
    "구동사",
    "관용구",
    "고정표현",
    "신조어",
    "슬랭",
    "문법적 구조",
    "약어",
    "속담",
    "숙어",
    "단어",
  ];
  const [type, setType] = useState("신조어");

  // Handler functions for navigation
  const goToHome = () => navigate("/home");
  const goToStudy = () => navigate("/study");
  const goToExpress = () => navigate("/express");
  const goToAI = () => navigate("/ai");
  const goToMyPage = () => navigate("/mypage");
  const overlayRef = useRef(null); // Ref for the overlay
  const goToVocabList = (item, type) => {
    navigate("/vocablist", {
      state: { item, type },
    });
  };

  const getRandomType = () => {
    const randomIndex = Math.floor(Math.random() * typeList.length);
    return typeList[randomIndex];
  };

  const [selectedGender, setSelectedGender] = useState(null);

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleFilter = (type, value) => {
    if (type === "gender") {
      setSelectedGender(value); // Store the selected gender
    }
  };

  const handleComplete = () => {
    const filteredItems = data.filter((item) => item.type === selectedGender);
    setData(filteredItems); // Update the state with filtered items
    toggleOverlay(); // Optionally close the overlay
  };

  const loadMoreData = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    // Simulate data fetching or duplication
    setTimeout(() => {
      if (page < 2) {
        const newItems = Array(5)
          .fill()
          .map((_, idx) => ({
            item: `Item ${items.length + idx + 1}`,
            type: getRandomType(),
          }));
        dispatch(addItems(newItems));
        setPage((prevPage) => prevPage); // Increment the page number
      } else {
        setHasMore(false);
        toast.info("End of data");
      }
      setLoading(false);
    }, 100);
  }, [loading, hasMore, page, items, dispatch]);

  // Variables to track scroll
  const lastScrollTopRef = useRef(
    window.pageYOffset || document.documentElement.scrollTop
  );
  const debounceTimerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    // Detect if the user is scrolling up
    if (currentScrollTop < lastScrollTopRef.current) {
      // Clear any existing timer
      clearTimeout(debounceTimerRef.current);

      // Set a new timer for holding position
      debounceTimerRef.current = setTimeout(() => {
        loadMoreData();
      }, 5000); // Adjust the time (2000 ms = 2 seconds) as needed
    }

    lastScrollTopRef.current = currentScrollTop;
  }, [loadMoreData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimerRef.current);
    };
  }, [handleScroll]);

  // Function to handle clicks outside the overlay
  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    if (items.length < 5) {
      loadMoreData();
    }
    if (items.length < 10) {
      loadMoreData();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] ">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative w-[360px] bg-[#faf8f6]">
        {/* Navigation Bar */}
        <div className="absolute left-0 bottom-0 w-full flex flex-col items-start justify-start">
          <div className="fixed bottom-0 left-0 w-full bg-[#fff] border-none z-50">
            <div className="flex flex-row items-start justify-between pt-[12px] px-[20px] pb-[16px]">
              <button
                className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
                onClick={goToHome}
              >
                <img
                  width="24"
                  height="24"
                  src="homeI12_83;216_3180.png"
                  alt="홈"
                />
                <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
                  홈
                </div>
              </button>
              <button
                className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
                onClick={goToStudy}
              >
                <img
                  width="24"
                  height="24"
                  src="pencilI12_192;216_3182.png"
                  alt="학습하기"
                />
                <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
                  학습하기
                </div>
              </button>
              <button
                className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
                onClick={goToExpress}
              >
                <img
                  width="24"
                  height="24"
                  src="expressionI12_613;216_3184.png"
                  alt="표현하기"
                />
                <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
                  표현하기
                </div>
              </button>
              <button
                className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
                onClick={goToAI}
              >
                <div className="relative w-[24px] h-[24px] shrink-0">
                  <img
                    className="absolute left-[1px] top-[2px]"
                    width="22"
                    height="20"
                    src="Group 1261155132I12_192;216_3186;216_3138.png"
                    alt="AI 맞춤학습"
                  />
                </div>
                <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
                  AI 맞춤학습
                </div>
              </button>
              <button
                className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
                onClick={goToMyPage}
              >
                <div className="relative w-[24px] h-[24px] shrink-0">
                  <img
                    className="absolute left-[1px] top-[1px]"
                    width="21"
                    height="21"
                    src="Group 1261155133I12_83;216_3194;216_3139.png"
                    alt="마이페이지"
                  />
                </div>
                <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
                  마이페이지
                </div>
              </button>
            </div>
          </div>
        </div>
        {/* Main Content */}
        <div className="relative w-full h-full flex justify-center">
          <div className="flex-grow relative w-full">
            <div className="absolute left-[271px] top-[103px] flex flex-row items-center justify-start gap-[5px]">
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] text-right whitespace-nowrap">
                최신순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                알파벳순
              </div>
            </div>

            <div className="relative left-[15px] top-[129px] w-[328px] flex flex-col items-start justify-start rounded-[20px] mb-[200px]">
              {items.map((dataItem, index) => (
                <div
                  key={index}
                  onClick={() => goToVocabList(dataItem.item, dataItem.type)}
                  className="self-stretch flex flex-row items-center justify-between py-[20px] px-[14px] bg-[#fff] border-b border-#e9ecef"
                >
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-[5px] h-[5px] shrink-0 bg-[#f67239] rounded-full"></div>
                    <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-justify whitespace-nowrap">
                      {dataItem.item}
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-justify whitespace-nowrap">
                    {dataItem.type}
                  </div>
                </div>
              ))}
              <div className="flex items-center justify-center w-[328px] h-[20px] bg-gray-100">
                <h1 className="text-center text-xl font-bold">
                  {loadingMessage}
                </h1>
              </div>
            </div>

            {/* Overlay */}
            <div
              ref={overlayRef}
              className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
                isOverlayVisible
                  ? "transform translate-y-0"
                  : "transform translate-y-full"
              }`}
              style={{ height: "80%", zIndex: 100 }}
            >
              {/* Title of the overlay */}
              <div className="p-4">
                <h2 className="text-lg font-bold">필터 설정</h2>
              </div>
              {/* Content of the overlay */}
              <div className="flex flex-wrap gap-[6px] justify-center">
                {[
                  "구동사",
                  "관용구",
                  "고정표현",
                  "신조어",
                  "슬랭",
                  "문법적 구조",
                  "약어",
                  "속담",
                  "숙어",
                  "단어",
                ].map((gender) => (
                  <div
                    key={gender}
                    className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                      selected.gender === gender
                        ? "border-[#3677f6] text-[#3677f6]"
                        : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                    }`}
                    onClick={() => handleSelect("gender", gender)}
                    style={{ flexBasis: "calc(33.333% - 6px)" }} // Make each item take approximately one-third of the row width
                  >
                    <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                      {gender}
                    </div>
                  </div>
                ))}
              </div>

              {/* Footer */}
              <div className="fixed bottom-0 w-full">
                <div className="flex-none pb-[20px] px-[16px]">
                  <button
                    className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
                    onClick={handleComplete}
                  >
                    설정 완료
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute left-0 top-[42px] w-[360px] h-[50px]">
              <div className="absolute left-[16px] top-[7px] w-[328px] flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                  표현하기
                </div>
                <div className="flex flex-row items-center justify-end gap-[8px]">
                  <div className="h-[36px] flex flex-row items-center justify-center">
                    <div className="relative w-[30px] h-[30px] shrink-0">
                      <div className="absolute left-0 right-0 top-0 bottom-0">
                        <img
                          className="absolute left-[3px] top-[3px]"
                          width="24"
                          height="24"
                          src="filter.png"
                          onClick={toggleOverlay}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative w-[36px] h-[36px] shrink-0 flex">
                      <div className="absolute left-0 top-0 w-[36px] h-[36px] border-[2px] border-solid border-[#000] rounded-full"></div>
                      <div className="absolute left-[9px] top-[9px] text-[16px] leading-[100%] font-sans font-semibold text-[#000] whitespace-nowrap">
                        Aa
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Express;
