import styles from "./TextField.module.css";

const TextField = ({ prop, prop1, prop2, onChange, type, className }) => {
  return (
    <div className={styles.textField}>
      <b className={styles.b}>
        <span>{prop}</span>
        <br></br>
        <span>{prop1}</span>
      </b>
      {/* <div className={styles.field}> */}
      <input
        className={`${className} ${styles.field}`}
        placeholder={prop2}
        type={type}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default TextField;
