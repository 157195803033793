import React from "react";

const Modal2 = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
      <div className="w-[60%] h-[20%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-hidden animate-slideIn">
        <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
          🚧 준비중
        </div>
        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
          조금만 기다려주세요!
        </div>
        <button
          className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal2;
