import { useState, useEffect } from "react";
import BottomButtons from "../logincomponents/BottomButtons";
import FormForm from "../logincomponents/FormForm";
import TextField from "../logincomponents/TextField";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Login1 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState(""); // Track the user's email
  const [emailError, setEmailError] = useState("");
  const [animation, setAnimation] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  const handleInputChange = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(value);
    if (emailRegex.test(value)) {
      setEmailError(""); // Clear error if valid email
    } else if (value === "") {
      setEmailError("error");
    } else {
      setEmailError("올바른 이메일을 입력해주세요");
    }
  };

  // Function to check if user is registered using the API
  const checkUserRegistration = async () => {
    try {
      setLoading(true); // Start loading
      const response = await fetch(`${apiUrl}/auth/check-user?userid=${email}`);
      const data = await response.json();

      if (response.ok && data.exists) {
        navigate("/login2", { state: { email } }); // Redirect to the password page if user exists
      } else {
        navigate("/register", { state: { email } }); // Redirect to the registration page if user does not exist
      }
    } catch (error) {
      console.error("Error checking user registration:", error);
      toast.error("서버에 문제가 발생했습니다. 다시 시도해주세요.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleButtonClick = () => {
    if (!emailError && email) {
      checkUserRegistration(); // Check if user is registered when button is clicked
    } else {
      const toastId = "email-error";
      setAnimation("animate-shake border-red-500"); // Trigger shake animation for error
      if (!toast.isActive(toastId)) {
        // Show error toast if email is invalid
        toast.error("올바른 이메일을 입력해주세요", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
        });
      }
    }
  };

  useEffect(() => {
    // Reset animation after applying
    if (animation) {
      const timer = setTimeout(() => {
        setAnimation("");
      }, 500); // Duration of the shake animation
      return () => clearTimeout(timer);
    }
  }, [animation]);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log(email);
  }, [email]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff] ">
      <div className="relative min-x-[360px] w-full bg-[#fff] ">
        <div className="mb-[4vh]"></div>
        {/* Header */}
        <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff] mb-[3vh]">
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px]">
            <img
              width="10"
              height="16"
              src="back.png"
              alt="icon"
              onClick={handleGoBack}
            />
          </div>
        </div>
        <div className="mb-[1vh]"></div>
        <FormForm>
          <TextField
            prop="로그인 시 사용하는"
            prop1="이메일을 입력해주세요"
            onChange={handleInputChange}
            type="text"
            className={animation}
          />
        </FormForm>
        <div className="fixed bottom-0 w-full">
          <BottomButtons
            prop="아이디 찾기"
            prop1={loading ? "로딩 중..." : "다음"} // Show loading state if applicable
            to="/lost-id1" // No static link since we're handling it programmatically
            onClick={handleButtonClick}
          />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login1;
