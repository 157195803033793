import Btn from "./Btn";
import styles from "./BBtn.module.css";

const BBtn = ({ prop, to, onClick }) => {
  return (
    <div className={styles.findIdButton}>
      <Btn
        btnAlignSelf="unset"
        btnBorder="unset"
        btnFlex="1"
        btnBackground="linear-gradient(64.95deg, #61e3eb, #3677f6)"
        prop={prop}
        divWidth="unset"
        divFontWeight="bold"
        divColor="#fff"
        divMinWidth="28px"
        to={to}
        onClick={onClick}
      />
    </div>
  );
};

export default BBtn;
