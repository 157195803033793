import { useState, useEffect } from "react";
import BottomButtons from "../logincomponents/BottomButtons";
import FormForm from "../logincomponents/FormForm";
import TextField from "../logincomponents/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId, clearUserId } from "../actions";

const Login2 = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const userId = email;
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSetUserId = (userId) => {
    dispatch(setUserId(userId));
  };

  const handleInputChange = (value) => {
    setPassword(value);
  };

  // Function to update profile with HTTP POST
  const updateProfile = async () => {
    console.log("아이디 ", userId);
    console.log("비번 ", password);
    const data = {
      userid: userId,
      password: password,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.onboarding);
        const accessToken = result.accessToken;
        handleSetUserId(accessToken);
        if (result.onboarding) {
          navigate("/home", {
            state: { accessToken },
          });
        } else {
          navigate("/nickname", {
            state: { userId },
          });
        }
      } else {
        console.error("Failed to update profile:", response.statusText);
        // alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("An error occurred while updating the profile.");
    }
  };
  useEffect(() => {
    console.log(password);
  }, [password]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff]">
      <div className="relative min-x-[360px] w-full bg-[#fff]">
        <div className="mb-[4vh]"></div>
        {/* Header */}
        <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff]">
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px]">
            <img
              width="10"
              height="16"
              src="back.png"
              alt="icon"
              onClick={handleGoBack}
            />
          </div>
        </div>
        <div className="mb-[1vh]"></div>
        {/* Progress Bar */}
        {/* <div className="w-full h-1 bg-gray-200 mb-[3vh]">
          <div className="w-0/4 h-full bg-blue-500"></div>
        </div> */}
        <FormForm>
          <TextField
            prop1="비밀번호를 입력해주세요"
            type="password"
            onChange={handleInputChange}
          />
        </FormForm>
        <div className="fixed bottom-0 w-full">
          <BottomButtons
            prop="비밀번호 찾기"
            prop1="다음"
            to="/lost-pw1"
            to1={null}
            onClick={updateProfile}
          />
        </div>
      </div>
    </div>
  );
};

export default Login2;
