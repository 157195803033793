import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BookMark = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoStudy = () => {
    navigate("/studyonboard", {
      state: {
        script: {
          id: 2332,
          language: "eng",
          background:
            "Junho (INTJ) and Mina (ESFP) are colleagues working at the same company. Junho has a quiet and systematic personality, while Mina is lively and spontaneous. The two accidentally met in the company parking lot.",
          humancount: 2,
          humanA: "Junho(INTJ)",
          humanB: "Mina(ESFP)",
          humanC: "",
          humanD: "",
          script:
            "=/A/Oh, Mina. You're driving to work.\n=/B/Junho! Yes, it's my first time today. Isn't it exciting?\n=/A/Hmm... I drive every day. It's efficient.\n=/B/Wow! I want to drive every day from now on. A morning drive, how refreshing!",
          note: "",
          type: "easy",
          createdAt: "2024-09-06T10:13:27.252Z",
          deletedAt: null,
        },
        id: "2332",
      },
    });
  };
  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    toggleOverlay(); // Optionally close the overlay
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6]">
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "80%",
            zIndex: 100,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          {/* Title of the overlay */}
          <div className="p-4">
            <h2 className="text-lg font-bold">필터 설정</h2>
          </div>
          {/* Content of the overlay */}
          <div className="flex flex-wrap gap-[6px] justify-center">
            {typeList.map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selectedGender === gender
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("gender", gender)}
                style={{ flexBasis: "calc(33.333% - 6px)" }}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className="fixed bottom-0 w-full">
            <div className="flex-none pb-[20px] px-[16px]">
              <button
                className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
                onClick={handleComplete}
              >
                설정 완료
              </button>
            </div>
          </div>
        </div>

        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start p-[10px]"
                onClick={handleGoBack}
              >
                <img width="8" height="16" src="back.png"></img>
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                북마크
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0">
                  <div className="absolute left-0 right-0 top-0 bottom-0">
                    <img
                      className="absolute left-[3px] top-[3px]"
                      width="24"
                      height="24"
                      src="filter.png"
                      onClick={toggleOverlay}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          <div className="flex flex-col items-start justify-start py-[10px] px-[16px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[5px]">
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] text-right whitespace-nowrap">
                최신순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                알파벳순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                별점순
              </div>
            </div>
          </div>
          {/* item1 */}
          <div
            className="w-full flex flex-col items-start justify-start bg-[#fff]"
            onClick={handleGoStudy}
          >
            <div className="w-full h-[98px] shrink-0 flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
              <div className="flex flex-col items-start justify-start gap-[17px]">
                <div className="flex flex-col items-start justify-start gap-[12px]">
                  <div className="flex flex-row items-center justify-center">
                    <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                      Flirting Relationship
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000]">
                    로맨틱한 상황에서 플러팅하기
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[3px]">
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    출퇴근
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    산책
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    건강식
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    한식
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-end justify-between">
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <img width="15" height="15" src="star.png"></img>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                    4.5
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px]">
                  <div className="flex flex-row items-center justify-start bg-[#e9ecef] rounded-[33px]">
                    <div className="flex flex-row items-center justify-center py-[4px] px-[6px]">
                      <div className="text-[10px] leading-[100%] font-sans font-bold text-[#666b81] whitespace-nowrap">
                        MBTI
                      </div>
                    </div>
                  </div>
                  <div className="w-[40px] shrink-0 flex flex-row items-center justify-start bg-[#e0fef5] rounded-[33px]">
                    <div className="flex flex-row items-center justify-center py-[4px] px-[6px]">
                      <div className="text-[10px] leading-[100%] font-sans font-bold text-[#61e3eb] whitespace-nowrap">
                        EAZY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMark;
