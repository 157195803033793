import axios from 'axios';
import API_BASE_URL from '../../config';

// customize Manage 데이터 생성
export const createManage = async (manageData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/customize/manage`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error creating manage:', error);
    throw error;
  }
};

// situation Manage 데이터 생성
export const situationCreateManage = async (manageData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/situation/manage`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error creating manage:', error);
    throw error;
  }
};

// psychology Manage 데이터 생성
export const psychologyCreateManage = async (manageData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/psychology/manage`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error creating manage:', error);
    throw error;
  }
};

// expression Manage 데이터 생성
export const expressionCreateManage = async (manageData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/expression/manage`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error creating manage:', error);
    throw error;
  }
};

// 특정 customize Manage 데이터 가져오기 (수정용)
export const fetchManage = async (managerid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/customize/manage/${managerid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manage:', error);
    throw error;
  }
};

// 특정 situation Manage 데이터 가져오기 (수정용)
export const situationFetchManage = async (managerid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/situation/manage/${managerid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manage:', error);
    throw error;
  }
};

// 특정 psychology Manage 데이터 가져오기 (수정용)
export const psychologyFetchManage = async (managerid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/psychology/manage/${managerid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manage:', error);
    throw error;
  }
};

// 특정 expression Manage 데이터 가져오기 (수정용)
export const expressionFetchManage = async (managerid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expression/manage/${managerid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manage:', error);
    throw error;
  }
};

// customize Manage 데이터 업데이트
export const updateManage = async (managerid, manageData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/customize/manage/${managerid}`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error updating manage:', error);
    throw error;
  }
};

// situation Manage 데이터 업데이트
export const situationUpdateManage = async (managerid, manageData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/situation/manage/${managerid}`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error updating manage:', error);
    throw error;
  }
};

// psychology Manage 데이터 업데이트
export const psychologyUpdateManage = async (managerid, manageData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/psychology/manage/${managerid}`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error updating manage:', error);
    throw error;
  }
};

// expression Manage 데이터 업데이트
export const expressionUpdateManage = async (managerid, manageData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/expression/manage/${managerid}`, manageData);
    return response.data;
  } catch (error) {
    console.error('Error updating manage:', error);
    throw error;
  }
};

// 모든 customize Manage 데이터 리스트 가져오기 (목록 보기 용)
export const fetchCustomizeList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/customize/manage`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customize list:', error);
    throw error;
  }
};

// 모든 situation Manage 데이터 리스트 가져오기 (목록 보기 용)
export const situationFetchCustomizeList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/situation/manage`);
    return response.data;
  } catch (error) {
    console.error('Error fetching situation list:', error);
    throw error;
  }
};

// 모든 psychology Manage 데이터 리스트 가져오기 (목록 보기 용)
export const psychologyFetchCustomizeList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/psychology/manage`);
    return response.data;
  } catch (error) {
    console.error('Error fetching psychology list:', error);
    throw error;
  }
};

// 모든 psychology Manage 데이터 리스트 가져오기 (목록 보기 용)
export const expressionFetchCustomizeList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expression/manage`);
    return response.data;
  } catch (error) {
    console.error('Error fetching psychology list:', error);
    throw error;
  }
};