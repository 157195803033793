import React, { useState } from "react";
import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import PhoneVerification from "../logincomponents/PhoneVerification";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import styles from "./SocialLogin.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const SocialLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onBoard, userId } = location.state || {};
  console.log("onBoard:", onBoard);
  console.log("userId:", userId);
  const [recommender, setRecommender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ageConsent, setAgeConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    // Remove non-digit characters
    const cleaned = value.replace(/\D/g, "");

    // Check if the cleaned number is 11 digits long
    if (cleaned.length === 11) {
      // Remove the leading zero and add +82
      return `+82${cleaned.substring(1)}`;
    }

    // Default case: just return the cleaned number with +82
    return `+82${cleaned}`;
  };

  const policyStyle = {
    fontSize: "12px",
    lineHeight: "1.5",
    textAlign: "center" /* Center text inside the div */,
    margin: "10px auto" /* Horizontally center within its parent */,
  };

  const updateProfile = async () => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    console.log(formattedPhoneNumber);
    const data = {
      userid: userId, // replace with your actual userId
      recommender: recommender, // replace with your actual recommender
      marketingConsent: marketingConsent, // replace with actual consent value
      phoneNumber: formattedPhoneNumber, // replace with actual phone number
    };
    console.log("추천인", recommender);
    console.log(marketingConsent);
    console.log(phoneNumber);

    try {
      const response = await fetch(`${apiUrl}/auth/complete-profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Profile updated successfully:", result);
        navigate("/nickname", {
          state: {
            userId,
          },
        });
      } else {
        console.error("Failed to update profile:", response.statusText);
        // alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("An error occurred while updating the profile.");
    }
  };
  const handleConditions = () => {
    navigate("/conditions");
  };
  const handleTerms = () => {
    navigate("/terms");
  };
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff]">
      <div className="relative min-x-[360px] w-full bg-[#fff]">
        <ReactiveOuter>
          <FormTop>
            <Title
              titleGap="40%"
              frameDivWidth="unset"
              prop="회원가입"
              bAlignSelf="unset"
              bHeight="unset"
              bOpacity="unset"
              bMinWidth="101px"
              bDisplay="inline-block"
              to="/welcome"
            />
            <FormForm>
              <MustTextField
                prop="이름"
                prop1="이름을 입력하세요"
              ></MustTextField>
              <PhoneVerification
                phoneNumber={phoneNumber} // Pass phoneNumber state
                onPhoneNumberChange={setPhoneNumber}
              ></PhoneVerification>
              <OptionalTextField
                prop="추천인 계정 입력"
                prop1="이름"
                value={recommender}
                onChange={(e) => setRecommender(e.target.value)}
              ></OptionalTextField>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center justify-between">
                  <b className="mr-2">
                    만 14세 이상입니다{" "}
                    <span style={{ color: "var(--color-tomato)" }}>*</span>
                  </b>
                  <input
                    type="checkbox"
                    checked={ageConsent}
                    onChange={(e) => setAgeConsent(e.target.checked)} // Set 만 14세 이상입니다 in state
                  />
                </div>
                <div className="flex items-center justify-between">
                  <b className="mr-2">마케팅 수신 동의(선택)</b>
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)} // Set 마케팅 수신 동의(선택) in state
                  />
                </div>
              </div>
            </FormForm>
          </FormTop>
        </ReactiveOuter>
        <div className="h-[20vh]"></div>
        <div className="w-full">
          <div style={policyStyle}>
            <a className="underline" onClick={handleConditions}>
              <b>이용 약관</b>
            </a>
            과{" "}
            <a className="underline" onClick={handleTerms}>
              <b>개인정보 처리방침</b>
            </a>
            에 동의시 회원가입을 진행해주세요
          </div>
          <BBtn prop={"회원가입"} onClick={updateProfile} />
        </div>
        <div className="h-[2vh]"></div>
      </div>
    </div>
  );
};

export default SocialLogin;
