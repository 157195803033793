import axios from 'axios';
import API_BASE_URL from '../../config';

// 단일 customize Quiz 생성
export const createQuiz = async (quizData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/customize_quiz`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error creating quiz:', error);
        throw error;
    }
};

// 단일 situation Quiz 생성
export const situationCreateQuiz = async (quizData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/situation_quiz`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error creating quiz:', error);
        throw error;
    }
};

// 단일 psychology Quiz 생성
export const psychologyCreateQuiz = async (quizData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/psychology_quiz`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error creating quiz:', error);
        throw error;
    }
};

// 여러 customize Quiz 생성
export const createMultipleQuizzes = async (quizzesData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/customize_quiz/multicreate`, quizzesData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple quizzes:', error);
        throw error;
    }
};

// 여러 situation Quiz 생성
export const situationCreateMultipleQuizzes = async (quizzesData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/situation_quiz/multicreate`, quizzesData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple quizzes:', error);
        throw error;
    }
};

// 여러 psychology Quiz 생성
export const psychologyCreateMultipleQuizzes = async (quizzesData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/psychology_quiz/multicreate`, quizzesData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple quizzes:', error);
        throw error;
    }
};

// 특정 customize Quiz 가져오기
export const fetchQuiz = async (quizId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/customize_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching quiz:', error);
        throw error;
    }
};

// 특정 situation Quiz 가져오기
export const situationFetchQuiz = async (quizId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/situation_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching quiz:', error);
        throw error;
    }
};

// 특정 psychology Quiz 가져오기
export const psychologyFetchQuiz = async (quizId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/psychology_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching quiz:', error);
        throw error;
    }
};

// scriptId로 모든 customize Quiz 가져오기
export const fetchQuizzesByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/customize_quiz`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quizzes by scriptId:', error);
        throw error;
    }
};

// scriptId로 모든 situation Quiz 가져오기
export const situationFetchQuizzesByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/situation_quiz`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quizzes by scriptId:', error);
        throw error;
    }
};

// scriptId로 모든 psychology Quiz 가져오기
export const psychologyFetchQuizzesByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/psychology_quiz`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quizzes by scriptId:', error);
        throw error;
    }
};

// customize Quiz 업데이트
export const updateQuiz = async (quizId, quizData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/customize_quiz/${quizId}`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error updating quiz:', error);
        throw error;
    }
};

// situation Quiz 업데이트
export const situationUpdateQuiz = async (quizId, quizData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/situation_quiz/${quizId}`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error updating quiz:', error);
        throw error;
    }
};

// psychology Quiz 업데이트
export const psychologyUpdateQuiz = async (quizId, quizData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/psychology_quiz/${quizId}`, quizData);
        return response.data;
    } catch (error) {
        console.error('Error updating quiz:', error);
        throw error;
    }
};

// Quiz 삭제
export const deleteQuiz = async (quizId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/customize_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting quiz:', error);
        throw error;
    }
};

// situation Quiz 삭제
export const situationDeleteQuiz = async (quizId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/situation_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting quiz:', error);
        throw error;
    }
};

// psychology Quiz 삭제
export const psychologyDeleteQuiz = async (quizId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/psychology_quiz/${quizId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting quiz:', error);
        throw error;
    }
};

