import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import Navigation from '../../components/admin/Navigation';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';

const LearnSituation = () => {
  const navigate = useNavigate();
  // 상태 정의
  const [data, setData] = useState([]); // 목록 데이터를 저장할 상태
  const [totalItems, setTotalItems] = useState(0); // 전체 항목 수
  const [title, setSearchTerm] = useState(''); // 검색어
  const [filter, setFilter] = useState({ // 필터 상태 초기값 설정
    mbti: '전체',
    age: '전체',
    sex: '전체',
    job: '전체',
    love: '전체',
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // 정렬 설정
  const [selectedRows, setSelectedRows] = useState([]); // 선택된 행
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const itemsPerPage = 20; // 페이지 당 항목 수
  const [metaData, setMetaData] = useState(null); // 메타 데이터

  // 페이지네이션을 위한 기본 데이터 가져오는 함수
  const fetchDefaultPageData = useCallback(async (page, limit) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/situation/managerFilter/`, {
        params: { page, limit },
      });

      setData(response.data.data);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Failed to fetch default data:', error);
    }
  }, []);

  // 필터를 서버에 적용하는 fetchPageData 함수
  const fetchPageData = useCallback(async (page, limit) => {
    try {
      const params = {
        page,
        limit,
        title,
        mbti: filter.mbti === '전체' ? '' : filter.mbti,
        age: filter.age === '전체' ? '' : filter.age,
        sex: filter.sex === '전체' ? '' : filter.sex,
        job: filter.job === '전체' ? '' : filter.job,
        love: filter.love === '전체' ? '' : filter.love,
      };

      const response = await axios.get(`${API_BASE_URL}/situation/managerFilter`, {
        params,
      });

      console.log(response.data); // 전체 응답 데이터를 확인

      let sortedData = response.data.data;

      // 정렬 로직 추가
      if (sortConfig.key) {
        sortedData = sortedData.sort((a, b) => {
          if (sortConfig.direction === 'ascending') {
            return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
            return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
        });
      }

      setData(sortedData);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }, [title, filter, sortConfig]); // 종속성 배열에 `sortConfig` 추가

  // 검색 버튼 클릭 핸들러
  const handleSearch = () => {
    console.log('Search button clicked');
    console.log('title:', title); // 필터 상태 확인
    console.log('Current filters:', filter); // 필터 상태 확인

    setCurrentPage(0); // 검색 시 페이지를 첫 페이지로 리셋
    fetchPageData(1, itemsPerPage); // 검색 시 필터 조건으로 데이터 가져오기
  };

  // 값 매핑 함수: 영어 값으로 변환 (저장 시 사용)
  const mapToEnglishValue = (category, value) => {
    // `metaData`에 해당 카테고리가 있는지 확인
    if (!metaData[category]) {
      console.error(`Category ${category} not found in metaData`);
      return value; // 변환할 값이 없으면 원래 값을 반환
    }

    const mappedValue = metaData[category][value]; // 한글 값을 영어로 변환
    if (!mappedValue) {
      console.error(`Value ${value} not found in category ${category}`);
      return value; // 변환할 값이 없으면 원래 값을 반환
    }

    return mappedValue; // 변환된 영어 값 반환
  };

  // 값 매핑 함수: 한국어로 변환 (화면 표시 시 사용)
  const mapToKoreanLabel = (category, value) => {
    const categoryMap = metaData[category];
    return categoryMap ? Object.keys(categoryMap).find(key => categoryMap[key] === value) || value : value;
  };

  // 필터 버튼 클릭 시 데이터 초기화 후 서버에서 필터링된 데이터 가져오기
  const handleFilterButtonClick = (filterType, item) => {
    const englishValue = mapToEnglishValue(filterType, item);

    // 필터링 값 확인을 위한 콘솔 로그
    console.log(`Filter Type: ${filterType}`);
    console.log(`Selected Item (Korean): ${item}`);
    console.log(`Mapped English Value: ${englishValue}`);

    setFilter(prevFilter => ({
      ...prevFilter,
      [filterType]: prevFilter[filterType] === englishValue ? '전체' : englishValue
    }));
  };

  // 컴포넌트 마운트 시 초기 데이터 가져오기
  useEffect(() => {
    fetchDefaultPageData(1, itemsPerPage); // 페이지네이션을 위해 초기 페이지를 1로 설정
  }, [fetchDefaultPageData]); // `fetchDefaultPageData`를 종속성 배열에 추가

  // 메타 데이터 가져오기 (초기 로드 시)
  useEffect(() => {
    fetch('/select_kor_eng.json')
      .then(response => response.json())
      .then(data => setMetaData(data)) // 메타 데이터 설정
      .catch(error => console.error('Error fetching metaData:', error));
  }, []);


  // 페이지 클릭 핸들러 (페이지 변경 시)
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // 선택한 페이지 설정
    fetchPageData(data.selected + 1, itemsPerPage);  // 페이지 변경 시에도 데이터 가져오기
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending'; // 기본 정렬 방향
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'; // 동일 키 클릭 시 방향 반전
    }
    setSortConfig({ key, direction }); // 정렬 설정 업데이트
    
    // 정렬된 데이터를 다시 가져오기 위해 fetchPageData 호출
    fetchPageData(1, itemsPerPage); // 정렬 후 첫 페이지를 가져옵니다.
  };

  // 행 선택 핸들러 (체크박스 선택)
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id) // 이미 선택된 경우 제거
        : [...prevSelected, id] // 선택된 경우 추가
    );
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    try {
      await Promise.all(selectedRows.map(id => axios.delete(`${API_BASE_URL}/situation/manage/${id}`))); // 선택된 항목 삭제
      setData((prevData) => prevData.filter((item) => !selectedRows.includes(item.id))); // 데이터 갱신
      setSelectedRows([]); // 선택 초기화
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  };

  // 모든 행 선택/해제 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]); // 모든 선택 해제
    } else {
      setSelectedRows(data.map(item => item.id)); // 모든 행 선택
    }
  };

  // 스크립트 생성 핸들러
  const handleCreateScript = async () => {

    try {
      const manageData = {
        title: '',
        sex: '',
        age: '',
        job: '',
        love: '',
        mbti: '',
        partnermbti: '',
        countrycode: '',
        hobby: '',
        used: false,
        imageurl: '',
        memo: '',
        link: '',
        tag: []
      };
  
      // `managerId`를 state로 전달하며 `CustomizeManage` 페이지로 이동
      navigate(`/situation/manage/create`);
    } catch (error) {
      console.error('Failed to create manager:', error);
    }
  };

  // 스크립트 수정 핸들러
  const handleEditScript = (managerId) => {
    navigate(`/situation/manage/edit/${managerId}`); // edit 모드로 이동
  };

  if (!metaData || Object.keys(metaData).length === 0) {
    return <div>Loading...</div>; // 메타 데이터 로딩 중
  }

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div 
        style={{
          padding: '26px 30px', // 위아래 26px, 좌우 30px 여백을 줍니다.
        }}
      >
        
        {/* 제목 표시 */}
        <h1 
          className="font-bold uppercase"
          style={{
            width: '140px',
            height: '28px',
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'left',
          }}
        >
          Situation
        </h1>
  
        {/* 검색 및 필터 영역 */}
        <div 
          className="bg-white p-5 rounded-lg shadow-md"
          style={{
            margin: '26px 0px 20px 0', // 위아래 26px, 좌우 30px 여백을 줍니다.
          }}  
        >
      
          {/* 타이틀 검색 섹션 */}
          <div>
            <label 
              className="block"
              style={{
                fontFamily: 'Pretendard Variable',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '14px',
                textAlign: 'left',
              }}
            >
              타이틀 검색
            </label>  
            <div className="flex items-center space-x-3 mt-2.5">
              {/* 검색어 입력 필드 */}
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                value={title}
                onChange={(e) => setSearchTerm(e.target.value)} // 입력 변경 시 상태 업데이트
                className="border flex-grow h-[48px]"
                style={{
                  padding: '18px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                }}
              />
              {/* 검색 버튼 */}
              <button 
                onClick={handleSearch} 
                className="w-[100px] h-[50px] bg-[#3677F6] text-white ml-2"
                style={{
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '14px',
                }}
              >
                검색어 검색
              </button>
            </div>
          </div>
  
          {/* 필터 검색 섹션 */}
          <div className='mt-4'>
            <div className="space-y-4">
  
              {/* MBTI 필터 */}
              <div>
                <label 
                  className="block mb-3.5"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '14px',
                    textAlign: 'left',
                  }}
                >
                  MBTI
                </label>
                <div className="flex flex-wrap">
                  {metaData.mbti && Object.keys(metaData.mbti).map((item) => (
                    <button
                      key={item}
                      onClick={() => handleFilterButtonClick('mbti', item)}
                      className={`m-1 px-4 py-2 rounded-full border ${filter.mbti === mapToEnglishValue('mbti', item) ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]' : 'bg-white border-[#E2E8EF] text-black'}`}
                      style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, lineHeight: '14px' }}>
                      {item}
                    </button>
                  ))}
                </div>
              </div>
  
              {/* 연령대 및 성별 필터 */}
              <div className='flex space-x-8'>
                <div className='w-1/2'>
                  <label 
                    className="block mb-3.5"
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '14px',
                      textAlign: 'left',
                    }}
                  >
                    연령대
                  </label>
                  <div className="flex flex-wrap">
                    {metaData.age && Object.keys(metaData.age).map((item) => (
                      <button
                        key={item}
                        onClick={() => handleFilterButtonClick('age', item)}
                        className={`m-1 px-4 py-2 rounded-full border ${filter.age === mapToEnglishValue('age', item) ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]' : 'bg-white border-[#E2E8EF] text-black'}`}
                        style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, lineHeight: '14px' }}>
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
                {/* 성별 필터 */}
                <div className='w-1/2'>
                  <label 
                    className="block mb-3.5"
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '14px',
                      textAlign: 'left',
                    }}
                  >
                    성별
                  </label>
                  <div className="flex flex-wrap">
                    {metaData.sex && Object.keys(metaData.sex).map((item) => (
                      <button
                        key={item}
                        onClick={() => handleFilterButtonClick('sex', item)}
                        className={`m-1 px-4 py-2 rounded-full border ${filter.sex === mapToEnglishValue('sex', item) ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]' : 'bg-white border-[#E2E8EF] text-black'}`}
                        style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, lineHeight: '14px' }}>
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {/* 직업 및 연애 상태 필터 그리고 필터 검색 버튼 */}
              <div className="flex space-x-8">
                <div className='w-1/2'>
                  <label 
                    className="block mb-3.5"
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '14px',
                      textAlign: 'left',
                    }}
                  >
                    직업
                  </label>
                  <div className="flex flex-wrap">
                    {metaData.job && Object.keys(metaData.job).map((item) => (
                      <button
                        key={item}
                        onClick={() => handleFilterButtonClick('job', item)}
                        className={`m-1 px-4 py-2 rounded-full border ${filter.job === mapToEnglishValue('job', item) ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]' : 'bg-white border-[#E2E8EF] text-black'}`}
                        style={{ 
                          fontFamily: 'Pretendard', 
                          fontSize: '14px', 
                          fontWeight: 500, 
                          lineHeight: '14px' 
                        }}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
  
                {/* 연애 상태 필터 */}
                <div className='w-1/2 flex'>
                  <div className='flex-grow'>
                    <label 
                      className="block mb-3.5"
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        textAlign: 'left',
                      }}
                    >
                      연애 상태
                    </label>
                    <div className="flex flex-wrap">
                      {metaData.love && Object.keys(metaData.love).map((item) => (
                        <button
                          key={item}
                          onClick={() => handleFilterButtonClick('love', item)}
                          className={`m-1 px-4 py-2 rounded-full border ${filter.love === mapToEnglishValue('love', item) ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]' : 'bg-white border-[#E2E8EF] text-black'}`}
                          style={{ 
                            fontFamily: 'Pretendard', 
                            fontSize: '14px', 
                            fontWeight: 500, 
                            lineHeight: '14px' 
                          }}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className='flex items-end justify-end'>
                    {/* 검색 버튼, 필터 적용 시 검색 */}
                    <button 
                      onClick={handleSearch} 
                      className="w-[100px] h-[50px] bg-[#3677F6] text-white"
                      style={{
                        padding: '18px 14px',
                        borderRadius: '12px',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '14px',
                      }}
                    >
                      필터 검색
                    </button>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          {/* 목록 헤더 */}
          <div className="flex justify-between items-center">
            
            {/* 검색 결과 및 선택 항목 수 표시 */}
            <div className="text-lg mt-5 mb-7 ml-5">
              <label 
                className="text-xl font-bold text-[#0E111F] mr-5"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '18px',
                  fontWeight: 700,
                  textAlign: 'left',
                }}
              >
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
              </label>
            </div>
            {/* 액션 버튼들 */}
            <div className="flex space-x-2 mr-5">
              <button 
                onClick={handleDelete} 
                className="p-2 rounded-md text-[#383C4B]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#C5D0DD',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                선택 삭제
              </button>
              <button 
                onClick={handleCreateScript} 
                className="p-2 rounded-md text-[#3677F6]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#E3EDFF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                스크립트 생성
              </button>
            </div>
          </div>
  
          {/* 데이터 테이블 */}
          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            {/* 테이블 헤더 */}
            <thead 
              className="bg-[#E2E8EF]"
              style={{
                height: '40px',
                border: '0px 1px 0px 1px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#666B81',
              }}
            >
              <tr className='align-middle'>
                <th className='text-center w-16'>
                  {/* 모든 항목 선택/해제 체크박스 */}
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-16"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  No.
                </th>
                {/* 테이블 컬럼 헤더 - 클릭 시 정렬 기능 제공 */}
                <th 
                  onClick={() => handleSort('title')} 
                  className="text-left font-medium text-gray-500 tracking-wider cursor-pointer w-109"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  제목<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  onClick={() => handleSort('averageRating')} 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-22"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  별점<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  onClick={() => handleSort('used')} 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-22"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  사용여부<img 
                        src={handleSortIcon} 
                        alt="Sort Icon" 
                        className="inline-block ml-1"
                        style={{ width: '22px', height: '22px' }}
                       />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  MBTI
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  성별
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  연령대
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  직업
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  연애상태
                </th>
                <th 
                  onClick={() => handleSort('createdAt')} 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  생성일자<img 
                        src={handleSortIcon} 
                        alt="Sort Icon" 
                        className="inline-block ml-1"
                        style={{ width: '22px', height: '22px' }}
                       />
                </th>
                <th 
                  onClick={() => handleSort('lastmodified')} 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  마지막 수정일<img 
                            src={handleSortIcon} 
                            alt="Sort Icon" 
                            className="inline-block ml-1"
                            style={{ width: '22px', height: '22px' }}
                           />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-16 pr-2"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  카테고리
                </th>
              </tr>
            </thead>
  
            {/* 테이블 본문 */}
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.map((item, index) => (
                <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                  <td className="whitespace-no-wrap text-center w-16">
                    {/* 각 항목 선택 체크박스 */}
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)} // 선택 여부에 따라 체크
                      onChange={(e) => {
                        e.stopPropagation(); // 클릭 이벤트 전파 중지
                        handleSelectRow(item.id); // 선택된 항목 업데이트
                      }}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                  {/* 테이블 데이터 셀 */}
                  <td className="whitespace-no-wrap text-center">
                    {index + 1}
                  </td>
                  <td className="whitespace-no-wrap cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {item.title}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {item.averageRating}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {item.used ? 'YES' : 'NO'}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {mapToKoreanLabel('mbti', item.mbti)}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {mapToKoreanLabel('sex', item.sex)}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {mapToKoreanLabel('age', item.age)}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {mapToKoreanLabel('job', item.job)}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {mapToKoreanLabel('love', item.love)}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {new Date(item.createdAt).toLocaleDateString()} ({new Date(item.createdAt).toLocaleTimeString()})
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                    {new Date(item.lastmodified).toLocaleDateString()} ({new Date(item.lastmodified).toLocaleTimeString()})
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.category}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'} // 페이지 구분자
            pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
            marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
            pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
            onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
            containerClassName={'pagination flex space-x-2 items-center'} // 페이지네이션 컨테이너 스타일
            activeClassName={'text-[#3677F6] font-bold'} // 현재 선택된 페이지 스타일
            breakClassName={'text-[#A9B6CA]'} // 구분자 스타일
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'} // 비활성화 스타일
          />
        </div>
      </div>
    </div>
    );
};

export default LearnSituation;