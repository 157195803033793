import bgImage from "./bg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";

const StudyOnboard = () => {
  const location = useLocation();
  const { script, id } = location.state || {};
  console.log(script.script);
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8";
  };

  const handleNextClick = () => {
    navigate("/conversation", {
      state: { script: script, id: id },
    });
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoStudy = () => {
    navigate("/study");
  };

  const ProgressBar = ({ filledSections }) => {
    const totalSections = 6;
    const gapWidth = 4;

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  return (
    <div
      className="relative flex flex-col bg-[#fff] overflow-hidden"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        height: "100vh", // Ensure full viewport height
        width: "100vw", // Ensure full viewport width
        overflow: "hidden", // Prevent scrolling
      }}
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="absolute w-full h-full bg-black opacity-70 z-10"></div>
      {/* Header */}
      <div className="relative flex items-center px-[16px] h-[50px]">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] p-[10px] z-40">
          <img
            width="60"
            height="60"
            src="x.png"
            alt="icon"
            onClick={toggleModal}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-40 text-white font-sans text-center">
          Conversation
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "78%" }}
        >
          <img
            width="40"
            height="40"
            src="link.png"
            alt="icon"
            onClick={handleLink}
          />
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "88%" }}
        >
          <img
            width="45"
            height="45"
            src="ddd.png"
            alt="icon"
            onClick={toggleOverlay}
          />
        </div>
      </div>
      {/* Progress Bar */}
      <ProgressBar filledSections={0} />
      {/* Content */}
      <div className="flex justify-end flex-1 mr-[10px] mt-[10px] z-50">
        <LanguageSwitcher2></LanguageSwitcher2>
      </div>
      <div className="flex mb-[50%] justify-center flex-1">
        <div className="text-white font-sans z-40 text-center">
          <div className="mb-[20px] w-[80%] mx-auto break-words">
            {script.background}
          </div>
          <div>{script.humanA}</div>
          <div>{script.humanB}</div>
          {/* <div>아들: 현우</div> */}
          {/* <div>딸: 예진</div> */}
        </div>
      </div>
      {/* Footer */}
      <div className="fixed bottom-0 w-full pb-[20px] px-[16px] z-40">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleNextClick}
        >
          학습 시작하기
        </button>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            언어 설정
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          {/* Text Container 2 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            오류 신고
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalVisible && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={() => setModalVisible(false)}
        >
          <div
            className="w-[60%] h-[120px] flex flex-col rounded-[10px] overflow-hidden bg-[#faf8f6]"
            onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
          >
            {/* Modal Content */}
            <div className="flex-1 flex items-center justify-center pt-[20px] pb-[20px] px-0">
              <div className="text-[14px] leading-[155%] font-['Pretendard_Variable'] font-medium text-[#000] text-center">
                학습을 종료하시나요?
              </div>
            </div>
            {/* Button Row */}
            <div className="h-[46px] flex border-t border-[#c5d0dd]">
              <div className="flex-1 h-full flex items-center justify-center border-r border-[#c5d0dd]">
                <button
                  className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#666b81] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                  onClick={handleModalAction1}
                >
                  취소
                </button>
              </div>
              <div className="flex-1 h-full flex items-center justify-center">
                <button
                  className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#3677f6] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                  onClick={handleGoStudy}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyOnboard;
