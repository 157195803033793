import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import Modal from "./Modal";

const AiChat = () => {
  const { translations } = useContext(LanguageContext);
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoAi = () => {
    navigate("/ai");
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6]">
        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] px-[10px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
                onClick={toggleModal}
              >
                <img width="36" height="36" src="xbutton.png"></img>
              </div>
              <div className="absolute left-1/2 transform -translate-x-1/2 text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                Chat
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <LanguageSwitcher></LanguageSwitcher>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        {/* chat */}
        <div className="absolute left-[2%] top-[106px] w-[96%] flex flex-col items-start justify-start gap-[20px]">
          <div className="flex flex-row items-center justify-center py-[10px] px-[16px] bg-[#c5d0dd] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0">
            <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-nowrap">
              Honey, you were amazing out there!
              <br />
              We are so proud of you.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[8px]">
            <div className="flex flex-row items-center justify-center py-[10px] px-[16px] bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-br-0">
              <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-nowrap">
                Oh. Thank you. I’m so happy
              </div>
            </div>
            <div className="w-[167px] flex flex-row items-center justify-start py-[10px] px-[16px] bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-br-0">
              <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-nowrap">
                Where is Minji?
                <br />I want to talk with her
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <div className="flex flex-row items-center justify-center py-[10px] px-[16px] bg-[#c5d0dd] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0">
              <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-nowrap">
                I don’t know where she is
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-end justify-start">
            <div className="flex flex-row items-center justify-center py-[10px] px-[16px] bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-br-0">
              <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-nowrap">
                Anyway. I glad to talk to you
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-0 bottom-0 w-[100%] flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-center gap-[10px] p-[10px] bg-[#fff]">
            <div className="w-[82.5%] h-[40px] shrink-0 flex flex-row items-center justify-start py-[18px] px-[14px] border-[1px] border-solid border-[#e2e8ef] rounded-[12px]">
              <input
                className="text-[16px] leading-[100%] font-['Pretendard'] font-medium text-[#000] whitespace-nowrap focus:outline-none"
                placeholder="입력해주세요."
              />
            </div>
            <img width="32" height="32" src="icon117_1764.png"></img>
          </div>
        </div>
        <Modal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onAction1={handleModalAction1}
          onAction2={handleGoAi}
        />
      </div>
    </div>
  );
};

export default AiChat;
