import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Make sure to install react-toastify
import { useDispatch, useSelector } from "react-redux";
import { addItems } from "./actions";
import GnbAi from "./components/GnbAi";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

const Ai = () => {
  const { translations } = useContext(LanguageContext);
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items); // Access items from Redux state
  const [filteredItems, setFilteredItems] = useState(items);
  const [page, setPage] = useState(1); // Manage page or data set state
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const typeList = [
    "구동사",
    "관용구",
    "고정표현",
    "신조어",
    "슬랭",
    "문법적 구조",
    "약어",
    "속담",
    "숙어",
    "단어",
  ];

  const overlayRef = useRef(null); // Ref for the overlay
  const goToVocabList = (item, type) => {
    navigate("/aichat", {
      state: { item, type },
    });
  };

  const getRandomType = () => {
    const randomIndex = Math.floor(Math.random() * typeList.length);
    return typeList[randomIndex];
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    if (selectedGender) {
      const filtered = items.filter((item) => item.type === selectedGender);
      setFilteredItems(filtered); // Update filtered items
      sessionStorage.setItem("selectedGender", selectedGender);
    } else {
      setFilteredItems(items); // Update filtered items
      sessionStorage.removeItem("selectedGender");
    }
    toggleOverlay(); // Optionally close the overlay
  };

  const loadMoreData = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    setTimeout(() => {
      if (page < 100) {
        const newItems = Array(5)
          .fill()
          .map((_, idx) => ({
            item: `Item ${items.length + idx + 1}`,
            type: getRandomType(),
          }));
        // setData((prevData) => [...prevData, ...newItems]);
        dispatch(addItems(newItems));
        setPage((prevPage) => prevPage + 1); // Increment the page number
      } else {
        setHasMore(false);
        toast.info("End of data");
      }
      setLoading(false);
    }, 100);
  }, [loading, hasMore, page, items, dispatch]);

  const lastScrollTopRef = useRef(
    window.pageYOffset || document.documentElement.scrollTop
  );
  const debounceTimerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop < lastScrollTopRef.current) {
      clearTimeout(debounceTimerRef.current);

      debounceTimerRef.current = setTimeout(() => {
        loadMoreData();
      }, 100); // Adjust the time (2000 ms = 2 seconds) as needed
    }

    lastScrollTopRef.current = currentScrollTop;
  }, [loadMoreData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimerRef.current);
    };
  }, [handleScroll]);

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    const storedGender = sessionStorage.getItem("selectedGender");
    if (storedGender) {
      setSelectedGender(storedGender);
    }
    if (items.length < 5) {
      loadMoreData();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = selectedGender
      ? items.filter((item) => item.type === selectedGender)
      : items;
    setFilteredItems(filtered);
  }, [items, selectedGender]);

  const handleSwipeLeft = () => {
    navigate("/mypage");
  };

  const handleSwipeRight = () => {
    navigate("/express");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  return (
    <div
      id="swipe-container"
      className="flex min-h-screen justify-center w-full bg-[#faf8f6]"
    >
      <div className="relative w-[100%] bg-[#faf8f6]">
        {/* Navigation Bar */}
        <GnbAi></GnbAi>
        {/* Main Content */}
        <div className="relative w-full h-full flex justify-center">
          <div className="flex-grow relative w-full">
            <div className="absolute left-[16px] top-[77px] flex flex-row items-center justify-start gap-[5px]">
              <div className="flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch text-[16px] leading-[100%] font-sans font-bold text-[#25272f]">
                  복습할 표현
                </div>
                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                  나의 특징과 상황에 맞게 회화를 연습해 볼 수 있어요
                </div>
              </div>
            </div>

            <div className="relative left-[3vw] top-[129px] w-[94vw] flex flex-col rounded-[20px] mb-[200px]">
              {filteredItems.map((dataItem, index) => (
                <div
                  key={index}
                  className="self-stretch flex flex-row items-center justify-between py-[13px] px-[14px] bg-[#fff] border-b border-#e9ecef"
                >
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-[5px] h-[5px] shrink-0 bg-[#f67239] rounded-full"></div>
                    <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-justify whitespace-nowrap">
                      {dataItem.item}
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-justify whitespace-nowrap">
                    <div className="relative w-[30px] h-[30px] shrink-0">
                      <img
                        width="30"
                        height="30"
                        src="xbutton.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex flex-row items-center justify-end">
                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                  단어 바꾸기
                </div>
                <div className="w-[32px] h-[32px] shrink-0 flex flex-row items-center justify-center p-[8px]">
                  <img
                    width="32"
                    height="32"
                    src="VectorI20_198;365_5132.png"
                  ></img>
                </div>
              </div>
            </div>

            <div className="absolute left-0 top-[2vh] w-[360px] h-[50px]">
              <div className="absolute left-[16px] top-[7px] w-[92vw] flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                  AI Chat
                </div>
                <LanguageSwitcher></LanguageSwitcher>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="fixed bottom-[60px] w-full pb-[20px] px-[16px] z-40">
          <button
            className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
            onClick={() => goToVocabList()}
          >
            학습 시작하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ai;
