import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import GnbMyPage from "./components/GnbMyPage";
import MyPageNotice from "./MyPageNotice";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import Modal2 from "./Modal2";

const MyPage = () => {
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  // Handler functions for navigation
  const goAccountInfo = () => {
    navigate("/accountinfo");
  };
  const goNotice = () => {
    navigate("/mypagenotice");
  };
  const goFaq = () => {
    navigate("/mypagefaq");
  };

  const handleSwipeLeft = () => {};

  const handleSwipeRight = () => {
    navigate("/ai");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleOpenModal = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  return (
    <div
      id="swipe-container"
      className="relative w-full bg-[#faf8f6] overflow-hidden flex flex-col"
      style={{ height: `calc(100vh)` }}
    >
      {/* Navigation Bar */}
      <GnbMyPage></GnbMyPage>

      {/* Main Content */}
      <div className="mt-[3vh] pb-[80px] px-[24px] flex-grow">
        {/* Header */}

        {/* Service Settings */}
        <div className="top-[2vh]"></div>
        <div className="ml-[92%] flex flex-row items-center">
          <LanguageSwitcher></LanguageSwitcher>
        </div>
        <div onClick={goAccountInfo}>
          <div className="text-[18px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap mt-[4vh]">
            김카라
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap inline-block">
              님
            </div>
            <img
              src="forward.png"
              className="ml-auto align-middle -mt-[18px]"
              alt="Forward"
            />
          </div>
          {/* membership */}
          <div className="w-[100%] flex flex-col items-start justify-center gap-[8px] py-[12px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] mt-[2vh]">
            <div className="text-[14px] leading-[100%] font-sans font-bold text-[#fff] whitespace-nowrap">
              Membership
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
              2024.05.24 - 2025.05.23
            </div>
          </div>
        </div>
        <div className="text-[16px] leading-[100%] font-sans font-bold text-[#25272f] whitespace-nowrap mt-[3vh]">
          서비스 설정
        </div>
        <div className="mt-[6px]" onClick={handleOpenModal}>
          <div className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4]">
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              모드 설정
            </div>
            <div className="flex flex-row items-center">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                다크모드
              </div>
              <div className="w-[4px] h-[4px] bg-[#666b81] rounded-full mx-[6px]"></div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                라이트모드
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4]">
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              푸시 설정
            </div>
            <div className="flex flex-row items-center">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                ON
              </div>
              <div className="w-[4px] h-[4px] bg-[#666b81] rounded-full mx-[6px]"></div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                OFF
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4]">
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              글꼴 설정
            </div>
            <img
              width="30"
              height="30"
              src="icon22_189.png"
              alt="Font Settings"
            />
          </div>
        </div>

        {/* Customer Service */}
        <div className="mt-[20px]">
          <div className="text-[16px] leading-[100%] font-sans font-bold text-[#25272f] whitespace-nowrap">
            고객센터
          </div>
          <div className="mt-[6px]">
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4]"
              onClick={goNotice}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                공지사항
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4]"
              onClick={goFaq}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                FAQ
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4] mb-[100px]"
              onClick={handleOpenModal}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                오류신고
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Action Button */}
      <div
        className="fixed bottom-[80px] right-[30px] w-[75px] h-[75px] flex items-center justify-center"
        onClick={handleOpenModal}
      >
        <div className="relative w-full h-full rounded-full flex items-center justify-center">
          <div className="absolute inset-0 bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-full"></div>
          <img
            className="relative w-[44px] h-[44px]"
            src="ChatTeardropDots22_205.png"
            alt="Chat"
          />
        </div>
      </div>

      <Modal2 isVisible={isModalVisible2} onClose={handleCloseModal} />
    </div>
  );
};

export default MyPage;
