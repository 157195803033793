import FrameComponent3 from "../logincomponents/FrameComponent3";
import styles from "./PW1.module.css";
import Field from "../logincomponents/Field";
import Title from "../logincomponents/Title";
import PropTypes from "prop-types";
import BBtn from "../logincomponents/BBtn";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";

const PW1 = ({ className = "" }) => {
  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="비밀번호 재설정"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        {/* <FrameComponent3 prop="비밀번호 재설정" prop1="1213213@fmail.com" /> */}
        <section className={styles.pwInner}>
          <div className={[styles.textFieldParent, className].join(" ")}>
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop=""
              prop1="아이디"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="영문, 숫자, 특수문자 조합 8자-15자 이내"
              prop1="비밀번호 재설정"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="비밀번호를 한번 더 입력해주세요"
              prop1="비밀번호 확인"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
            />
          </div>
        </section>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={"로그인"} to="/login1" />
      </div>
    </ReactiveOuter>
  );
};

PW1.propTypes = {
  className: PropTypes.string,
};

export default PW1;
