import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import PhoneVerification from "../logincomponents/PhoneVerification";
import PWChange from "../logincomponents/PWChange";
import PHChange from "../logincomponents/PHChange";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import { useNavigate } from "react-router-dom";
import BBtn2 from "../logincomponents/BBtn2";
import { useSelector } from "react-redux";

const RegisterSetting = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userId);
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleStart = () => {
    navigate("/accountinfo");
  };

  const deleteAccount = async (accessToken) => {
    try {
      const response = await fetch(`${apiUrl}/auth/delete`, {
        method: "DELETE", // Use DELETE method
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        navigate("/welcome");
        // const result = await response.json();
        // console.log("Account deleted successfully:", result);
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        console.error("Failed to delete account:", response.statusText);
        // Handle error response
      }
    } catch (error) {
      console.error("Error during the request:", error);
      // Handle request error
    }
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative min-x-[360px] w-full bg-[#faf8f6]">
        <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
        {/* Header */}
        <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
          <div
            className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
            onClick={handleGoBack}
          >
            <img width="10" height="16" src="back.png" alt="icon" />
          </div>
          <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
            계정 정보
          </div>
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
            <img width="10" height="16" src="back.png" alt="icon" />
          </div>
        </div>
        <ReactiveOuter>
          <div className="h-[2vh]"></div>
          <FormTop>
            <FormForm>
              <OptionalTextField prop="닉네임" prop1=""></OptionalTextField>
              <OptionalTextField prop="아이디" prop1=""></OptionalTextField>
              <PWChange></PWChange>
              <PHChange></PHChange>
              <OptionalTextField
                prop="프로모션코드"
                prop1=""
              ></OptionalTextField>
              <OptionalTextField prop="연결" prop1=""></OptionalTextField>
            </FormForm>
            <div className="bottom-[5vh] w-full mt-[5vh]">
              <BBtn prop={"저장하기"} onClick={handleStart} />
              <div className="h-[10px]"></div>
              <BBtn2
                prop={"회원탈퇴"}
                onClick={() => {
                  deleteAccount(accessToken);
                }}
              />
            </div>
          </FormTop>
        </ReactiveOuter>
      </div>
    </div>
  );
};

export default RegisterSetting;
