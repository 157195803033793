import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Note = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    toggleOverlay(); // Optionally close the overlay
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6]">
        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start p-[10px]"
                onClick={handleGoBack}
              >
                <img width="8" height="16" src="back.png"></img>
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                오답노트
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0"></div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          <div className="flex flex-col items-start justify-start py-[10px] px-[16px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[5px]">
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] text-right whitespace-nowrap">
                최신순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                알파벳순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                별점순
              </div>
            </div>
          </div>
          {/* item1 */}
          <div className="w-full flex flex-col items-start justify-start bg-[#fff]">
            <div className="w-full h-[98px] shrink-0 flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
              <div className="flex flex-col items-start justify-start gap-[17px]">
                <div className="flex flex-col items-start justify-start gap-[12px]">
                  <div className="flex flex-row items-center justify-center">
                    <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                      Flirting Relationship
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000]">
                    로맨틱한 상황에서 플러팅하기
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[3px]">
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    출퇴근
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    산책
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    건강식
                  </div>
                  <div className="w-[2px] h-[2px] shrink-0 bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    한식
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-end justify-between">
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <img width="15" height="15" src="star.png"></img>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                    4.5
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px]">
                  <div className="flex flex-row items-center justify-start bg-[#e9ecef] rounded-[33px]">
                    <div className="flex flex-row items-center justify-center py-[4px] px-[6px]">
                      <div className="text-[10px] leading-[100%] font-sans font-bold text-[#666b81] whitespace-nowrap">
                        MBTI
                      </div>
                    </div>
                  </div>
                  <div className="w-[40px] shrink-0 flex flex-row items-center justify-start bg-[#e0fef5] rounded-[33px]">
                    <div className="flex flex-row items-center justify-center py-[4px] px-[6px]">
                      <div className="text-[10px] leading-[100%] font-sans font-bold text-[#61e3eb] whitespace-nowrap">
                        EAZY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
