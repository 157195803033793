import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PersonaSetting = () => {
  const [selected, setSelected] = useState({
    gender: null,
    age: null,
    occupation: null,
    relationshipStatus: null,
  });

  const navigate = useNavigate();

  const handleSelect = (category, value) => {
    setSelected((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleStart = () => {
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected) {
      navigate("/accountinfo", { state: { selected } });
    } else {
      toast.error("모든 옵션을 선택해 주세요.");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
          페르소나 정보
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 px-[16px] py-[20px] pb-[20px] flex flex-col gap-[14px]">
        {/* Title and Description */}

        {/* Gender */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            성별
          </div>
          <div className="flex gap-[6px]">
            {["여성", "남성", "무관"].map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.gender === gender
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("gender", gender)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Age Group */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            연령대
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["10대", "20대", "30대", "40대", "50대 이상", "무관"].map(
              (age) => (
                <div
                  key={age}
                  className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                    selected.age === age
                      ? "border-[#3677f6] text-[#3677f6]"
                      : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                  }`}
                  onClick={() => handleSelect("age", age)}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                    {age}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* Occupation */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            직업
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {[
              "학생",
              "수험생",
              "직장인",
              "프리랜서",
              "알바생",
              "자영업자",
              "전업주부",
              "취준생",
              "무관",
            ].map((job) => (
              <div
                key={job}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.occupation === job
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("occupation", job)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {job}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Relationship Status */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            연애상태
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["싱글", "썸", "연애", "결혼", "무관"].map((status) => (
              <div
                key={status}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.relationshipStatus === status
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("relationshipStatus", status)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {status}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleStart}
        >
          저장하기
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default PersonaSetting;
