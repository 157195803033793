import React from "react";
import { useState, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate, useLocation } from "react-router-dom";

const SituationStudyList = () => {
  const location = useLocation();
  const { keywords } = location.state || {};
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoStudy = () => {
    navigate("/studyonboard", {
      state: {
        script: {
          id: 2332,
          language: "eng",
          background:
            "Junho (INTJ) and Mina (ESFP) are colleagues working at the same company. Junho has a quiet and systematic personality, while Mina is lively and spontaneous. The two accidentally met in the company parking lot.",
          humancount: 2,
          humanA: "Junho(INTJ)",
          humanB: "Mina(ESFP)",
          humanC: "",
          humanD: "",
          script:
            "=/A/Oh, Mina. You're driving to work.\n=/B/Junho! Yes, it's my first time today. Isn't it exciting?\n=/A/Hmm... I drive every day. It's efficient.\n=/B/Wow! I want to drive every day from now on. A morning drive, how refreshing!",
          note: "",
          type: "easy",
          createdAt: "2024-09-06T10:13:27.252Z",
          deletedAt: null,
        },
        id: "2332",
      },
    });
  };
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        {/* Updated Item List Section */}
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            Situation
          </h1>
          <LanguageSwitcher></LanguageSwitcher>
        </header>
        {/* Top Bar */}
        <div className="w-full h-[44px] flex items-center justify-between px-4 bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)]">
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center justify-center py-[5px] px-[8px] bg-[#fff] rounded-full">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] whitespace-nowrap">
                KEYWORD
              </div>
            </div>
            <div className="flex items-center gap-[4px]">
              {keywords.slice(0, keywords.length).map((keyword, index) => (
                <React.Fragment key={index}>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                    {keyword}
                  </div>
                  {index < keywords.length - 1 && ( // Check if it's not the last item to add the dot
                    <div className="w-[2px] h-[2px] bg-[#fff] rounded-full"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        {/* Sorting Options */}
        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              최신순
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              알파벳순
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
              별점순
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="w-full flex flex-col items-start bg-[#fff] overflow-y-auto">
          {/* Card */}
          {[...Array(10)].map((_, index) => (
            <div
              key={index}
              className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0"
              onClick={handleGoStudy}
            >
              <div className="flex flex-col gap-[17px]">
                <div className="flex flex-col gap-[12px]">
                  <div className="flex items-center">
                    <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] whitespace-nowrap">
                      Flirting Relationship
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333]">
                    로맨틱한 상황에서 플러팅하기
                  </div>
                </div>
                <div className="flex items-center gap-[3px]">
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    출퇴근
                  </div>
                  <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    산책
                  </div>
                  <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    건강식
                  </div>
                  <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    한식
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-[4px]">
                <img
                  width="15"
                  height="15"
                  src={`star.png`}
                  alt="rating star"
                />
                <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] whitespace-nowrap">
                  4.5
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SituationStudyList;
