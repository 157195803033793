import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AccountInfo = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoRegister = () => {
    navigate("/registersetting");
  };
  const handleGoLang = () => {
    navigate("/langsetting");
  };
  const handleGoPersona = () => {
    navigate("/personasetting");
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#faf8f6] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
          회원 정보
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="absolute left-[5%] top-[12vh] w-[90%] flex flex-col items-start justify-start gap-[12px]">
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoRegister}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            계정 정보
          </div>
        </div>
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoLang}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            언어 설정
          </div>
        </div>
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoPersona}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            페르소나 설정
          </div>
        </div>
      </div>

      {/* Footer */}

      {/* Toast Container */}
    </div>
  );
};

export default AccountInfo;
