import React, { useEffect } from 'react';
import trashIcon from '../../assets/admin/icons/trash.png';

const StudyPointEditor = ({ studyPoints = [], onStudyPointsChange, scriptId, onRemoveStudyPoint, type }) => {
  useEffect(() => {
    // Filter study points by scriptId
    const filteredStudyPoints = studyPoints.filter(sp => sp.scriptId === scriptId);

    // Only update if the filteredStudyPoints array is actually different from the current state
    if (filteredStudyPoints.length === 0 && studyPoints.length === 0) {
      onStudyPointsChange([{ title: '', content: '', scriptId: scriptId }]);
    } else if (filteredStudyPoints.length > 0 && JSON.stringify(filteredStudyPoints) !== JSON.stringify(studyPoints)) {
      onStudyPointsChange(filteredStudyPoints);
    }
  }, [studyPoints, onStudyPointsChange, scriptId]);

  const handleStudyPointChange = (index, field, value) => {
    const updatedStudyPoints = [...studyPoints];
    updatedStudyPoints[index] = {
      ...updatedStudyPoints[index],
      [field]: value,
      scriptId: scriptId,
    };
    onStudyPointsChange(updatedStudyPoints);
  };

  const handleAddStudyPoint = () => {
    if (studyPoints.length < 3) { // 최대 3개의 스터디 포인트만 추가 가능
      onStudyPointsChange([...studyPoints, { title: '', content: '', scriptId: scriptId }]);
    }
  };

  const handleRemoveStudyPointClick = (index) => {
    if (onRemoveStudyPoint) {
      onRemoveStudyPoint(index);
    } else {
      console.error("onRemoveStudyPoint is not a function");
    }
  };

  return (
    <div className="mb-4">
      <label
        className="block mb-1"
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        Study Point
      </label>
      <div className="space-y-4">
        {studyPoints.map((studyPoint, index) => (
          <div key={index} className="flex items-center space-x-4">
            <label
              className="block w-[48px]"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'left',
              }}
            >
              {`포인트${index + 1}`}
            </label>
            <div className="flex-grow">
              <input
                type="text"
                placeholder="학습포인트"
                value={studyPoint.title}
                onChange={(e) => handleStudyPointChange(index, 'title', e.target.value)}
                className="border p-2 rounded-md w-full mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
              <input
                type="text"
                placeholder="학습 포인트 해설"
                value={studyPoint.content}
                onChange={(e) => handleStudyPointChange(index, 'content', e.target.value)}
                className="border p-2 rounded-md w-full"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            {studyPoints.length > 1 && (
              <button
                type="button"
                className="p-2 rounded-md flex items-center justify-center"
                onClick={() => handleRemoveStudyPointClick(index)}
              >
                <img src={trashIcon} alt="Delete" className="w-5 h-5" />
              </button>
            )}
          </div>
        ))}
        {studyPoints.length < 3 && (
          <div className="flex items-center space-x-4">
            <label
              className="block w-[48px]"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'left',
              }}
            >
              {`포인트${studyPoints.length + 1}`}
            </label>
            <button
              type="button"
              className="p-2 rounded-md flex-grow border border-[#A9B6CA] text-[#666B81] text-left"
              onClick={handleAddStudyPoint}
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              추가하기
            </button>
          </div>
        )}
      </div>
      {/* 아래 선 추가 */}
      <div className="border-b-[1px] border-[#E2E8EF] mt-4"></div>
    </div>
  );
};

export default StudyPointEditor;