import { ADD_ITEMS, SET_ITEMS, SET_USER_ID, CLEAR_USER_ID } from "./actions";

const initialState = {
  items: [], // Existing state for items
  userId: null, // New state for userId
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle items actions
    case ADD_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    // Handle userId actions
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload, // Set userId
      };
    case CLEAR_USER_ID:
      return {
        ...state,
        userId: null, // Clear userId
      };

    default:
      return state;
  }
};

export default reducer;
