import React, { useState, useRef, useEffect } from "react";
import "./index.css";

function Speech() {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const text = `=A/Hi Chulsoo, how was your cycling club activity yesterday?\n=A/Hi Minho, it was a really meaningful time.`;

  const startRecording = () => {
    const utterance = new SpeechSynthesisUtterance(text);
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(
      audioContext.createMediaStreamDestination().stream
    );
    const destination = audioContext.createMediaStreamDestination();
    source.connect(destination);

    utterance.onstart = () => {
      console.log("Speech started");
      setIsRecording(true);
    };

    utterance.onend = () => {
      console.log("Speech ended");
      setIsRecording(false);
    };

    window.speechSynthesis.speak(utterance);

    // Handle the audio stream
    const chunks = [];
    const recorder = new MediaRecorder(destination.stream);
    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/mp3" });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
    };

    recorder.start();
    setTimeout(() => recorder.stop(), 5000); // Stop recording after 5 seconds
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeBarChange = (e) => {
    if (audioRef.current) {
      const newTime = (e.target.value / 100) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      const updateDuration = () => setDuration(audioRef.current.duration);
      const updateCurrentTime = () =>
        setCurrentTime(audioRef.current.currentTime);

      audioRef.current.addEventListener("loadedmetadata", updateDuration);
      audioRef.current.addEventListener("timeupdate", updateCurrentTime);

      return () => {
        audioRef.current.removeEventListener("loadedmetadata", updateDuration);
        audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
      };
    }
  }, [audioUrl]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <button
        onClick={startRecording}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 mb-4"
      >
        Generate and Record MP3
      </button>
      {audioUrl && (
        <>
          <audio ref={audioRef} src={audioUrl} controls />
          <button
            onClick={handlePlayPause}
            className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-lg hover:bg-green-600"
          >
            {isPlaying ? "Pause" : "Play"}
          </button>
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100}
            onChange={handleTimeBarChange}
            className="w-full mt-4"
          />
          <div>
            <span>{Math.floor(currentTime)}s</span> /{" "}
            <span>{Math.floor(duration)}s</span>
          </div>
          <a
            href={audioUrl}
            download="recorded_audio.mp3"
            className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-lg shadow-lg hover:bg-yellow-600"
          >
            Download MP3
          </a>
        </>
      )}
    </div>
  );
}

export default Speech;
