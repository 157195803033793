import axios from 'axios';
import API_BASE_URL from '../../config';

// Expression Manage 생성
export const createExpressionManage = async (expressionData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/expressions/manage`, expressionData);
    return response.data;
  } catch (error) {
    console.error('Error creating expression manage:', error);
    throw error;
  }
};

// 특정 ID의 Expression Manage 데이터를 가져오기
export const fetchExpressionManage = async (managerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/expressions/manage/${managerId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching expression manage:', error);
      throw error;
    }
};

// Expression Manage 특정 Expression 데이터 가져오기 (수정용)
export const fetchExpression = async (managerId) => {
  try {
    // 먼저 해당 managerId로 manage 데이터를 가져옵니다.
    const response = await axios.get(`${API_BASE_URL}/expressions/manage/${managerId}`);
    const manageData = response.data;

    // manageData에서 customizes 배열을 가져옵니다.
    const expressionData = manageData.expressions;
    
    // 모든 connect 데이터를 반환합니다.
    return expressionData;
  } catch (error) {
    console.error('Error fetching connect:', error);
    throw error;
  }
};

// Expression Manage 데이터 수정
export const updateExpressionManage = async (managerId, updatedData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/expressions/manage/${managerId}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating expression manage:', error);
      throw error;
    }
};

// Expression Manage 데이터 삭제
export const deleteExpressionManage = async (managerId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/expressions/manage/${managerId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting expression manage:', error);
      throw error;
    }
};

// Expression Manage 데이터 페이지네이션으로 가져오기
export const fetchExpressionManageList = async (page = 1, limit = 10) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/expressions/manage`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching expression manage list:', error);
      throw error;
    }
};

// Expression 생성
export const createExpression = async (expressionData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/expressions`, expressionData);
    return response.data;
  } catch (error) {
    console.error('Error creating expression:', error);
    throw error;
  }
};

// Expression 페이지네이션
export const getExpressionsWithPagination = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expressions?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching expressions with pagination:', error);
    throw error;
  }
};

// 특정 ID의 Expression 검색
export const getExpressionById = async (managerId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expressions/${managerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching expression with ID ${managerId}:`, error);
    throw error;
  }
};

// Expression 필터 검색
export const filterExpressions = async (filters) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expressions/managerFilter`, {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error filtering expressions:', error);
    throw error;
  }
};

// Tag 복수 검색을 통한 Expression 필터 검색
export const filterExpressionsByTags = async (tagIds, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/expressions/managers/by-tags`, {
      params: {
        tagIds: tagIds.join(','), // 배열을 콤마로 구분된 문자열로 변환
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error filtering expressions by tags:', error);
    throw error;
  }
};

// Expression 업데이트
export const updateExpression = async (managerId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/expressions/${managerId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating expression with ID ${managerId}:`, error);
    throw error;
  }
};

// Expression 삭제
export const deleteExpression = async (managerId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/expressions/${managerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting expression with ID ${managerId}:`, error);
    throw error;
  }
};