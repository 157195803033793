// src/context/LanguageContext.js

import React, { createContext, useState } from "react";
import en from "./en.json";
import ko from "./ko.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("ko");
  const [translations, setTranslations] = useState(ko);

  const switchLanguage = () => {
    if (language === "en") {
      setLanguage("ko");
      setTranslations(ko);
    } else {
      setLanguage("en");
      setTranslations(en);
    }
  };

  return (
    <LanguageContext.Provider
      value={{ language, translations, switchLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
