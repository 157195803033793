import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MyPageNoticeContent = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/mbti");
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
          공지사항
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>
      {/* content */}
      <div className="relative w-[100%] min-h-screen bg-[#faf8f6] overflow-hidden">
        <div className="absolute left-0  w-[100%] min-h-screen flex flex-col items-start justify-start gap-[20px]  px-[16px] py-[14px] bg-[#fff]">
          <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-[20px] border-[solid] border-#e9ecef border-b border-[0_0_1px]">
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-justify whitespace-nowrap">
              공지사항 제목이 들어갑니다
            </div>
          </div>
          <div className="self-stretch text-[14px] leading-[100%] font-sans font-medium text-[#000]">
            공지사항 내용이 들어갑니다
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPageNoticeContent;
