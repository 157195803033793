import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LearnCustomize from './pages/admin/LearnCustomize';
import LearnSituation from './pages/admin/LearnSituation';
import LearnPsychology from './pages/admin/LearnPsychology';
import LearnExpression from './pages/admin/LearnExpression';
import CustomizeManage from './pages/admin/CustomizeManage';
import SituationManage from './pages/admin/SituationManage';
import PsychologyManage from './pages/admin/PsychologyManage';
import Navigation from './components/admin/Navigation';
import ExpressionManage from './pages/admin/ExpressionManage';
import InputJson from './pages/admin/InputJson';
import ExpressionInput from './pages/admin/ExpressionInput';
import Login from './components/admin/Login';
import TermsAndConditions from './components/admin/TermsAndConditions';
import NoticeList from './pages/admin/NoticeList';
import NoticeEditor from './pages/admin/NoticeEditor';
import FaqList from './pages/admin/FaqList';
import FaqEditor from './pages/admin/FaqEditor';
import ErrorList from './pages/admin/ErrorList';
import Terms from './components/admin/PersonalInformation';

//user
import { motion, AnimatePresence } from "framer-motion";

import Mbti2 from "./user/Mbti2";
import Persona from "./user/Persona";
import Lang from "./user/Lang";
import Nickname from "./user/Nickname";
import Home from "./user/Home";
import Study from "./user/Study";
import Express from "./user/Express";
import Ai from "./user/Ai";
import MyPage from "./user/MyPage";
import VocabList from "./user/VocabList";
import Splash from "./user/loginpages/Splash";
import Welcome from "./user/loginpages/Welcome";
import SocialLogin from "./user/loginpages/SocialLogin";
import Login1 from "./user/loginpages/Login1";
import Login2 from "./user/loginpages/Login2";
import ID from "./user/loginpages/ID";
import ID1 from "./user/loginpages/ID1";
import PW from "./user/loginpages/PW";
import PW1 from "./user/loginpages/PW1";
import Register from "./user/loginpages/Register";
import RegisterSetting from "./user/loginpages/RegisterSetting";
import "./user/global.css";
import QuizA from "./user/QuizA";
import Speech from "./user/Speech";
import SocialKakao from "./user/loginpages/SocialKakao";
import SocialApple from "./user/loginpages/SocialApple";
import Prac from "./user/Prac";
import BookMark from "./user/BookMark";
import MbtiStudy from "./user/MbtiStudy";
import MbtiStudyList from "./user/MbtiStudyList";
import PersonaStudy from "./user/PersonaStudy";
import StudyOnboard from "./user/StudyOnboard";
import PersonaStudyList from "./user/PersonaStudyList";
import PsychologyStudyList from "./user/PsychologyStudyList";
import SituationStudyList from "./user/SituationStudyList";
import Note from "./user/Note";
import Associated from "./user/Associated";
import SituationStudy from "./user/SituationStudy";
import PsychologyStudy from "./user/PsychologyStudy";
import Conversation from "./user/Conversation";
import QuizB from "./user/QuizB";
import StarScore from "./user/StarScore";
import PersonaSetting from "./user/PersonaSetting";
import LangSetting from "./user/LangSetting";
import AccountInfo from "./user/AccountInfo";
import MyPageNotice from "./user/MyPageNotice";
import MyPageFaq from "./user/MyPageFaq";
import MyPageNoticeContent from "./user/MyPageNoticeContent";
import StudyPoint from "./user/StudyPoint";
import AiChat from "./user/AiChat";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.key}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
      >
        <Routes location={location} key={location.key}>

          {/* admin 경로로 Login 페이지 렌더링 */}
          <Route path="/admin" element={<Login />} />

          {/* inputJson 경로로 LearnCustomize 페이지 렌더링 */}
          <Route path="/inputJson" element={<InputJson />} />
          {/* expressionJson 경로로 LearnCustomize 페이지 렌더링 */}
          <Route path="/expressJson" element={<ExpressionInput />} />
          {/* customize 경로로 LearnCustomize 페이지 렌더링 */}
          <Route path="/customize" element={<LearnCustomize />} />
          {/* situation 경로로 LearnCustomize 페이지 렌더링 */}
          <Route path="/situation" element={<LearnSituation />} />
          {/* psychology 경로로 LearnPsychology 페이지 렌더링 */}
          <Route path="/psychology" element={<LearnPsychology />} />
          {/* expression 경로로 LearnExpression 페이지 렌더링 */}
          <Route path="/expression" element={<LearnExpression />} />
          {/* notice 경로로 NoticeList 페이지 렌더링 */}
          <Route path="/notice" element={<NoticeList />} />
          {/* notice-editor 경로로 NoticeEditor 페이지 렌더링 */}
          <Route path="/notice-editor" element={<NoticeEditor />} />
          {/* faq 경로로 FaqList 페이지 렌더링 */}
          <Route path="/faq" element={<FaqList />} />
          {/* faq-editor 경로로 FaqEditor 페이지 렌더링 */}
          <Route path="/faq-editor" element={<FaqEditor />} />
          {/* error 경로로 ErrorList 페이지 렌더링 */}
          <Route path="/error" element={<ErrorList />} />

          {/* 스크립트 생성 모드로 CustomizeManage 페이지 렌더링 */}
          <Route path="/customize/manage/create" element={<CustomizeManage mode="create" />} />
          {/* 스크립트 생성 모드로 SituationManage 페이지 렌더링 */}
          <Route path="/situation/manage/create" element={<SituationManage mode="create" />} />
          {/* 스크립트 생성 모드로 PsychologyManage 페이지 렌더링 */}
          <Route path="/psychology/manage/create" element={<PsychologyManage mode="create" />} />
          {/* 스크립트 생성 모드로 expression 페이지 렌더링 */}
          <Route path="/expression/manage/create" element={<ExpressionManage mode="create" />} />

          {/* 스크립트 수정 모드로 CustomizeManage 페이지 렌더링 */}
          <Route path="/customize/manage/edit/:managerId" element={<CustomizeManage mode="edit" />} />
          {/* 스크립트 수정 모드로 SituationManage 페이지 렌더링 */}
          <Route path="/situation/manage/edit/:managerId" element={<SituationManage mode="edit" />} />
          {/* 스크립트 수정 모드로 PsychologyManage 페이지 렌더링 */}
          <Route path="/psychology/manage/edit/:managerId" element={<PsychologyManage mode="edit" />} />
          {/* 스크립트 수정 모드로 ExpressionManage 페이지 렌더링 */}
          <Route path="/expression/manage/edit/:managerId" element={<ExpressionManage mode="edit" />} />
          
          {/* 이용약관 */}
          <Route path="/conditions" element={<TermsAndConditions />} />
          {/* 개인정보 */}
          <Route path='/terms' element={<Terms />} />
          
          {/* 모든 경로가 일치하지 않을 때 기본적으로 /로 리디렉션 */}
          <Route path="*" element={<Navigate to="/" />} />

          {/* 로그인 */}
          {/* 1ㅇ */}
          <Route path="/" element={<Splash />} />
          {/* 2ㅇ */}
          <Route path="/welcome" element={<Welcome />} />
          {/* 3 */}
          <Route path="/social-login" element={<SocialLogin />} />
          {/* 4 */}
          <Route path="/social-kakao" element={<SocialKakao />} />
          {/* 4 */}
          <Route path="/social-apple" element={<SocialApple />} />
          {/* 4 */}
          <Route path="/login1" element={<Login1 />} />
          {/* 5 */}
          <Route path="/login2" element={<Login2 />} />
          {/* 6 */}
          <Route path="/lost-id1" element={<ID />} />
          {/* 7 */}
          <Route path="/lost-id2" element={<ID1 />} />
          {/* 8 */}
          <Route path="/lost-pw1" element={<PW />} />
          {/* 9 */}
          <Route path="/lost-pw2" element={<PW1 />} />
          {/* 10 */}
          <Route path="/register" element={<Register />} />
          {/* 10 */}
          <Route path="/registersetting" element={<RegisterSetting />} />

          {/* 1 */}
          <Route path="/nickname" element={<Nickname />} />
          {/* 2 */}
          <Route path="/lang" element={<Lang />} />
          {/* 3 */}
          <Route path="/mbti" element={<Mbti2 />} />
          {/* 4 */}
          <Route path="/persona" element={<Persona />} />
          {/* 5 */}
          <Route path="/home" element={<Home />} />
          {/* 6 */}
          <Route path="/study" element={<Study />} />
          {/* 7 */}
          <Route path="/express" element={<Express />} />
          {/* 8 */}
          <Route path="/ai" element={<Ai />} />
          {/* 8 */}
          <Route path="/aichat" element={<AiChat />} />
          {/* 9 */}
          <Route path="/mypage" element={<MyPage />} />
          {/* 9 */}
          <Route path="/mypagenotice" element={<MyPageNotice />} />
          {/* 9 */}
          <Route
            path="/mypagenoticecontent"
            element={<MyPageNoticeContent />}
          />
          {/* 9 */}
          <Route path="/mypagefaq" element={<MyPageFaq />} />
          {/* 9 */}
          <Route path="/accountinfo" element={<AccountInfo />} />
          {/* 9 */}
          <Route path="/langsetting" element={<LangSetting />} />
          {/* 9 */}
          <Route path="/personasetting" element={<PersonaSetting />} />
          {/* 10 */}
          <Route path="/vocablist" element={<VocabList />} />
          {/* 10 */}
          <Route path="/associated" element={<Associated />} />
          {/* 12 */}
          <Route path="/studypoint" element={<StudyPoint />} />
          {/* 12 */}
          <Route path="/quiza" element={<QuizA />} />
          {/* 12 */}
          <Route path="/quizb" element={<QuizB />} />
          {/* 12 */}
          <Route path="/starscore" element={<StarScore />} />
          {/* 13 */}
          <Route path="/speech" element={<Speech />} />
          {/* 14 */}
          <Route path="/bookmark" element={<BookMark />} />
          {/* 14 */}
          <Route path="/note" element={<Note />} />

          {/* pr */}
          <Route path="/prac" element={<Prac />} />

          {/* pr */}
          <Route path="/mbtistudy" element={<MbtiStudy />} />
          {/* pr */}
          <Route path="/mbtistudylist" element={<MbtiStudyList />} />
          {/* pr */}
          <Route path="/personastudy" element={<PersonaStudy />} />
          {/* pr */}
          <Route path="/personastudylist" element={<PersonaStudyList />} />
          {/* pr */}
          <Route path="/psychologystudy" element={<PsychologyStudy />} />
          {/* pr */}
          <Route
            path="/psychologystudylist"
            element={<PsychologyStudyList />}
          />
          {/* pr */}
          <Route path="/situationstudy" element={<SituationStudy />} />
          {/* pr */}
          <Route path="/situationstudylist" element={<SituationStudyList />} />

          {/* pr */}
          <Route path="/studyonboard" element={<StudyOnboard />} />
          {/* pr */}
          <Route path="/conversation" element={<Conversation />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  return (
    <Router>
      <AnimatedRoutes></AnimatedRoutes>
    </Router>
  );
}

export default App;
