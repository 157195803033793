import TextField from "../logincomponents/PhoneVerification";
import Title from "../logincomponents/Title";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";

const ID = () => {
  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="아이디 찾기"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField
            prop="이름"
            prop1="이름을 입력하세요"
          ></OptionalTextField>
          <TextField />
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={"다음"} to="/lost-id2" />
      </div>
    </ReactiveOuter>
  );
};

export default ID;
