import TextField from "../logincomponents/PhoneVerification";
import Title from "../logincomponents/Title";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import TBtn from "../logincomponents/TBtn";
import Btn from "../logincomponents/Btn";
import BBtn2 from "../logincomponents/BBtn2";

const ID1 = () => {
  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="아이디 찾기"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField prop="아이디"></OptionalTextField>
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn2 prop={"비밀번호 찾기"} to="/lost-pw1" />
        <div className="h-[10px]"></div>
        <BBtn prop={"로그인하기"} to="/login1" />
      </div>
    </ReactiveOuter>
  );
};

export default ID1;
