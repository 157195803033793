import Logo from "../logincomponents/Logo";
import LoginOptions from "../logincomponents/LoginOptions";
import styles from "./Welcome.module.css";

const Welcome = () => {
  return (
    <div className={styles.div}>
      <Logo />
      <div className="fixed bottom-0 w-full">
        <LoginOptions />
      </div>
    </div>
  );
};

export default Welcome;
