import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/admin/images/logo/logo.png';

const Navigation = () => {
  const [jsonOpen, setJsonOpen] = useState(false);
  const [learnOpen, setLearnOpen] = useState(false);
  const [expressOpen, setExpressOpen] = useState(false);
  const [csOpen, setCsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // 경로가 변경되었을 때
    if (location.pathname.startsWith('/inputJson') || location.pathname.startsWith('/expressJson')) {
      setJsonOpen(true);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
    } else if (
      location.pathname.startsWith('/customize') ||
      location.pathname.startsWith('/situation') ||
      location.pathname.startsWith('/psychology')
    ) {
      setJsonOpen(false);
      setLearnOpen(true);
      setExpressOpen(false);
      setCsOpen(false);
    } else if (location.pathname.startsWith('/expression')) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(true);
      setCsOpen(false);
    } else if (location.pathname.startsWith('/notice') || location.pathname.startsWith('/faq') || location.pathname.startsWith('/error')) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(true);
    } else {
      // 모든 메뉴를 닫음
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
    }
  }, [location.pathname]);

  const toggleJsonMenu = () => {
    setJsonOpen(!jsonOpen);
    if (!jsonOpen) {
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
    }
  };

  const toggleLearnMenu = () => {
    setLearnOpen(!learnOpen);
    if (!learnOpen) {
      setJsonOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
    }
  };

  const toggleExpressMenu = () => {
    setExpressOpen(!expressOpen);
    if (!expressOpen) {
      setJsonOpen(false);
      setLearnOpen(false);
      setCsOpen(false);
    }
  };

  const toggleCsMenu = () => {
    setCsOpen(!csOpen);
    if (!csOpen) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
    }
  };

  const closeMenus = () => {
    setJsonOpen(false);
    setLearnOpen(false);
    setExpressOpen(false);
    setCsOpen(false);
  };

  const isActive = (paths) => paths.some(path => location.pathname.startsWith(path));

  const getMenuClass = (isOpen, paths) => {
    const isSelected = isOpen || isActive(paths);
    return {
      color: isSelected ? 'text-selectedBlue' : 'hover:text-customBlue',
      fontWeight: isSelected ? '700' : '500',
    };
  };

  return (
    <>
      <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50" style={{ height: '68px' }}>
        <div className="container flex items-center" style={{ padding: '0 32px' }}>
          {/* 로고 이미지 */}
          <NavLink to="/customize" className="flex items-center" onClick={closeMenus}>
            <img 
              src={logo} 
              alt="파스텔라 로고" 
              className="h-8" 
              style={{ width: '100px', height: '30px', marginRight: '53px', objectFit: 'contain' }} 
            />
          </NavLink>
          <div className="flex items-center space-x-4" style={{ gap: '36px' }}>
            <div className="relative flex items-center">
              <button
                onClick={toggleJsonMenu}
                className={`flex items-center ${getMenuClass(jsonOpen, ['/inputJson', '/expressJson']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                JSON
              </button>
              {/* 드롭다운 메뉴 */}
              {jsonOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/inputJson"
                      className={`flex items-center ${getMenuClass(isActive(['/inputJson']), ['/inputJson']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/inputJson']), ['/inputJson']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      학습하기
                    </NavLink>
                    <NavLink
                      to="/expressJson"
                      className={`flex items-center ${getMenuClass(isActive(['/expressJson']), ['/expressJson']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/expressJson']), ['/expressJson']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      표현하기
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            <div className="relative flex items-center">
              <button
                onClick={toggleLearnMenu}
                className={`flex items-center ${getMenuClass(learnOpen, ['/customize', '/situation', '/psychology']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                학습하기
              </button>
              {/* 드롭다운 메뉴 */}
              {learnOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/customize"
                      className={`flex items-center ${getMenuClass(isActive(['/customize']), ['/customize']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/customize']), ['/customize']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      CUSTOMIZE
                    </NavLink>
                    <NavLink
                      to="/situation"
                      className={`flex items-center ${getMenuClass(isActive(['/situation']), ['/situation']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/situation']), ['/situation']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      SITUATION
                    </NavLink>
                    <NavLink
                      to="/psychology"
                      className={`flex items-center ${getMenuClass(isActive(['/psychology']), ['/psychology']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/psychology']), ['/psychology']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      PSYCHOLOGY
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            <div className="relative flex items-center">
              <button
                onClick={toggleExpressMenu}
                className={`flex items-center ${getMenuClass(expressOpen, ['/expression']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                표현하기
              </button>
              {/* 드롭다운 메뉴 */}
              {expressOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/expression"
                      className={`flex items-center ${getMenuClass(isActive(['/expression']), ['/expression']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/expression']), ['/expression']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      EXPRESSION
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            {/* CS 관리 메뉴 추가 */}
            <div className="relative flex items-center">
              <button
                onClick={toggleCsMenu}
                className={`flex items-center ${getMenuClass(csOpen, ['/notice', '/faq', '/error']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                CS 관리
              </button>
              {/* 드롭다운 메뉴 */}
              {csOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/notice"
                      className={`flex items-center ${getMenuClass(isActive(['/notice']), ['/notice']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/notice']), ['/notice']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      공지사항
                    </NavLink>
                    <NavLink
                      to="/faq"
                      className={`flex items-center ${getMenuClass(isActive(['/faq']), ['/faq']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/faq']), ['/faq']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      to="/error"
                      className={`flex items-center ${getMenuClass(isActive(['/error']), ['/error']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(isActive(['/error']), ['/error']).fontWeight,
                        lineHeight: '14px',
                      }}
                    >
                      오류신고
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    {/* 컨텐츠를 아래로 밀기 */}
    <div style={{ marginTop: jsonOpen || learnOpen || expressOpen || csOpen ? '113px' : '69px' }} />
    </>
  );
};

export default Navigation;