import { useEffect, useState, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";

const MbtiStudy = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  const handleNextClick = () => {
    // Construct the tagIds string from selectedGenders array
    const tagIds = selectedGenders.join(",");
    // Construct the URL with the query parameters
    const url = `${apiUrl}/customize/managers/by-tags?tagIds=${tagIds}&page=1&limit=10`;
    // Navigate to the constructed URL
    window.location.href = url;
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleSubmit = () => {
    navigate("/personastudylist", {
      state: { keywords: selectedKeys },
    });
  };
  // Use an array to keep track of multiple selected items
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedIndex2, setExpandedIndex2] = useState(null);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [allValue, setAllValue] = useState(true);
  const [data, setData] = useState({});
  const [topics, setTopics] = useState([]);

  const handleSelect2 = (value) => {
    setSelectedKeys((prevSelectedKeys) => {
      if (prevSelectedKeys.includes(value)) {
        return prevSelectedKeys;
      } else {
        // Otherwise, add the value
        return [...prevSelectedKeys, value];
      }
    });
  };

  // Fetch data once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array: fetch data only once on mount

  useEffect(() => {
    if (data && data.children) {
      const topics = data.children.flatMap(
        (firstLevelChild, firstLevelIndex) => {
          const firstLevelNames = firstLevelChild.translations
            ? firstLevelChild.translations
                .filter((translation) => translation.language === "kor")
                .map((translation) => translation.name)
            : [];

          const secondLevel = firstLevelChild.children || [];
          const secondLevelData = secondLevel.flatMap(
            (secondLevelChild, secondLevelIndex) => {
              const secondLevelNames = secondLevelChild.translations
                ? secondLevelChild.translations
                    .filter((translation) => translation.language === "kor")
                    .map((translation) => translation.name)
                : [];

              const reversedSecondLevelNames = [...secondLevelNames].reverse();

              const thirdLevel = secondLevelChild.children || [];
              const thirdLevelData = thirdLevel.flatMap((thirdLevelChild) =>
                thirdLevelChild.translations
                  ? thirdLevelChild.translations
                      .filter((translation) => translation.language === "kor")
                      .map((translation) => ({
                        name: translation.name,
                        id: thirdLevelChild.id,
                      }))
                  : []
              );

              const reversedThirdLevelNames = [...thirdLevelData].reverse();

              return reversedSecondLevelNames.map((name, index) => ({
                id: `second-${firstLevelIndex}-${secondLevelIndex}-${index}`,
                title: name,
                thirdLevelData: reversedThirdLevelNames, // Include third-level data
              }));
            }
          );

          const reversedSecondLevelData = [...secondLevelData].reverse();

          return firstLevelNames.map((firstLevelName, index) => ({
            id: `first-${firstLevelIndex}-${index}`,
            title: firstLevelName,
            content: (
              <div key={`first-content-${index}`}>
                <div className="flex flex-wrap gap-2">
                  {reversedSecondLevelData.map((secondLevelTopic) => (
                    <div key={secondLevelTopic.id}>
                      <div className="font-bold text-[#000] mb-2">
                        {secondLevelTopic.title}
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {/* <div
                          className="flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] border-[#e9ecef] text-[#000]"
                          style={{ flexBasis: "calc(33.333% - 6px)" }}
                        >
                          <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                            전체
                          </div>
                        </div> */}
                        {secondLevelTopic.thirdLevelData.map((thirdName) => (
                          <div
                            key={thirdName.id}
                            onClick={() => {
                              handleSelect(thirdName.id);
                              handleSelect2(secondLevelTopic.title);
                            }}
                            className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                              selectedGenders.includes(thirdName.id)
                                ? "border-[#3677f6] text-[#3677f6]"
                                : "bg-[#e9ecef] border-[#e9ecef] text-[#000]"
                            }`}
                            style={{ flexBasis: "calc(33.333% - 6px)" }}
                          >
                            <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                              {thirdName.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          }));
        }
      );

      setTopics(topics.reverse());
    }
  }, [data, selectedGenders]);

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Close the currently expanded topic
    } else {
      setExpandedIndex(index); // Open the clicked topic
      setExpandedIndex2(index); // Open the clicked topic
    }
  };

  const handleSelect = (value) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(value)) {
        // If the value is already selected, remove it
        return prevSelectedGenders.filter((item) => item !== value);
      } else {
        // Otherwise, add the value
        return [...prevSelectedGenders, value];
      }
    });
  };

  useEffect(() => {
    console.log("리스트내용    ", selectedGenders);
  }, [selectedGenders]);
  useEffect(() => {
    setSelectedGenders([]);
  }, [expandedIndex2]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            Persona
          </h1>
          <LanguageSwitcher></LanguageSwitcher>
        </header>

        <main className="flex flex-col items-center gap-4 px-4 mt-6">
          <div className="w-[328px] h-[117px] shrink-0 flex flex-row items-start justify-between bg-[#fff] rounded-[12px]">
            <div className="w-[296px] self-stretch shrink-0 flex flex-row items-center justify-center">
              <div className="w-[157px] shrink-0 flex flex-col items-center justify-center gap-[14px]">
                <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                  나의 페르소나
                </div>
                <div className="flex flex-row items-start justify-start gap-[8px]">
                  <img width="12" height="11" src="opening.png"></img>
                  <div className="flex flex-row items-start justify-start">
                    <div className="flex flex-row items-center justify-center py-[8px] px-[14px] bg-[#e3edff] border-[1px] border-solid border-[#3677f6] rounded-[59px]">
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                        여성
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start">
                    <div className="flex flex-row items-center justify-center py-[8px] px-[14px] bg-[#e3edff] border-[1px] border-solid border-[#3677f6] rounded-[59px]">
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                        직장인
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start">
                    <div className="flex flex-row items-center justify-center py-[8px] px-[14px] bg-[#e3edff] border-[1px] border-solid border-[#3677f6] rounded-[59px]">
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                        20대
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start">
                    <div className="flex flex-row items-center justify-center py-[8px] px-[14px] bg-[#e3edff] border-[1px] border-solid border-[#3677f6] rounded-[59px]">
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                        싱글
                      </div>
                    </div>
                  </div>
                  <img width="13" height="11" src="closing.png"></img>
                </div>
              </div>
            </div>
            <img
              className="mr-[10px] mt-[10px]"
              width="20"
              height="20"
              src="gear.png"
            ></img>
          </div>

          <section className="w-full">
            <h2 className="text-[16px] font-sans font-bold text-[#000] mb-4">
              주제
            </h2>
            <div className="flex flex-col">
              {topics.map((topic, index) => (
                <div key={index} className="w-full border-b bg-[#fff]">
                  <button
                    onClick={() => handleToggle(index)}
                    className={`flex justify-between items-center w-full p-4 border-b border-[#e9ecef] last:border-none ${
                      expandedIndex !== index &&
                      selectedGenders.length > 0 &&
                      expandedIndex2 !== index
                        ? "text-[#ccc]"
                        : "text-[#000]"
                    }`}
                  >
                    <span className="text-[16px] font-sans font-bold ">
                      {topic.title}
                    </span>
                    <img
                      width="30"
                      height="30"
                      src={`open.png`}
                      alt="Toggle"
                      className={`transition-transform duration-300 ${
                        expandedIndex === index ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </button>
                  {expandedIndex === index && (
                    <div className="p-4 bg-[#fff]">
                      <div className="text-[14px] font-sans">
                        {topic.content}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </main>

        <footer className="bottom-0 py-4 px-4">
          <button
            className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
            onClick={handleSubmit}
          >
            완료
          </button>
        </footer>
      </div>
    </div>
  );
};

export default MbtiStudy;
