import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../logincomponents/Logo";
import styles from "./Splash.module.css";

const Splash = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/welcome");
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={styles.div}>
      <Logo />
      <div className="bottom-[10vh] w-full">
        <div className={styles.easybrainSoftWrapper}>
          <div className={styles.easybrainSoft} style={{ fontSize: "18px" }}>
            ezBrain Soft
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
