import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';

const FaqList = () => {
  const navigate = useNavigate();
  const [title, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const itemsPerPage = 20;

  useEffect(() => {
    fetchNoticeData();
  }, [currentPage]);

  const fetchNoticeData = () => {
    // 가상 데이터 생성
    const fakeData = [
      {
        id: 1,
        author: '관리자',
        category: '일반',
        title: '공지사항 업데이트 안내',
        isVisible: true,
        createdAt: '2024-09-10T12:00:00Z',
        updatedAt: '2024-09-11T14:30:00Z',
      },
      {
        id: 2,
        author: '운영팀',
        category: '결제',
        title: '결제 관련 안내',
        isVisible: false,
        createdAt: '2024-09-12T15:30:00Z',
        updatedAt: '2024-09-13T10:20:00Z',
      },
      {
        id: 3,
        author: '홍길동',
        category: '기능',
        title: '새로운 기능 소개',
        isVisible: true,
        createdAt: '2024-09-14T09:15:00Z',
        updatedAt: '2024-09-14T10:00:00Z',
      }
    ];
    setData(fakeData);
    setTotalItems(fakeData.length);
  };

  const handleSearch = () => {
    fetchNoticeData();
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    try {
      setData((prevData) => prevData.filter((item) => !selectedRows.includes(item.id)));
      setSelectedRows([]);
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(item => item.id));
    }
  };

  const handleCreateScript = async () => {
    try {
      navigate(`/faq-editor`);
    } catch (error) {
      console.error('Failed to create manager:', error);
    }
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        
        {/* 제목 표시 */}
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          FAQ
        </h1>

        {/* 검색 및 필터 영역 */}
        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            {/* 카테고리 필터 */}
            <select value={category} onChange={handleCategoryChange} className="w-[100px] h-[36px]" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '4px 14px', borderRadius: '12px', color: '#A9B6CA'}}>
              <option value="">카테고리</option>
              <option value="일반">일반</option>
              <option value="학습">학습</option>
              <option value="기능">기능</option>
              <option value="결제">결제</option>
              <option value="환불">환불</option>
            </select>

            {/* 검색어 입력 */}
            <div className="flex">
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                value={title}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="h-[36px] w-[192px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px 0px 0px 12px', color: '#A9B6CA' }}
              />
              <button onClick={handleSearch} className="bg-[#3677F6] text-white" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, width: '57px', height: '36px', padding: '4px 4px', borderRadius: '0px 12px 12px 0px' }}>
                검색
              </button>
            </div>

            {/* 기간 필터 */}
            <div className="relative flex items-center h-[36px] w-[250px] bg-white" style={{ padding: '0px 8px', borderRadius: '12px' }}>
              <input
                type="text"
                value={startDate ? `${startDate} ~ ${endDate}` : '기간 입력'}
                readOnly
                className="w-full h-full"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, paddingLeft: '14px', border: 'none', color: '#A9B6CA' }}
              />
              <img src={calendarIcon} alt="Calendar Icon" className="absolute right-2 w-7 h-6" />
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline={false}
                customInput={<></>} 
              />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="text-lg mt-5 mb-7 ml-5">
              <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
              </label>
            </div>
            {/* 액션 버튼들 */}
            <div className="flex space-x-2 mr-5">
              <button 
                onClick={handleDelete} 
                className="p-2 rounded-md text-[#383C4B]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#C5D0DD',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                선택 삭제
              </button>
              <button 
                onClick={handleCreateScript} 
                className="p-2 rounded-md text-[#3677F6]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#E3EDFF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                FAQ 작성
              </button>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
              <tr>
                <th className='text-center w-16'>
                  {/* 모든 항목 선택/해제 체크박스 */}
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-16"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  No.
                </th>
                <th 
                  // onClick={() => handleSort('averageRating')} 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-22"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  작성자<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  카테고리<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  // onClick={() => handleSort('title')} 
                  className="text-left font-medium text-gray-500 tracking-wider cursor-pointer w-109"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  제목<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  출력 여부<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  최초 등록일<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  마지막 수정일<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
              </tr>
            </thead>
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.map((item, index) => (
                <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                  <td className="whitespace-no-wrap text-center w-16">
                    {/* 각 항목 선택 체크박스 */}
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)} // 선택 여부에 따라 체크
                      onChange={(e) => {
                        e.stopPropagation(); // 클릭 이벤트 전파 중지
                        handleSelectRow(item.id); // 선택된 항목 업데이트
                      }}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                  <td className="whitespace-no-wrap text-center">
                    {index + 1}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.author}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.category}
                  </td>
                  <td className="whitespace-no-wrap cursor-pointer">
                    {item.title}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.isVisible ? 'YES' : 'NO'}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {new Date(item.updatedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'} // 페이지 구분자
            pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
            marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
            pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
            onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
            containerClassName={'pagination flex space-x-2 items-center'} // 페이지네이션 컨테이너 스타일
            activeClassName={'text-[#3677F6] font-bold'} // 현재 선택된 페이지 스타일
            breakClassName={'text-[#A9B6CA]'} // 구분자 스타일
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'} // 비활성화 스타일
          />
        </div>
      </div>
    </div>
  );
};

export default FaqList;