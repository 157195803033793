import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Quill 기본 스타일
import Navigation from '../../components/admin/Navigation';

const FaqEditor = () => {
  const navigate = useNavigate();
  
  // 드롭다운 상태 관리
  const [publishStatus, setPublishStatus] = useState('출력');
  const [pinStatus, setPinStatus] = useState('고정');
  const [mainExpose, setMainExpose] = useState('노출');
  
  // Quill 에디터 내용 관리
  const quillRef = useRef(null);
  const [content, setContent] = useState('');
  const quillInstance = useRef(null); // Quill 인스턴스 관리

  useEffect(() => {
    if (!quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'], // 이미지와 링크 추가
            ['clean'], // 포맷 초기화
          ],
        },
      });

      // Quill 에디터의 내용이 변경될 때마다 content 상태 업데이트
      quillInstance.current.on('text-change', () => {
        setContent(quillInstance.current.root.innerHTML);
      });

      // 이미지 업로드 핸들러
      quillInstance.current.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, []);

  // 이미지 업로드 핸들러 함수
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const range = quillInstance.current.getSelection();
        quillInstance.current.insertEmbed(range.index, 'image', reader.result); // Base64 이미지 삽입
      };
      reader.readAsDataURL(file);
    };
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      {/* 공지사항 상세 상단 */}
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left">FAQ 상세</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/notice')}
            className="w-[100px] h-[48px] bg-gray-400 text-white font-bold rounded-lg px-4 py-3"
          >
            목록가기
          </button>
          <button className="w-[100px] h-[48px] bg-blue-500 text-white font-bold rounded-lg px-4 py-3">
            저장하기
          </button>
        </div>
      </div>

      {/* 상태값 섹션 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">상태값</h2>
        <div className="grid grid-cols-3 gap-8">
          {/* 출력 여부 */}
          <div>
            <label className="block text-sm font-bold mb-2">출력여부</label>
            <select
              value={publishStatus}
              onChange={(e) => setPublishStatus(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="출력">출력</option>
              <option value="미출력">미출력</option>
            </select>
          </div>

          {/* 카테고리 설정 여부 */}
          <div>
            <label className="block text-sm font-bold mb-2">카테고리 설정</label>
            <select
              value={mainExpose}
              onChange={(e) => setMainExpose(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="일반">일반</option>
              <option value="학습">학습</option>
              <option value="학습">기능</option>
              <option value="학습">결제</option>
              <option value="학습">환불</option>
            </select>
          </div>
        </div>
      </div>

      {/* 내용 섹션 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">내용</h2>

        {/* 제목 */}
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">질문</label>
          <input
            type="text"
            placeholder="질문을 입력하세요"
            className="w-full h-10 border border-gray-300 rounded-lg px-3"
          />
        </div>

        {/* 내용 입력 */}
        <div>
          <label className="block text-sm font-bold mb-2">답변</label>
          <div className="h-[400px] pb-12">
            <div ref={quillRef}></div> {/* Quill 에디터가 삽입될 DOM 요소 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqEditor;