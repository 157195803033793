import styles from "./Logo.module.css";

const Logo = () => {
  return (
    <section className={styles.frameParent}>
      <div className={styles.unionContainer}>
        <img
          className={styles.unionIcon}
          loading="lazy"
          alt=""
          src="/union1.svg"
        />
      </div>
    </section>
  );
};

export default Logo;
