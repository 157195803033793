import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Make sure to install react-toastify
import { useDispatch, useSelector } from "react-redux";
import { addItems } from "./actions";
import GnbExpress from "./components/GnbExpress";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

const Express = () => {
  const { translations } = useContext(LanguageContext);
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items); // Access items from Redux state
  const [filteredItems, setFilteredItems] = useState(items);
  const [page, setPage] = useState(1); // Manage page or data set state
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const typeList = [
    "구동사",
    "관용구",
    "고정표현",
    "신조어",
    "슬랭",
    "문법적 구조",
    "약어",
    "속담",
    "숙어",
    "단어",
  ];

  const overlayRef = useRef(null); // Ref for the overlay
  const goToVocabList = (item, type) => {
    navigate("/vocablist", {
      state: { item, type },
    });
  };

  const getRandomType = () => {
    const randomIndex = Math.floor(Math.random() * typeList.length);
    return typeList[randomIndex];
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    if (selectedGender) {
      const filtered = items.filter((item) => item.type === selectedGender);
      setFilteredItems(filtered); // Update filtered items
      sessionStorage.setItem("selectedGender", selectedGender);
    } else {
      setFilteredItems(items); // Update filtered items
      sessionStorage.removeItem("selectedGender");
    }
    toggleOverlay(); // Optionally close the overlay
  };

  const loadMoreData = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    setTimeout(() => {
      if (page < 100) {
        const newItems = Array(5)
          .fill()
          .map((_, idx) => ({
            item: `Item ${items.length + idx + 1}`,
            type: getRandomType(),
          }));
        // setData((prevData) => [...prevData, ...newItems]);
        dispatch(addItems(newItems));
        setPage((prevPage) => prevPage + 1); // Increment the page number
      } else {
        setHasMore(false);
        toast.info("End of data");
      }
      setLoading(false);
    }, 100);
  }, [loading, hasMore, page, items, dispatch]);

  const lastScrollTopRef = useRef(
    window.pageYOffset || document.documentElement.scrollTop
  );
  const debounceTimerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop < lastScrollTopRef.current) {
      clearTimeout(debounceTimerRef.current);

      debounceTimerRef.current = setTimeout(() => {
        loadMoreData();
      }, 100); // Adjust the time (2000 ms = 2 seconds) as needed
    }

    lastScrollTopRef.current = currentScrollTop;
  }, [loadMoreData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimerRef.current);
    };
  }, [handleScroll]);

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    const storedGender = sessionStorage.getItem("selectedGender");
    if (storedGender) {
      setSelectedGender(storedGender);
    }
    if (items.length < 5) {
      loadMoreData();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = selectedGender
      ? items.filter((item) => item.type === selectedGender)
      : items;
    setFilteredItems(filtered);
  }, [items, selectedGender]);

  const handleSwipeLeft = () => {
    navigate("/ai");
  };

  const handleSwipeRight = () => {
    navigate("/study");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  return (
    <div
      id="swipe-container"
      className="flex min-h-screen justify-center w-full bg-[#faf8f6]"
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative w-[100%] bg-[#faf8f6]">
        {/* Navigation Bar */}
        <GnbExpress></GnbExpress>
        {/* Main Content */}
        <div className="relative w-full h-full flex justify-center">
          <div className="flex-grow relative w-full">
            <div className="relative left-[3vw] top-[97px] w-[94vw] flex flex-col items-start justify-start rounded-[20px] mb-[200px]">
              <div className="w-[100%] mx-auto">
                <div className="relative w-full h-[20px] flex items-center justify-end">
                  <div className="flex flex-row items-center gap-[5px]">
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] text-right whitespace-nowrap">
                      최신순
                    </div>
                    <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                      알파벳순
                    </div>
                  </div>
                </div>
              </div>
              {filteredItems.map((dataItem, index) => (
                <div
                  key={index}
                  onClick={() => goToVocabList(dataItem.item, dataItem.type)}
                  className="self-stretch flex flex-row items-center justify-between py-[20px] px-[14px] bg-[#fff] border-b border-#e9ecef"
                >
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-[5px] h-[5px] shrink-0 bg-[#f67239] rounded-full"></div>
                    <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-justify whitespace-nowrap">
                      {dataItem.item}
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-justify whitespace-nowrap">
                    {dataItem.type}
                  </div>
                </div>
              ))}
            </div>

            {/* Overlay */}
            <div
              ref={overlayRef}
              className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
                isOverlayVisible
                  ? "transform translate-y-0"
                  : "transform translate-y-full"
              }`}
              style={{
                height: "80%",
                zIndex: 100,
                borderTopLeftRadius: "25px", // Adjust the radius value as needed
                borderTopRightRadius: "25px", // Adjust the radius value as needed
              }}
            >
              {/* Title of the overlay */}
              <div className="p-4">
                <h2 className="text-lg font-bold">필터 설정</h2>
              </div>
              {/* Content of the overlay */}
              <div className="flex flex-wrap gap-[6px] justify-center">
                {typeList.map((gender) => (
                  <div
                    key={gender}
                    className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                      selectedGender === gender
                        ? "border-[#3677f6] text-[#3677f6]"
                        : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                    }`}
                    onClick={() => handleSelect("gender", gender)}
                    style={{ flexBasis: "calc(33.333% - 6px)" }} // Make each item take approximately one-third of the row width
                  >
                    <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                      {gender}
                    </div>
                  </div>
                ))}
              </div>

              {/* Footer */}
              <div className="fixed bottom-0 w-full">
                <div className="flex-none pb-[20px] px-[16px]">
                  <button
                    className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
                    onClick={handleComplete}
                  >
                    설정 완료
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute left-0 top-[2vh] w-[360px] h-[50px]">
              <div className="absolute left-[16px] top-[7px] w-[92vw] flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                  표현하기
                </div>
                <div className="flex flex-row items-center justify-end gap-[8px]">
                  <div className="h-[36px] flex flex-row items-center justify-center">
                    <div className="relative w-[30px] h-[30px] shrink-0">
                      <div className="absolute left-0 right-0 top-0 bottom-0">
                        <img
                          className="absolute left-[3px] top-[3px]"
                          width="24"
                          height="24"
                          src="filter.png"
                          onClick={toggleOverlay}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <LanguageSwitcher></LanguageSwitcher>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Express;
