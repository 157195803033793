// src/api/admin/connect.js

import axios from 'axios';
import API_BASE_URL from '../../config';

// customize 특정 Connect 데이터 가져오기 (수정용)
export const fetchConnect = async (managerId) => {
    try {
      // 먼저 해당 managerId로 manage 데이터를 가져옵니다.
      const response = await axios.get(`${API_BASE_URL}/customize/manage/${managerId}`);
      const manageData = response.data;

      // manageData에서 customizes 배열을 가져옵니다.
      const connectData = manageData.customizes;
      
      // 모든 connect 데이터를 반환합니다.
      return connectData;
    } catch (error) {
      console.error('Error fetching connect:', error);
      throw error;
    }
};

// situation 특정 Connect 데이터 가져오기 (수정용)
export const situationFetchConnect = async (managerId) => {
  try {
    // 먼저 해당 managerId로 manage 데이터를 가져옵니다.
    const response = await axios.get(`${API_BASE_URL}/situation/manage/${managerId}`);
    const manageData = response.data;

    // manageData에서 situation 배열을 가져옵니다.
    const connectData = manageData.situations;
    
    // 모든 connect 데이터를 반환합니다.
    return connectData;
  } catch (error) {
    console.error('Error fetching connect:', error);
    throw error;
  }
};

// psychology 특정 Connect 데이터 가져오기 (수정용)
export const psychologyFetchConnect = async (managerId) => {
  try {
    // 먼저 해당 managerId로 manage 데이터를 가져옵니다.
    const response = await axios.get(`${API_BASE_URL}/psychology/manage/${managerId}`);
    const manageData = response.data;

    // manageData에서 psychology 배열을 가져옵니다.
    const connectData = manageData.psychologys;
    
    // 모든 connect 데이터를 반환합니다.
    return connectData;
  } catch (error) {
    console.error('Error fetching connect:', error);
    throw error;
  }
};

// customize Connect 데이터 생성
export const createConnect = async (connectData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/customize/connect`, connectData);
      return response.data;
    } catch (error) {
      console.error('Error creating connect:', error);
      throw error;
    }
};  

// situation Connect 데이터 생성
export const situationCreateConnect = async (connectData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/situation/connect`, connectData);
    return response.data;
  } catch (error) {
    console.error('Error creating connect:', error);
    throw error;
  }
};  

// psychology Connect 데이터 생성
export const psychologyCreateConnect = async (connectData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/psychology/connect`, connectData);
    return response.data;
  } catch (error) {
    console.error('Error creating connect:', error);
    throw error;
  }
};  

// customize script와 connect연결
export const linkScriptToConnect = async (connectId, scriptId) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/customize/connect/${connectId}?scriptId=${scriptId}`);
      return response.data;
    } catch (error) {
      console.error('Error linking script to connect:', error);
      throw error;
    }
};

// situation script와 connect연결
export const situationLinkScriptToConnect = async (connectId, scriptId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/situation/connect/${connectId}?scriptId=${scriptId}`);
    return response.data;
  } catch (error) {
    console.error('Error linking script to connect:', error);
    throw error;
  }
};

// psychology script와 connect연결
export const psychologyLinkScriptToConnect = async (connectId, scriptId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/psychology/connect/${connectId}?scriptId=${scriptId}`);
    return response.data;
  } catch (error) {
    console.error('Error linking script to connect:', error);
    throw error;
  }
};

// customize Connect 업데이트
export const updateConnect = async (connectId, scriptId, updateData = {}) => {
    try {
      const {
        managerId,  // 필수 값
        title = '',  // 기본값 설정
        language = '',  // 기본값 설정
        type = '',  // 기본값 설정
      } = updateData;
  
      if (!managerId) {
        throw new Error('Manager ID is required for updating connect');
      }

      // 쿼리 파라미터를 사용해 scriptId 전달
      const url = `${API_BASE_URL}/customize/connect/${connectId}?scriptId=${scriptId}`;
  
      const response = await axios.put(url, {
        managerId,
        title,
        language,
        type,
      });
  
      return response.data;
    } catch (error) {
      console.error(`Error updating connect:`, error.response ? error.response.data : error.message);
      throw error;
    }
};

// situation Connect 업데이트
export const situationUpdateConnect = async (connectId, scriptId, updateData = {}) => {
  try {
    const {
      managerId,  // 필수 값
      title = '',  // 기본값 설정
      language = '',  // 기본값 설정
      type = '',  // 기본값 설정
    } = updateData;

    if (!managerId) {
      throw new Error('Manager ID is required for updating connect');
    }

    // 쿼리 파라미터를 사용해 scriptId 전달
    const url = `${API_BASE_URL}/situation/connect/${connectId}?scriptId=${scriptId}`;

    const response = await axios.put(url, {
      managerId,
      title,
      language,
      type,
    });

    return response.data;
  } catch (error) {
    console.error(`Error updating connect:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

// psychology Connect 업데이트
export const psychologyUpdateConnect = async (connectId, scriptId, updateData = {}) => {
  try {
    const {
      managerId,  // 필수 값
      title = '',  // 기본값 설정
      language = '',  // 기본값 설정
      type = '',  // 기본값 설정
    } = updateData;

    if (!managerId) {
      throw new Error('Manager ID is required for updating connect');
    }

    // 쿼리 파라미터를 사용해 scriptId 전달
    const url = `${API_BASE_URL}/psychology/connect/${connectId}?scriptId=${scriptId}`;

    const response = await axios.put(url, {
      managerId,
      title,
      language,
      type,
    });

    return response.data;
  } catch (error) {
    console.error(`Error updating connect:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

// customize 특정 Connect 삭제
export const deleteConnect = async (connectId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/customize/connect/${connectId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting connect:', error);
      throw error;
    }
};

// situation 특정 Connect 삭제
export const situationDeleteConnect = async (connectId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/situation/connect/${connectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting connect:', error);
    throw error;
  }
};

// psychology 특정 Connect 삭제
export const psychologyDeleteConnect = async (connectId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/psychology/connect/${connectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting connect:', error);
    throw error;
  }
};