import React, { useState, useContext } from "react";
import { LanguageContext } from "./LanguageContext";

const LanguageSelector = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Korean");

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang === "en" ? "English" : "Korean");
    setModalOpen(true);
    setTimeout(() => setModalOpen(false), 700); // Auto close modal after 2 seconds
  };

  const { switchLanguage } = useContext(LanguageContext);

  return (
    <div>
      {/* Language Selector Button */}
      {selectedLanguage === "Korean" ? (
        <div
          className="flex items-center justify-center w-[36px] h-[36px] border-2 border-solid bg-[#25272f] border-[#25272f] rounded-full cursor-pointer"
          onClick={() => {
            handleLanguageChange(selectedLanguage === "English" ? "ko" : "en");
            switchLanguage();
          }}
        >
          <span className="text-[16px] font-sans font-semibold text-[#fff]">
            가
          </span>
        </div>
      ) : (
        <div
          className="flex items-center justify-center w-[36px] h-[36px] border-2 border-solid border-[#25272f] rounded-full cursor-pointer"
          onClick={() => {
            handleLanguageChange(selectedLanguage === "English" ? "ko" : "en");
            switchLanguage();
          }}
        >
          <span className="text-[16px] font-sans font-semibold text-[#25272f]">
            Aa
          </span>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[300px] text-center">
            <h2 className="text-lg font-semibold mb-4">언어 변경</h2>
            <p className="text-md">{selectedLanguage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
