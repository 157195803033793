import styles from "./FormForm.module.css";

const FormForm = ({ children }) => {
  return (
    <div className={styles.frameDiv}>
      <form className={styles.textFieldParent}>{children}</form>
    </div>
  );
};

export default FormForm;
