import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Title.module.css";
import { useNavigate } from "react-router-dom";

const Title = ({
  className = "",
  titleGap,
  frameDivWidth,
  prop,
  bAlignSelf,
  bHeight,
  bOpacity,
  bMinWidth,
  bDisplay,
  to,
}) => {
  const navigate = useNavigate();

  // Container style
  const titleStyle = useMemo(() => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center contents within the container
      position: "relative", // Position relative for the absolute button
      width: "100%", // Full width container
    };
  }, [titleGap]);

  // Button style
  const buttonStyle = {
    position: "absolute",
    left: "2vh",
  };

  // Text style
  const bStyle = useMemo(() => {
    return {
      alignSelf: bAlignSelf,
      height: bHeight,
      opacity: bOpacity,
      minWidth: bMinWidth,
      display: bDisplay,
      margin: "0 auto", // Ensure it's centered
      textAlign: "center", // Center text within the <b> element
    };
  }, [bAlignSelf, bHeight, bOpacity, bMinWidth, bDisplay]);

  const handleClick = () => {
    navigate(to ? to : -1);
  };

  return (
    <div className={[styles.title, className].join(" ")} style={titleStyle}>
      <button
        className={styles.transparentButton}
        onClick={handleClick}
        style={buttonStyle}
      >
        <img
          className={styles.iconChild}
          loading="lazy"
          alt=""
          src="/back.png"
        />
      </button>
      {prop && (
        <b className={styles.b} style={bStyle}>
          {prop}
        </b>
      )}
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,

  /** Style props */
  titleGap: PropTypes.any,
  frameDivWidth: PropTypes.any,
  bAlignSelf: PropTypes.any,
  bHeight: PropTypes.any,
  bOpacity: PropTypes.any,
  bMinWidth: PropTypes.any,
  bDisplay: PropTypes.any,
};

export default Title;
