import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LangSetting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedOption, setSelectedOption] = useState("선택해주세요");
  const [selectedOption2, setSelectedOption2] = useState("선택해주세요");

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const handleToggle2 = () => {
    setIsOpen2((prev) => !prev);
  };

  const handleOptionClick2 = (option) => {
    setSelectedOption2(option);
    setIsOpen2(false);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleStart = () => {
    if (
      selectedOption !== "선택해주세요" &&
      selectedOption2 !== "선택해주세요"
    ) {
      navigate("/accountinfo");
    } else {
      toast.error("모든 옵션을 선택해 주세요.");
    }
  };
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
          언어 정보
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 px-4 py-[20px] flex flex-col gap-5">
        {/* Language Selection */}
        <div className="flex flex-col gap-5">
          {/* Native Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[18px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000]">
              모국어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000]">
              Native Language
            </div>
            <div className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]">
              <div className="flex-1 text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                {selectedOption}
              </div>
              <button
                onClick={handleToggle}
                className="w-[30px] h-[30px] flex items-center justify-center"
              >
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#d5dce4] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {["English", "한국어"].map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Target Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[18px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000]">
              학습언어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000]">
              Target Language
            </div>
            <div className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]">
              <div className="flex-1 text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                {selectedOption2}
              </div>
              <button
                onClick={handleToggle2}
                className="w-[30px] h-[30px] flex items-center justify-center"
              >
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen2 && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#d5dce4] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {["English", "한국어"].map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick2(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="w-full h-[48px] flex items-center justify-between py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                선택해주세요
              </div>
              <img
                width="30"
                height="30"
                src="iconI5_75;189_11838;198_4987.png"
                alt="icon"
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]" onClick={handleStart}>
        <button className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold">
          저장하기
        </button>
      </div>
      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default LangSetting;
