import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PsychologyStudy = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const handleNextClick = () => {
    const tagIds = selectedGenders.join(",");
    const url = `${apiUrl}/customize/managers/by-tags?tagIds=${tagIds}&page=1&limit=10`;
    window.location.href = url;
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleSubmit = (keyword) => {
    navigate("/psychologystudylist", {
      state: {
        keyword,
      },
    });
  };

  const [selectedGenders, setSelectedGenders] = useState([]);
  const [data, setData] = useState([]);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/3`);
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    if (data && data.children) {
      const topics = data.children.flatMap((child) => {
        const names = child.translations
          ? child.translations
              .filter((translation) => translation.language === "kor")
              .map((translation) => ({
                id: child.id,
                name: translation.name,
              }))
          : [];
        return names;
      });

      setTopics(topics);
    }
  }, [data]);

  const handleSelect = (id) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(id)) {
        return prevSelectedGenders.filter((item) => item !== id);
      } else {
        return [...prevSelectedGenders, id];
      }
    });
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        <header className="flex items-center justify-between py-8 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            Psychology
          </h1>
        </header>

        <main className="flex flex-col items-center gap-4 px-4 mt-6">
          <section className="w-full">
            <div className="flex flex-col items-start gap-[12px]">
              {topics.map((topic) => (
                <div
                  key={topic.id}
                  className={`self-stretch h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px] cursor-pointer ${
                    selectedGenders.includes(topic.id)
                      ? "border-[#3677f6] text-[#3677f6]"
                      : "border-[#e9ecef] text-[#000]"
                  }`}
                  onClick={() => {
                    handleSelect(topic.id);
                    handleSubmit(topic.name);
                  }}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                    {topic.name}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PsychologyStudy;
