import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchTreeNodes } from '../../api/admin/treeNode';

const ExpressionTreeNodeSelector = ({ manageData, onTagsChange, mode }) => {
  const [treeData, setTreeData] = useState(null);
  const [selected1Depth, setSelected1Depth] = useState(null);
  const [selected2Depth, setSelected2Depth] = useState(null);
  const [selected3Depth, setSelected3Depth] = useState(null);
  const [options2Depth, setOptions2Depth] = useState([]);
  const [options3Depth, setOptions3Depth] = useState([]);
  const [savedSelections, setSavedSelections] = useState([]);
  const [maxDepth, setMaxDepth] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTreeNodes(4); // 'Expression'에 해당하는 ID 4를 사용
      setTreeData(data);
      const preloadedSelections = createTreeFromIds(manageData.tag, data);
      setSavedSelections(preloadedSelections);
      setMaxDepth(findMaxDepth(preloadedSelections));

    };

    fetchData();
  }, [mode, manageData]);

  const createTreeFromIds = (ids, data) => {
    const selections = [];
    ids.forEach(id => {
      let foundNode = findNodeById(data, id);
      if (foundNode) {
        selections.push(foundNode);
      }
    });
    return buildSelectionPath(selections, data);
  };

  const findNodeById = (node, id) => {
    if (node.id === id) return node;
    if (node.children) {
      for (let child of node.children) {
        const result = findNodeById(child, id);
        if (result) return result;
      }
    }
    return null;
  }

  const buildSelectionPath = (selections, data) => {
    const paths = [];
    selections.forEach(node => {
      const path = [];
      let currentNode = node;
      while (currentNode) {
        path.unshift({ label: getNodeLabel(currentNode), value: currentNode.id });
        currentNode = findParentNode(data, currentNode.id);
      }
      paths.push(path);
    });
    return paths;
  };

  const findParentNode = (data, id) => {
    for (let node of data.children || []) {
      if (node.children?.some(child => child.id === id)) {
        return node;
      }
      const found = findParentNode(node, id);
      if (found) return found;
    }
    return null;
  };

  const getNodeLabel = (node) => {
    const koTranslation = node.translations.find(t => t.language === 'kor');
    return koTranslation?.name || node.name;
  };

  const handle1DepthChange = (selectedOption) => {
    setSelected1Depth(selectedOption);
    setSelected2Depth(null); // 2Depth 초기화
    setSelected3Depth(null); // 3Depth 초기화
    setOptions3Depth([]); // 3Depth 옵션 초기화

    if (treeData?.children) {
      const selectedNode = treeData.children.find(node => node.id === selectedOption.value);
      if (selectedNode?.children) {
        const options = selectedNode.children.map(child => ({
          label: getNodeLabel(child),
          value: child.id,
        }));
        setOptions2Depth(options);
      } else {
        setOptions2Depth([]);
      }
    }
  };

  const handle2DepthChange = (selectedOption) => {
    setSelected2Depth(selectedOption);
    setSelected3Depth(null); // 3Depth 초기화

    if (selected1Depth && treeData?.children) {
      const selectedNode = treeData.children.find(node => node.id === selected1Depth.value);
      if (selectedNode?.children) {
        const selectedSubNode = selectedNode.children.find(child => child.id === selectedOption.value);
        if (selectedSubNode?.children) {
          const options = selectedSubNode.children.map(child => ({
            label: getNodeLabel(child),
            value: child.id,
          }));
          setOptions3Depth(options);
        } else {
          setOptions3Depth([]);
        }
      }
    }
  };

  const handle3DepthChange = (selectedOption) => {
    setSelected3Depth(selectedOption);
  };

  const handleSaveSelection = () => {
    const newSelection = [];

    if (selected1Depth) newSelection.push(selected1Depth);
    if (selected2Depth) newSelection.push(selected2Depth);
    if (selected3Depth) newSelection.push(selected3Depth);

    if (newSelection.length > 2) {
      const updatedSelections = [...savedSelections, newSelection];
      setSavedSelections(updatedSelections);

      // Value 값만을 추출하여 중복 제거 후 1차 배열 생성
      const valueArray = Array.from(new Set(updatedSelections.flatMap(selection =>
        selection.map(sel => sel.value)
      )));

      // 저장된 배열 상태를 콘솔에 출력
      console.log("Saved Selections Array:", updatedSelections);

      // 저장된 value 배열을 콘솔에 출력
      console.log("Value Array:", valueArray);

      // manageData.tag에 저장된 valueArray를 전달
      onTagsChange(valueArray);

      // 선택 상태 초기화
      setSelected1Depth(null);
      setSelected2Depth(null);
      setSelected3Depth(null);
      setOptions2Depth([]);
      setOptions3Depth([]);
    } else {
      alert("3 DEPTH까지 선택하세요.");
    }
  };

  const findMaxDepth = (selections) => {
    return Math.max(...selections.map(path => path.length));
  };

  const filterByMaxDepth = (selections) => {
    const filteredSelections = selections.map(path => path.slice(0, maxDepth));
    return buildTree(filteredSelections);
  };

  const buildTree = (paths) => {
    const tree = [];

    paths.forEach(path => {
      let currentLevel = tree;

      path.forEach((node, index) => {
        let existingNode = currentLevel.find(n => n.value === node.value);

        if (!existingNode) {
          existingNode = { ...node, children: [] };
          currentLevel.push(existingNode);
        }

        if (index === path.length - 1) {
          // If it's the last node in the path, we don't go deeper
          return;
        }

        currentLevel = existingNode.children;
      });
    });

    return tree;
  };

  const renderTree = (nodes, currentPath = []) => {
    if (!nodes || nodes.length === 0) return [];
  
    const paths = [];
  
    nodes.forEach(node => {
      const newPath = [...currentPath, node.label];
      if (node.children && node.children.length > 0) {
        paths.push(...renderTree(node.children, newPath));
      } else {
        paths.push(newPath.join(' > '));
      }
    });
  
    return paths;
  };

  const handleDeleteSelection = (index) => {
    // renderTree로부터 경로 배열을 생성
    const paths = renderTree(filterByMaxDepth(savedSelections));
  
    // 해당 인덱스의 경로를 삭제
    paths.splice(index, 1);
  
    // 삭제된 경로를 제외한 나머지 경로들을 다시 ID로 변환
    const updatedSelections = paths.map(path => {
      const labels = path.split(' > ');
      let currentLevel = treeData;
      return labels.map(label => {
        const node = currentLevel.children.find(child => getNodeLabel(child) === label);
        currentLevel = node;
        return node.id;
      });
    });
  
    // 중복된 ID를 제거한 1차 배열로 변환
    const valueArray = Array.from(new Set(updatedSelections.flat()));
  
    // 상태 업데이트 및 manageData.tag에 반영
    onTagsChange(valueArray);
  };

  const options1Depth = treeData?.children?.map(node => ({
    label: getNodeLabel(node),
    value: node.id,
  })) || [];

  return (
    <div className='mt-4'>
      {/* DEPTH selectors */}
      <div className='flex space-x-8'>
        <div className='flex-grow'>
          <label
            className="block mb-2.5 text-left font-bold" 
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '14px',
            }}
          >
            1 Depth
          </label>
          <Select
            value={selected1Depth}
            onChange={handle1DepthChange}
            options={options1Depth}
            className="rounded-md text-gray-500"
            styles={{
              control: (provided) => ({
                ...provided,
                height: '48px',
                borderRadius: '12px',
                border: '1px solid #E2E8EF',
                fontFamily: 'Pretendard Variable',
                fontSize: '14px',
                fontWeight: 700,
              }),
            }}
          />
        </div>
        <div className="flex-grow">
          <label 
            className="block mb-2.5 text-left font-bold" 
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '14px',
            }}
          >
            2 Depth
          </label>
          <Select
            value={selected2Depth}
            onChange={handle2DepthChange}
            options={options2Depth}
            isDisabled={!selected1Depth}
            className="rounded-md text-gray-500"
            styles={{
              control: (provided) => ({
                ...provided,
                height: '48px',
                borderRadius: '12px',
                border: '1px solid #E2E8EF',
                fontFamily: 'Pretendard Variable',
                fontSize: '14px',
                fontWeight: 700,
              }),
            }}
          />
        </div>
        <div className="flex-grow">
          <label 
            className="block mb-2.5 text-left font-bold" 
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '14px',
            }}
          >
            3 Depth
          </label>
          <Select
            value={selected3Depth}
            onChange={handle3DepthChange}
            options={options3Depth}
            isDisabled={!selected2Depth}
            className="rounded-md text-gray-500"
            styles={{
              control: (provided) => ({
                ...provided,
                height: '48px',
                borderRadius: '12px',
                border: '1px solid #E2E8EF',
                fontFamily: 'Pretendard Variable',
                fontSize: '14px',
                fontWeight: 700,
              }),
            }}
          />
        </div>
        <div className="flex items-end">
          <button 
            onClick={handleSaveSelection} 
            className="h-[48px] bg-[#E3EDFF] text-[#3677F6] rounded-lg px-4 py-2"
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            추가하기
          </button>
        </div>
      </div>

      {/* 저장된 선택들 */}
      <div className="mt-4">
        <ul className="flex flex-wrap mt-2">
          {renderTree(filterByMaxDepth(savedSelections)).map((path, index) => (
            <li 
              key={index} 
              className="mr-2 px-3 py-2 rounded-full border border-[#3677F6] text-[#3677F6]"
              style={{
                fontFamily: 'Pretendard Variable',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '14px',
              }}
            >
              {path} 
              <button 
                className="ml-2"
                onClick={() => handleDeleteSelection(index)} 
              >
                x
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExpressionTreeNodeSelector;