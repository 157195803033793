import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MyPageNotice = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/mypagenoticecontent");
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold">
          공지사항 목록
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>
      {/* content */}
      <div className="relative w-[100%] min-h-screen bg-[#faf8f6] overflow-hidden">
        <div
          className="absolute left-0 w-[100%] min-h-screen flex flex-col items-start justify-start bg-[#fff] overflow-hidden"
          onClick={handleNextClick}
        >
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="text-[14px] leading-[100%] font-sans font-bold text-[#f67239] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
              <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#e9ecef] rounded-[13px]">
                <div className="text-[10px] leading-[100%] font-sans font-medium text-[#f67239] text-justify whitespace-nowrap">
                  New
                </div>
              </div>
              <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#f67239] rounded-[13px]">
                <div className="text-[10px] leading-[100%] font-sans font-medium text-[#fff] text-justify whitespace-nowrap">
                  중요!
                </div>
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="text-[14px] leading-[100%] font-sans font-bold text-[#f67239] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
              <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#e9ecef] rounded-[13px]">
                <div className="text-[10px] leading-[100%] font-sans font-medium text-[#f67239] text-justify whitespace-nowrap">
                  New
                </div>
              </div>
              <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#f67239] rounded-[13px]">
                <div className="text-[10px] leading-[100%] font-sans font-medium text-[#fff] text-justify whitespace-nowrap">
                  중요!
                </div>
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
              <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#e9ecef] rounded-[13px]">
                <div className="text-[10px] leading-[100%] font-sans font-medium text-[#f67239] text-justify whitespace-nowrap">
                  New
                </div>
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] border-[solid] border-#e9ecef border border-[0_0_1px]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff]">
            <div className="flex flex-row items-center justify-start">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] text-justify whitespace-nowrap">
                공지사항의 제목이 들어갑니다
              </div>
            </div>
            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
              24.07.11
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPageNotice;
