import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PsychologyForm from '../../components/admin/PsychologyForm';
import PsychologySaveButton from '../../components/admin/PsychologySaveButton';
import { psychologyCreateManage, psychologyFetchManage, psychologyUpdateManage } from '../../api/admin/manage';
import { psychologyCreateConnect, psychologyFetchConnect, psychologyUpdateConnect } from '../../api/admin/connect'; 
import { psychologyCreateScript, psychologyFetchScriptData, psychologyUpdateScript } from '../../api/admin/script';
import { psychologyCreateStudyPoint, psychologyCreateMultipleStudyPoints, psychologyFetchStudyPointsByScriptId, psychologyUpdateStudyPoint, psychologyDeleteStudyPoint } from '../../api/admin/studyPoint'; 
import { psychologyCreateQuiz, psychologyCreateMultipleQuizzes, psychologyFetchQuizzesByScriptId, psychologyUpdateQuiz, psychologyDeleteQuiz } from '../../api/admin/quiz';
import TreeNodeSelector from '../../components/admin/TreeNodeSelector';
import Navigation from '../../components/admin/Navigation';


const PsychologyManage = ({ mode = 'create' }) => {
    const navigate = useNavigate();
    const { managerId } = useParams();
  
    const [tagOptions, setTagOptions] = useState({
      mbti: [],
      sex: [],
      age: [],
      job: [],
      love: [],
      partnermbti: [],
      countrycode: [],
      languages: []
    });
  
    // manage 데이터 상태
    const [manageData, setManageData] = useState({
      title: '',
      sex: '', 
      age: '', 
      job: '', 
      love: '', 
      mbti: '', 
      partnermbti: '', 
      countrycode: '', 
      hobby: '',
      used: false,
      serialnumber: '',
      imageurl: '',
      memo: '',
      link: '',
      tag: []
    });
  
    const handleTagsChange = (newTags) => {
        setManageData(prevState => {
            const updatedManageData = {
                ...prevState,
                tag: newTags
            };
            return updatedManageData;
        });
    };
    
  
    // connect 데이터 상태
    const [connectData, setConnectData] = useState({
        kor: {
          title: '',
          language: 'kor'
        },
        eng: {
          title: '',
          language: 'eng'
        }
    });
    
    // 스크립트 데이터 상태
    const [scriptData, setScriptData] = useState({
        kor: {
            easy: {
                languagecode: '',
                language: 'kor',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'easy'
            },
            normal: {
                languagecode: '',
                language: 'kor',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'normal'
            },
            difficult: {
                languagecode: '',
                language: 'kor',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'difficult'
            }
        },
        eng: {
            easy: {
                languagecode: '',
                language: 'eng',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'easy'
            },
            normal: {
                languagecode: '',
                language: 'eng',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'normal'
            },
            difficult: {
                languagecode: '',
                language: 'eng',
                background: '',
                humancount: 2,
                humanA: '',
                humanB: '',
                humanC: '',
                humanD: '',
                script: '',
                note: '',
                type: 'difficult'
            }
        }
        }
    );
  
    // 스터디 포인트 데이터 상태
    const [studyPointsData, setStudyPointsData] = useState({
        kor: {
            easy: [{ title: '', content: '', scriptId: null }],
            normal: [{ title: '', content: '', scriptId: null }],
            difficult: [{ title: '', content: '', scriptId: null }]
        },
        eng: {
            easy: [{ title: '', content: '', scriptId: null }],
            normal: [{ title: '', content: '', scriptId: null }],
            difficult: [{ title: '', content: '', scriptId: null }]
        }
    });
  
    // 퀴즈 데이터 상태
    const [quizzesData, setQuizzesData] = useState({
        kor: {
        easy: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }],
        normal: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }],
        difficult: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }]
        },
        eng: {
        easy: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }],
        normal: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }],
        difficult: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }]
        }
    });
  
    // Quiz 유형 옵션
    const [quizTypes, setQuizTypes] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [languageSections, setLanguageSections] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadMetaData = async () => {
            try {
                const response = await fetch('/select_kor_eng.json');
                const data = await response.json();
    
                setTagOptions({
                    mbti: Object.keys(data.mbti || []),
                    sex: Object.keys(data.sex || []),
                    age: Object.keys(data.age || []),
                    job: Object.keys(data.job || []),
                    love: Object.keys(data.love || []),
                    partnermbti: Object.keys(data.partnermbti || []),
                    countrycode: Object.keys(data.countrycode || []),
                    languages: Object.keys(data.language || [])
                });
    
                setQuizTypes(Object.keys(data.quiz || []));
            } catch (error) {
                console.error('Error loading metaData:', error);
            }
        };
    
        const loadManageData = async () => {
            if (mode === 'edit' && managerId) {
                try {
                    // Fetch manage data by managerId
                    const manageResponse = await psychologyFetchManage(managerId);

                    // Tag 변환 작업
                    const formattedTags = manageResponse.tag.map(tagItem => tagItem.id);

                    // manageData 설정
                    setManageData({
                        ...manageResponse,
                        tag: formattedTags, // tag를 1차원 배열로 설정
                    });
                    console.log('Updated manageData:', {
                        ...manageResponse,
                        tag: formattedTags,
                    });        
                    
                    console.log('formattedTags',formattedTags);
                    // Get the customizes array from manageResponse
                    console.log('managerId:',managerId);
                    const connectResponse = await psychologyFetchConnect(managerId);

                    const updatedLanguageSections = {};

                    console.log('connectResponse:',connectResponse);
        
                    // Loop over each connect item to fetch and set related data
                    for (const connect of connectResponse) {
                        const { language, title, easyId, normalId, difficultId } = connect;
        
                        // Update connectData with title and language
                        setConnectData(prevState => ({
                            ...prevState,
                            [language]: {
                                title: title || '',
                                language: language
                            }
                        }));
        
                        // Fetch scripts for each difficulty level (easy, normal, difficult)
                        const [easyScript, normalScript, difficultScript] = await Promise.all([
                            psychologyFetchScriptData(easyId),
                            psychologyFetchScriptData(normalId),
                            psychologyFetchScriptData(difficultId),
                        ]);

                        updatedLanguageSections[language] = {
                            easy: easyScript || {},
                            normal: normalScript || {},
                            difficult: difficultScript || {},
                        };
        
                        // Set scriptData state for the current language
                        setScriptData(prevState => ({
                            ...prevState,
                            [language]: {
                                easy: easyScript || {},
                                normal: normalScript || {},
                                difficult: difficultScript || {}
                            }
                        }));
        
                        // Fetch study points for each script by difficulty level
                        const [easyStudyPoints, normalStudyPoints, difficultStudyPoints] = await Promise.all([
                            psychologyFetchStudyPointsByScriptId(easyId),
                            psychologyFetchStudyPointsByScriptId(normalId),
                            psychologyFetchStudyPointsByScriptId(difficultId),
                        ]);
        
                        // Set studyPointsData state for the current language
                        setStudyPointsData(prevState => ({
                            ...prevState,
                            [language]: {
                                easy: easyStudyPoints,
                                normal: normalStudyPoints,
                                difficult: difficultStudyPoints,
                            }
                        }));
        
                        // Fetch quizzes for each script by difficulty level
                        const [easyQuizzes, normalQuizzes, difficultQuizzes] = await Promise.all([
                            psychologyFetchQuizzesByScriptId(easyId),
                            psychologyFetchQuizzesByScriptId(normalId),
                            psychologyFetchQuizzesByScriptId(difficultId),
                        ]);
        
                        // Set quizzesData state for the current language
                        setQuizzesData(prevState => ({
                            ...prevState,
                            [language]: {
                                easy: easyQuizzes,
                                normal: normalQuizzes,
                                difficult: difficultQuizzes,
                            }
                        }));
                    }
                    setLanguageSections(updatedLanguageSections);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Ensure loading state is false after data fetch
                }
            } else if (mode === 'create') {
                const scripts = {
                    easy: {
                        languagecode: '',
                        language: 'kor',
                        background: '',
                        humancount: 2,
                        humanA: '',
                        humanB: '',
                        humanC: '',
                        humanD: '',
                        script: '',
                        note: '',
                        type: 'easy'
                    },
                    normal: {
                        languagecode: '',
                        language: 'kor',
                        background: '',
                        humancount: 2,
                        humanA: '',
                        humanB: '',
                        humanC: '',
                        humanD: '',
                        script: '',
                        note: '',
                        type: 'normal'
                    },
                    difficult: {
                        languagecode: '',
                        language: 'kor',
                        background: '',
                        humancount: 2,
                        humanA: '',
                        humanB: '',
                        humanC: '',
                        humanD: '',
                        script: '',
                        note: '',
                        type: 'difficult'
                    }
                };

    
                setScriptData(prevState => ({
                    ...prevState,
                    kor: scripts,
                    eng: { ...scripts, language: 'eng' }
                }));
    
                setStudyPointsData(prevState => ({
                    ...prevState,
                    kor: {
                        easy: [{ title: '', content: '', scriptId: null }],
                        normal: [{ title: '', content: '', scriptId: null }],
                        difficult: [{ title: '', content: '', scriptId: null }]
                    },
                    eng: {
                        easy: [{ title: '', content: '', scriptId: null }],
                        normal: [{ title: '', content: '', scriptId: null }],
                        difficult: [{ title: '', content: '', scriptId: null }]
                    }
                }));
    
                setQuizzesData(prevState => ({
                    ...prevState,
                    kor: {
                        easy: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }],
                        normal: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }],
                        difficult: [{ type: '', question: '', result: '', explanation: '', language: 'kor', scriptId: null }]
                    },
                    eng: {
                        easy: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }],
                        normal: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }],
                        difficult: [{ type: '', question: '', result: '', explanation: '', language: 'eng', scriptId: null }]
                    }
                }));
    
                const initialLanguageSections = {
                    kor: {
                        ...scripts,
                        studyPoints: {
                            easy: [],
                            normal: [],
                            difficult: []
                        },
                        quizzes: {
                            easy: [],
                            normal: [],
                            difficult: []
                        }
                    },
                    eng: {
                        ...scripts,
                        language: 'eng',
                        studyPoints: {
                            easy: [],
                            normal: [],
                            difficult: []
                        },
                        quizzes: {
                            easy: [],
                            normal: [],
                            difficult: []
                        }
                    }
                };
    
                setLanguageSections(initialLanguageSections);
    
                setIsLoading(false);
            }
        };
    
        loadMetaData();
        loadManageData();
    }, [mode,managerId]);

    const handleManageChange = (updatedData) => {
        setManageData(prevState => ({
        ...prevState,
        ...updatedData
        }));
    };

    const handleConnectChange = (updatedData) => {
        setConnectData(prevState => ({
            ...prevState,
            ...updatedData
        }));
    };

    const handleImageUpload = (url) => {
        setManageData(prevData => ({
        ...prevData,
        imageurl: url
        }));
    };

    const handleLanguageChange = (language) => {
        setConnectData(prevState => ({
          ...prevState,
          [language]: {
            ...prevState[language],
            language: language
          }
        }));
    };

    const handleTitleChange = (language, newTitle) => {
        setConnectData(prevState => ({
            ...prevState,
            [language]: {
            ...prevState[language],
            title: newTitle
            }
        }));
    };
      

    const handleScriptChange = (language, type, updatedScript) => {
        setScriptData(prevState => ({
          ...prevState,
          [language]: {
            ...prevState[language],
            [type]: updatedScript,
          }
        }));
    };

    // handleStudyPointsChange 수정
    const handleStudyPointsChange = useCallback((language, type, updatedStudyPoints) => {
        setStudyPointsData(prevState => {
            const prevLanguageData = prevState[language] || {};
            const newLanguageData = {
                ...prevLanguageData,
                [type]: updatedStudyPoints,
            };

            return {
                ...prevState,
                [language]: newLanguageData,
            };
        });
    }, []);

    // handleQuizzesChange 수정
    const handleQuizzesChange = useCallback((language, type, updatedQuizzes) => {
        setQuizzesData(prevState => {
            const prevLanguageData = prevState[language] || {};
            const newLanguageData = {
                ...prevLanguageData,
                [type]: updatedQuizzes,
            };

            return {
                ...prevState,
                [language]: newLanguageData,
            };
        });
    }, []);

    // handleRemoveStudyPoint 수정
    const handleRemoveStudyPoint = useCallback(async (language, type, index) => {
        const studyPoints = studyPointsData[language]?.[type];

        if (!studyPoints || index < 0 || index >= studyPoints.length) {
            console.error(`Invalid index: ${index}, studyPoints length: ${studyPoints.length}`);
            return;
        }

        const studyPointToRemove = studyPoints[index];

        if (studyPointToRemove && studyPointToRemove.id) {
            try {
                await psychologyDeleteStudyPoint(studyPointToRemove.id);
                console.log(`ID가 ${studyPointToRemove.id}인 스터디 포인트가 성공적으로 삭제되었습니다.`);
            } catch (error) {
                console.error('스터디 포인트 삭제 중 오류 발생:', error);
                return;
            }
        } else {
            console.warn(`ID가 없는 스터디 포인트를 삭제하려고 합니다:`, studyPointToRemove);
        }

        const updatedStudyPoints = studyPoints.filter((_, i) => i !== index);
        setStudyPointsData(prevState => ({
            ...prevState,
            [language]: {
                ...prevState[language],
                [type]: updatedStudyPoints.length > 0 ? updatedStudyPoints : [{ title: '', content: '', scriptId: scriptData[language]?.[type]?.id }]
            }
        }));
    }, [studyPointsData, scriptData]);
  
    // handleRemoveQuiz 수정
    const handleRemoveQuiz = useCallback(async (language, type, index) => {
        const quizzes = quizzesData[language]?.[type];

        if (!quizzes || index < 0 || index >= quizzes.length) {
            console.error(`Invalid index: ${index}, quizzes length: ${quizzes.length}`);
            return;
        }

        const quizToRemove = quizzes[index];

        if (quizToRemove && quizToRemove.id) {
            try {
                await psychologyDeleteQuiz(quizToRemove.id);
                console.log(`ID가 ${quizToRemove.id}인 퀴즈가 성공적으로 삭제되었습니다.`);
            } catch (error) {
                console.error('퀴즈 삭제 중 오류 발생:', error);
                return;
            }
        } else {
            console.warn(`ID가 없는 퀴즈를 삭제하려고 합니다:`, quizToRemove);
        }

        const updatedQuizzes = quizzes.filter((_, i) => i !== index);
        setQuizzesData(prevState => ({
            ...prevState,
            [language]: {
                ...prevState[language],
                [type]: updatedQuizzes.length > 0 ? updatedQuizzes : [{ type: quizTypes[0] || 'blank', question: '', result: '', explanation: '', scriptId: scriptData[language]?.[type]?.id }]
            }
        }));
    }, [quizzesData, scriptData, quizTypes]);

    const handleCopy = () => {
        // manageData는 title, sex, age, job, love, mbti, partnermbti, countrycode, hobby, used만 유지하고 나머지 초기화
        setManageData(prevData => ({
            title: prevData.title,
            sex: prevData.sex, 
            age: prevData.age, 
            job: prevData.job, 
            love: prevData.love, 
            mbti: prevData.mbti, 
            partnermbti: prevData.partnermbti, 
            countrycode: prevData.countrycode, 
            hobby: prevData.hobby,
            used: prevData.used,
            imageurl: '',
            memo: '',
            link: '',
            tag: []
        }));

        // connectData 초기화
        setConnectData({
            title: '',
            language: ''
        });

        // scriptData 초기화
        setScriptData({
        easy: {
            languagecode: '',
            language: 'kor',
            background: '',
            humancount: 2,
            humanA: '',
            humanB: '',
            humanC: '',
            humanD: '',
            script: '',
            note: '',
            type: 'easy'
        },
        normal: {
            languagecode: '',
            language: 'kor',
            background: '',
            humancount: 2,
            humanA: '',
            humanB: '',
            humanC: '',
            humanD: '',
            script: '',
            note: '',
            type: 'normal'
        },
        difficult: {
            languagecode: '',
            language: 'kor',
            background: '',
            humancount: 2,
            humanA: '',
            humanB: '',
            humanC: '',
            humanD: '',
            script: '',
            note: '',
            type: 'difficult'
        }
        });

        // studyPointsData 초기화
        setStudyPointsData({
            easy: [],
            normal: [],
            difficult: []
        });

        // quizzesData 초기화
        setQuizzesData({
            easy: [],
            normal: [],
            difficult: []
        });

        alert("스크립트가 초기화되었습니다.");
    };

    const [visibleLanguages, setVisibleLanguages] = useState(['kor']);

    const handleAddLanguage = () => {
        const languagesToAdd = ['eng', 'jpn'];
        const nextLanguage = languagesToAdd.find(lang => !visibleLanguages.includes(lang));
    
        if (nextLanguage) {
        setVisibleLanguages(prevVisibleLanguages => [...prevVisibleLanguages, nextLanguage]);
        } else {
        console.log('No more languages to add');
        }
    };

    const handleLanguageReset = (language) => {
        // LanguageSelector 관련 데이터 초기화
        setConnectData(prevState => ({
          ...prevState,
          [language]: {
            title: '',
            language: language
          }
        }));
      
        // Script 관련 데이터 초기화
        setScriptData(prevState => ({
          ...prevState,
          [language]: {
            easy: {
              languagecode: '',
              language: language,
              background: '',
              humancount: 2,
              humanA: '',
              humanB: '',
              humanC: '',
              humanD: '',
              script: '',
              note: '',
              type: 'easy'
            },
            normal: {
              languagecode: '',
              language: language,
              background: '',
              humancount: 2,
              humanA: '',
              humanB: '',
              humanC: '',
              humanD: '',
              script: '',
              note: '',
              type: 'normal'
            },
            difficult: {
              languagecode: '',
              language: language,
              background: '',
              humancount: 2,
              humanA: '',
              humanB: '',
              humanC: '',
              humanD: '',
              script: '',
              note: '',
              type: 'difficult'
            }
          }
        }));
      
        // StudyPoint 관련 데이터 초기화
        setStudyPointsData(prevState => ({
          ...prevState,
          [language]: {
            easy: [{ title: '', content: '', scriptId: null }],
            normal: [{ title: '', content: '', scriptId: null }],
            difficult: [{ title: '', content: '', scriptId: null }]
          }
        }));
      
        // Quiz 관련 데이터 초기화
        setQuizzesData(prevState => ({
          ...prevState,
          [language]: {
            easy: [{ type: '', question: '', result: '', explanation: '', language: language, scriptId: null }],
            normal: [{ type: '', question: '', result: '', explanation: '', language: language, scriptId: null }],
            difficult: [{ type: '', question: '', result: '', explanation: '', language: language, scriptId: null }]
          }
        }));
      
        // 언어의 사용을 비활성화 (만약 관련된 상태가 있다면)
        setVisibleLanguages(prevState => prevState.filter(l => l !== language));
    };

    const handleSave = async () => {
        try {
            if (mode === 'create') {
                const manageResponse = await psychologyCreateManage(manageData);

                const managerId = manageResponse.id;
                const languages = ['kor', 'eng'];
                // const languages = Object.keys(connectData); 
                const newLanguageSections = {};

                for (const language of languages) {
                    const connectResponse = await psychologyCreateConnect({
                        ...connectData[language],
                        managerId: managerId,
                        language: language,
                        title: connectData[language].title
                    });

                    const connectId = connectResponse.id;
                    const scriptIds = {};

                    for (let type of ['easy', 'normal', 'difficult']) {
                        const scriptResponse = await psychologyCreateScript({ 
                            ...scriptData[language][type],
                            type: type,
                            language: language
                         });
                        const scriptId = scriptResponse.id;
                        scriptIds[type] = scriptId;
                        await psychologyUpdateConnect(connectId, scriptId, {
                            managerId: managerId,
                            language: language,
                            type: type,
                            title: connectData[language].title
                        });
                        const studyPoints = studyPointsData[language][type].map(sp => ({ ...sp, scriptId: scriptIds[type] }));
                        if (studyPoints.length > 0) {
                            await psychologyCreateMultipleStudyPoints(studyPoints);
                        }
                        const quizzes = quizzesData[language][type].map(quiz => ({ ...quiz, scriptId: scriptIds[type] }));
                        if (quizzes.length > 0) {
                            await psychologyCreateMultipleQuizzes(quizzes);
                        }
                    }

                    newLanguageSections[language] = {
                        connectResponse,
                        scriptIds,
                    };
                }
                setLanguageSections(newLanguageSections);
                alert('스크립트 저장이 완료되었습니다.');
            } else if (mode === 'edit' && managerId) {
                const manageResponse = await psychologyUpdateManage(managerId, manageData);

                const connectResponse = await psychologyFetchConnect(managerId);
                const scriptIds = {};

                for (const connect of connectResponse) {
                    const connectId = connect.id;
                    const { language, title } = connect;
                    const types = ['easy', 'normal', 'difficult'];

                    for (let type of types) {
                        const scriptId = connect[`${type}Id`];
                        scriptIds[type] = scriptId;

                        await psychologyUpdateScript(scriptId, { ...scriptData[language][type], type });

                        await psychologyUpdateConnect(connectId, scriptId, {
                            managerId: managerId,
                            language: language,
                            type: type,
                            title: connectData[language].title
                        });
                        const studyPoints = studyPointsData[language][type].map(sp => ({ ...sp, scriptId: scriptIds[type] }));
                        for (let studyPoint of studyPoints) {
                            if (studyPoint.id) {
                                await psychologyUpdateStudyPoint(studyPoint.id, studyPoint);
                            } else {
                                await psychologyCreateStudyPoint(studyPoint);
                            }
                        }
                        const quizzes = quizzesData[language][type].map(quiz => ({ ...quiz, scriptId: scriptIds[type] }));
                        for (let quiz of quizzes) {
                            if (quiz.id) {
                                await psychologyUpdateQuiz(quiz.id, quiz);
                            } else {
                                await psychologyCreateQuiz(quiz);
                            }
                        }
                    }
                }
                setLanguageSections(prevState => ({
                    ...prevState,
                    ...connectResponse.reduce((acc, connect) => {
                        acc[connect.language] = {
                            ...acc[connect.language],
                            ...scriptIds,
                        };
                        return acc;
                    }, {})
                }));
                alert('스크립트 수정이 완료되었습니다.');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            alert('스크립트 저장 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
        <Navigation />
        <PsychologyForm 
            mode={mode}
            tagOptions={tagOptions}
            manageData={manageData} 
            setManageData={setManageData}
            onManageChange={handleManageChange} 
            connectData={connectData}
            onConnectChange={handleConnectChange}
            handleTitleChange={handleTitleChange}
            scriptData={scriptData} 
            onScriptChange={handleScriptChange}
            onImageUpload={handleImageUpload}
            onFileDelete={() => handleManageChange({ imageurl: '' })}
            studyPointsData={studyPointsData}
            onStudyPointsChange={handleStudyPointsChange}
            quizzesData={quizzesData}
            onQuizzesChange={handleQuizzesChange}
            quizTypes={quizTypes} 
            onRemoveStudyPoint={handleRemoveStudyPoint}
            onRemoveQuiz={handleRemoveQuiz} 
            languageSections={languageSections}
            setLanguageSections={setLanguageSections}
            availableLanguages={tagOptions.languages} 
            onLanguageChange={handleLanguageChange} 
            onTagsChange={handleTagsChange}
            initialTags={manageData.tag} 
            visibleLanguages={visibleLanguages}
            setVisibleLanguages={setVisibleLanguages}
            onLanguageReset={handleLanguageReset}
        />
        <PsychologySaveButton 
            onSave={handleSave} 
            onCopy={handleCopy} 
            onAddLanguage={handleAddLanguage} 
            mode={mode} 
            navigate={navigate}
        />
        </div>
    );
};

export default PsychologyManage;