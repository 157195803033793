import { useNavigate } from "react-router-dom";

const GnbExpress = () => {
  const navigate = useNavigate();

  // Handler functions for navigation
  const goToHome = () => navigate("/home");
  const goToStudy = () => navigate("/study");
  const goToExpress = () => navigate("/express");
  const goToAI = () => navigate("/ai");
  const goToMyPage = () => navigate("/mypage");
  return (
    <div className="absolute left-0 bottom-0 w-full flex flex-col items-start justify-start">
      <div
        className="fixed bottom-0 left-0 w-full bg-[#fff] border-none z-50"
        style={{ boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="flex flex-row items-start justify-between pt-[12px] px-[20px] pb-[16px]">
          <button
            className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
            onClick={goToHome}
          >
            <img
              width="24"
              height="24"
              src="homeI12_83;216_3180.png"
              alt="홈"
            />
            <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
              홈
            </div>
          </button>
          <button
            className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
            onClick={goToStudy}
          >
            <img
              width="24"
              height="24"
              src="pencilI12_192;216_3182.png"
              alt="학습하기"
            />
            <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
              학습하기
            </div>
          </button>
          <button
            className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
            onClick={goToExpress}
          >
            <img
              width="24"
              height="24"
              src="expressionI12_613;216_3184.png"
              alt="표현하기"
            />
            <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
              표현하기
            </div>
          </button>
          <button
            className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
            onClick={goToAI}
          >
            <div className="relative w-[24px] h-[24px] shrink-0">
              <img
                className="absolute left-[1px] top-[2px]"
                width="22"
                height="20"
                src="Group 1261155132I12_192;216_3186;216_3138.png"
                alt="AI 맞춤학습"
              />
            </div>
            <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
              AI 맞춤학습
            </div>
          </button>
          <button
            className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
            onClick={goToMyPage}
          >
            <div className="relative w-[24px] h-[24px] shrink-0">
              <img
                className="absolute left-[1px] top-[1px]"
                width="21"
                height="21"
                src="Group 1261155133I12_83;216_3194;216_3139.png"
                alt="마이페이지"
              />
            </div>
            <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
              마이페이지
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GnbExpress;
