import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate, useLocation } from "react-router-dom";

const QuizA = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { id } = location.state || {};
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  const ProgressBar = ({ filledSections }) => {
    const totalSections = 6;
    const gapWidth = 4;

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };
  // first overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [answer, setAnswer] = useState("");
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (selectedDifficulty) {
      case "E":
        return "e.png";
      case "N":
        return "n.png";
      case "D":
        return "d.png";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  useEffect(() => {
    if (isModalVisible2) {
      const timer = setTimeout(() => {
        setisModalVisible2(false);
      }, 2000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isModalVisible2]);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoStudy = () => {
    navigate("/study");
  };
  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'
  const handleMarkAction = () => {
    setSelectedMark(!selectedMark);
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  // State to track the current selected image ("x" or "o") or none
  const [selected, setSelected] = useState(null);

  // Handle clicking on an image
  const handleClick = (image) => {
    if (selected === image) {
      // If the same image is clicked again, toggle it back to "before" state
      setSelected(null);
      setAnswer("");
    } else {
      // Otherwise, set the clicked image as the selected one
      setSelected(image);
      setAnswer(image);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoNext = () => {
    if (data2 === "x") {
      navigate("/starscore");
    } else {
      navigate("/quizb", { state: { id: id } });
    }
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/customize_quiz?scriptId=${id}`);
        const fetchedData = await response.json();

        // Find index of the "ox" type item
        const oxIndex = fetchedData.findIndex((item) => item.type === "ox");
        const blankIndex = fetchedData.length === 2 ? "ok" : "x";

        // Check if the item exists
        if (oxIndex !== -1) {
          setData(fetchedData[oxIndex]); // Set the found item
          setData2(blankIndex); // Set the found item
        } else {
          console.error("No 'ox' type found in the data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      {isOverlayVisible2 && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="relative w-[100%] bg-[#faf8f6]">
        <div className="absolute left-0 top-[78%] w-full flex flex-col items-center justify-center">
          <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoBack}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="Group 1261155146I46_187;328_5268.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={toggleOverlay2}
            >
              <div className="absolute left-0 top-0 flex flex-col items-center justify-start gap-[8px]">
                <div className="relative w-[50px] h-[50px] shrink-0">
                  <img
                    className="relative left-0 top-0"
                    width="50"
                    height="50"
                    src="Group 1261155148I46_191;426_15471.png"
                  ></img>
                  <img
                    className="absolute left-[39.72%] right-[38.6%] top-[32.15%] bottom-[32.5%]"
                    width="10"
                    height="17"
                    src={getImageSrc()}
                  ></img>
                </div>
                <div className="self-stretch text-[12px] leading-[100%] font-sans text-[#000] text-center">
                  난이도
                </div>
              </div>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={handleGoClick}
            >
              <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-full"></div>
              <div className="absolute left-[15px] top-[58px] text-[12px] leading-[100%] font-sans text-[#000] text-center whitespace-nowrap">
                채점
              </div>
              <img
                className="absolute left-[16px] top-[20px]"
                width="18"
                height="12"
                src="Vector 19446_196.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={handleMarkAction}
            >
              <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
              <div className="absolute left-[9px] top-[57px] w-[32px] h-[12px] text-[12px] leading-[100%] font-sans text-[#000] text-center">
                북마크
              </div>
              <img
                className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[calc(50%+-9px)]"
                width="13"
                height="24"
                src={getMarkImage()}
              ></img>
            </div>
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoNext}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="Group 1261155146I46_201;328_5268.png"
              ></img>
            </div>
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[10px] w-full h-[52px] overflow-hidden">
          {/* Header */}
          <div className="absolute top-0 left-0 right-0 flex items-center justify-between px-[16px]">
            {/* Left: xbutton */}
            <div className="w-[36px] flex items-center">
              <div className="relative w-[36px] h-[36px]">
                <img
                  className="w-full h-full"
                  src="xbutton.png"
                  alt="Close Button"
                  onClick={toggleModal}
                />
              </div>
            </div>
            {/* Center: Quiz Text */}
            <div className="text-[16px] font-sans font-semibold text-[#000]">
              Quiz
            </div>
            {/* Right: ddd2 Image */}
            <div className="w-[28px] flex items-center justify-end">
              <img
                width="28"
                height="28"
                src="ddd2.png"
                alt="Overlay Toggle"
                onClick={toggleOverlay}
              />
            </div>
          </div>
          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 w-full">
            <ProgressBar filledSections={3} />
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[80px] w-[91%] h-[60%]">
          <div className="absolute left-[87%] top-[2%] flex flex-row items-center justify-start z-10">
            <LanguageSwitcher></LanguageSwitcher>
          </div>
          <div className="absolute left-0 top-0 w-[100%] h-[100%] bg-[#fff]">
            <div className="absolute -translate-x-1/2 left-1/2 top-[30%] w-[277px] flex flex-col items-start justify-start gap-[10px]">
              <div className="self-stretch text-[18px] leading-[28px] font-sans font-bold text-[#000]">
                Quiz 1.
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-center justify-start">
                  <div className="text-[16px] leading-[100%] font-sans text-[#000] break-word">
                    {data.question}
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 right-0 bottom-[60px] flex justify-center gap-[20px]">
              <img
                width="100"
                height="100"
                src={selected === "x" ? "x_after.png" : "x_before.png"}
                alt="Image 1"
                onClick={() => handleClick("x")}
                className="cursor-pointer" // Make the image look clickable
              />
              <img
                width="100"
                height="100"
                src={selected === "o" ? "o_after.png" : "o_before.png"}
                alt="Image 2"
                onClick={() => handleClick("o")}
                className="cursor-pointer" // Make the image look clickable
              />
            </div>
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[72%] w-[91%] h-[23px] flex">
          <div className="absolute left-0 top-[19px] w-[100%] h-[4px] bg-[#fff]"></div>
          <img
            className="absolute left-0 top-[19px]"
            width="212"
            height="4"
            src="Vector 3 (Stroke)46_230.png"
          ></img>
          <div className="absolute left-0 top-0 text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] whitespace-nowrap">
            00:32
          </div>
          <div className="absolute left-[90%] top-0 text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
            00:59
          </div>
        </div>
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              설정
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 2 */}
            <div className="flex flex-wrap px-[10px] items-center flex-grow font-sans">
              <div className="font-bold">Time Check</div>
              <div className="flex flex-wrap items-center justify-end pr-[10px] flex-grow font-sans">
                OFF·ON
              </div>
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 3 */}
            <div className="flex flex-wrap px-[10px] justify-start items-center flex-grow font-sans">
              <div className="font-bold">Confirm Button</div>
              <div className="flex flex-wrap items-center justify-end pr-[10px] flex-grow font-sans">
                OFF·ON
              </div>
            </div>
          </div>
        </div>

        {/* Overlay2 */}
        <div
          ref={overlayRef2}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible2
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay2 */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              난이도
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("E")}
            >
              EASY
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("N")}
            >
              NORMAL
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("D")}
            >
              DIFFICULT
            </div>
          </div>
        </div>

        {/* Modal */}
        {isModalVisible2 && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
            <div className="w-[60%] h-[20%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-hidden animate-slideIn">
              <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
                Correct!
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                설명이 들어가는 자리입니다
              </div>
              <button
                className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {isModalVisible && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            onClick={() => setModalVisible(false)}
          >
            <div
              className="w-[60%] h-[120px] flex flex-col rounded-[10px] overflow-hidden bg-[#faf8f6]"
              onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
            >
              {/* Modal Content */}
              <div className="flex-1 flex items-center justify-center pt-[20px] pb-[20px] px-0">
                <div className="text-[14px] leading-[155%] font-['Pretendard_Variable'] font-medium text-[#000] text-center">
                  학습을 종료하시나요?
                </div>
              </div>
              {/* Button Row */}
              <div className="h-[46px] flex border-t border-[#c5d0dd]">
                <div className="flex-1 h-full flex items-center justify-center border-r border-[#c5d0dd]">
                  <button
                    className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#666b81] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                    onClick={handleModalAction1}
                  >
                    취소
                  </button>
                </div>
                <div className="flex-1 h-full flex items-center justify-center">
                  <button
                    className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#3677f6] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
                    onClick={handleGoStudy}
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizA;
