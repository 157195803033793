// src/api/admin/studyPoint.js
import axios from 'axios';
import API_BASE_URL from '../../config';

// customize 단일 StudyPoint 생성
export const createStudyPoint = async (studyPointData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/customize_studypoint`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error creating study point:', error);
        throw error;
    }
};

// situation 단일 StudyPoint 생성
export const situationCreateStudyPoint = async (studyPointData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/situation_studypoint`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error creating study point:', error);
        throw error;
    }
};

// psychology 단일 StudyPoint 생성
export const psychologyCreateStudyPoint = async (studyPointData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/psychology_studypoint`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error creating study point:', error);
        throw error;
    }
};

// customize 여러 StudyPoints 생성
export const createMultipleStudyPoints = async (studyPointsData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/customize_studypoint/multicreate`, studyPointsData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple study points:', error);
        throw error;
    }
};

// situation 여러 StudyPoints 생성
export const situationCreateMultipleStudyPoints = async (studyPointsData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/situation_studypoint/multicreate`, studyPointsData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple study points:', error);
        throw error;
    }
};

// psychology 여러 StudyPoints 생성
export const psychologyCreateMultipleStudyPoints = async (studyPointsData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/psychology_studypoint/multicreate`, studyPointsData);
        return response.data;
    } catch (error) {
        console.error('Error creating multiple study points:', error);
        throw error;
    }
};

// customize 특정 StudyPoint 가져오기
export const fetchStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/customize_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching study point:', error);
        throw error;
    }
};

// situation 특정 StudyPoint 가져오기
export const situationFetchStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/situation_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching study point:', error);
        throw error;
    }
};

// psychology 특정 StudyPoint 가져오기
export const psychologyFetchStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/psychology_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching study point:', error);
        throw error;
    }
};

// customize scriptId로 모든 StudyPoint 가져오기
export const fetchStudyPointsByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/customize_studypoint`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching study points by scriptId:', error);
        throw error;
    }
};

// situation scriptId로 모든 StudyPoint 가져오기
export const situationFetchStudyPointsByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/situation_studypoint`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching study points by scriptId:', error);
        throw error;
    }
};

// psychology scriptId로 모든 StudyPoint 가져오기
export const psychologyFetchStudyPointsByScriptId = async (scriptId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/psychology_studypoint`, {
            params: { scriptId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching study points by scriptId:', error);
        throw error;
    }
};

// customize StudyPoint 업데이트
export const updateStudyPoint = async (studyPointId, studyPointData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/customize_studypoint/${studyPointId}`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error updating study point:', error);
        throw error;
    }
};

// situation StudyPoint 업데이트
export const situationUpdateStudyPoint = async (studyPointId, studyPointData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/situation_studypoint/${studyPointId}`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error updating study point:', error);
        throw error;
    }
};

// psychology StudyPoint 업데이트
export const psychologyUpdateStudyPoint = async (studyPointId, studyPointData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/psychology_studypoint/${studyPointId}`, studyPointData);
        return response.data;
    } catch (error) {
        console.error('Error updating study point:', error);
        throw error;
    }
};

// StudyPoint 삭제
export const deleteStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/customize_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting study point:', error);
        throw error;
    }
};

// situation StudyPoint 삭제
export const situationDeleteStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/situation_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting study point:', error);
        throw error;
    }
};

// psychology StudyPoint 삭제
export const psychologyDeleteStudyPoint = async (studyPointId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/psychology_studypoint/${studyPointId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting study point:', error);
        throw error;
    }
};