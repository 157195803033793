import Title from "../logincomponents/Title";
import TextField from "../logincomponents/PhoneVerification";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import BottomButtons from "../logincomponents/BottomButtons";

const PW = () => {
  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="비밀번호 재설정"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField prop="아이디"></OptionalTextField>
          <TextField />
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BottomButtons prop1="다음" to1="/lost-pw2" />
      </div>
    </ReactiveOuter>
  );
};

export default PW;
