import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GnbHome from "./components/GnbHome";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { LanguageContext } from "./LanguageContext";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import { useSelector } from "react-redux";
// New import style (for newer versions of jwt-decode)
import { jwtDecode as jwt_decode } from "jwt-decode";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext);
  const userId = useSelector((state) => state.userId);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [attendanceCount, setAttendanceCount] = useState("");
  const [scriptCount, setScriptCount] = useState("");
  const [userQuizRating, setUserQuizRating] = useState("");
  const [initialTime, setInitialTime] = useState(168 * 3600);

  // Retrieve the passed data
  const { state } = location;
  // console.log("토큰  ", userId);
  const gender = state?.selected?.gender;

  // Handler functions for navigation
  const goToBookMark = () => navigate("/bookmark");
  const goToNote = () => navigate("/note");

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "slick-dots", // Maintain default class for positioning
    arrows: false,
  };

  const textSize = parseInt(attendanceCount, 10) < 7 ? "10px" : "14px";
  const screenSize = parseInt(attendanceCount, 10) < 7 ? "1245px" : "1045px";

  // Image sources for the carousel
  const images = [
    "slide1.png",
    "slide2.png",
    "slide3.png",
    "slide4.png",
    "slide5.png",
  ];

  const handleSwipeLeft = () => {
    navigate("/study");
  };

  const handleSwipeRight = () => {};

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const fetchInfo = async (accessToken) => {
    try {
      console.log("보내는 토큰!! ", accessToken);
      const response = await fetch(`${apiUrl}/auth/user-statistics`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();
        console.log("최근접속시간   ", result.lastLogin);
        setAttendanceCount(result.attendanceCount);
        setScriptCount(result.scriptCount);
        setUserQuizRating(result.userQuizRating);
        setInitialTime(
          initialTime - 24 * 3600 * (parseInt(attendanceCount, 10) - 1)
        );
        // console.log("Account deleted successfully:", result);
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        console.error("Failed to delete account:", response.statusText);
        // Handle error response
      }
    } catch (error) {
      console.error("Error during the request:", error);
      // Handle request error
    }
  };

  // Example function to decode the token
  function decodeToken(token) {
    try {
      const decoded = jwt_decode(token);
      console.log(decoded);
      return decoded;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }

  useEffect(() => {
    fetchInfo(userId);
    decodeToken(userId);
  }, []);

  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    // Set up an interval to update the time every second
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1); // Decrement the time by 1 second
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Convert seconds into HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  useEffect(() => {
    // Load the NicePay script when the component mounts
    const script = document.createElement("script");
    script.src = "https://pay.nicepay.co.kr/v1/js/";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const serverAuth = () => {
    // Ensure the script is loaded and available
    if (window.AUTHNICE) {
      window.AUTHNICE.requestPay({
        clientId: "S2_d0daab4e828149d587407426c7ed63bc",
        method: "card",
        orderId: "1ec82d20-4e79-4362-8b74-33ea56be7b0b",
        amount: 1004,
        goodsName: "나이스페이-상품",
        returnUrl: "https://pastellapp.com/api/payment/serverAuth", //API를 호출할 Endpoint 입력
        fnError: function (result) {
          alert("개발자확인용 : " + result.errorMsg);
        },
      });
    } else {
      console.error("AUTHNICE is not loaded yet");
    }
  };

  return (
    <div id="swipe-container" className="flex justify-center w-full">
      <div
        style={{ height: screenSize }}
        className="relative w-full bg-[#faf8f6]"
      >
        {/* Navigation Bar */}
        <GnbHome></GnbHome>

        <div className="w-full h-[654px] flex items-center justify-center">
          <div className="absolute left-0 top-0 w-full h-[600px] bg-[linear-gradient(180deg,rgba(47,157,235,1)_0%,rgba(54,119,246,0)_100%)]"></div>
          <div className="absolute left-0 top-0 w-full h-[854px] bg-[linear-gradient(180deg,#ffffff00_0%,#ffffff1a_100%)] filter-[backdrop-blur(75px)]"></div>
        </div>

        <div className="absolute left-1/2 top-[100px] w-[254px] h-[254px] flex transform -translate-x-1/2">
          {/* <div className="top-0 w-[284px] h-[284px] border-[7px] border-dashed border-[#fff] rounded-full opacity-[.2]"></div>
          <div className="absolute inset-0 border-dashed border-4 border-[#fff] rounded-full circular-dashed"></div> */}
          <img
            className=""
            width="254"
            height="254"
            src="Ellipse 2312_90.png"
          ></img>
          <div className="absolute left-[29px] top-[159px] w-[196px] flex flex-wrap items-center justify-center gap-[4px]">
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                Worker
              </div>
            </div>
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                Game
              </div>
            </div>
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                Female
              </div>
            </div>
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                Single
              </div>
            </div>
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                MBTI
              </div>
            </div>
            <div className="flex flex-row items-center justify-center py-[6px] px-[8px] border-[2px] border-solid border-[#fff] rounded-[12px]">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#fff] text-center whitespace-nowrap">
                20s
              </div>
            </div>
          </div>
          <div className="absolute -translate-x-1/2 left-1/2 top-[44px] text-[26px] leading-[130%] font-['Toppan_Bunkyu_Gothic'] font-semibold text-[#fff] text-center whitespace-nowrap">
            Discussing
            <br />
            Graduate School
            <br />
            with Family
          </div>
          <div className="absolute left-[108px] top-[27px] text-[10px] leading-[100%] font-sans font-medium text-[#ffffff80] text-center whitespace-nowrap">
            CAREER
          </div>
        </div>
        <div className="absolute left-1/2 top-[380px] w-[328px] flex flex-col items-start justify-start gap-[21px] transform -translate-x-1/2">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <div
              className="relative w-[156px] h-[131px] shrink-0 bg-[#fff] rounded-[12px] overflow-hidden"
              onClick={goToBookMark}
            >
              <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                {translations.BOOKMARK}
              </div>
              <img
                className="absolute left-[59px] top-[20px]"
                width="38"
                height="68"
                src="Group 126115506012_110.png"
              ></img>
            </div>
            <div
              className="relative w-[156px] h-[131px] shrink-0 bg-[#fff] rounded-[12px] overflow-hidden"
              onClick={goToNote}
            >
              <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#000] text-center whitespace-nowrap">
                {translations.INCORRECT_ANSWER_NOTES}
              </div>
              <img
                className="absolute left-[55px] top-[26px]"
                width="45"
                height="57"
                src="Group 126115505912_119.png"
              ></img>
            </div>
          </div>
          {/* Carousel Section */}
          <div className="w-full h-[56px] mb-[25px] flex justify-center items-center">
            <Slider {...sliderSettings} className="w-full h-full">
              {images.map((image, index) => (
                <div key={index} className="w-full h-full">
                  <img
                    src={image}
                    alt={`Carousel slide ${index}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
            {parseInt(attendanceCount, 10) < 7 && (
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                  프로모션
                </div>
              </div>
            )}
            {parseInt(attendanceCount, 10) < 7 && (
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch h-[100px] shrink-0 flex flex-col items-center justify-center gap-[10px] bg-[#fff] rounded-tl-[12px] rounded-tr-[12px] rounded-br-0 rounded-bl-0">
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    무료체험 종료까지 남은 시간
                  </div>
                  <div className="text-[30px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                    {formatTime(time)}
                  </div>
                </div>
                <div
                  className="self-stretch h-[48px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-tl-0 rounded-tr-0 rounded-br-[12px] rounded-bl-[12px]"
                  onClick={serverAuth}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-bold text-[#fff] whitespace-nowrap">
                    이용권 구매하기
                  </div>
                </div>
              </div>
            )}
            <div className="w-[328px] flex flex-row items-center justify-between">
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                {translations._LESSON_STATISTICS}
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] rounded-[12px] overflow-hidden">
                <img
                  className="absolute left-[20px] top-[15px]"
                  width="50"
                  height="55"
                  src="Frame 126115527012_148.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[137px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000]">
                    {translations.ATTENDANCE_DAYS}
                  </div>
                  <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                    전체 출석한 횟수
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-end gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {attendanceCount ? attendanceCount : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    일
                  </div>
                </div>
              </div>
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] rounded-[12px] overflow-hidden">
                <img
                  className="absolute left-[20px] top-[15px]"
                  width="50"
                  height="55"
                  src="Frame 126115527112_164.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[127px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000]">
                    {translations.MY_ACCOMPLISHMENT}
                  </div>
                  <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                    지금까지 수강 완료한 챕터!
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-center gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {scriptCount ? scriptCount : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    강
                  </div>
                </div>
              </div>
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] rounded-[12px] overflow-hidden  mb-[30px]">
                <img
                  className="absolute -translate-y-1/2 left-[20px] top-1/2"
                  width="50"
                  height="55"
                  src="Frame 126115527212_179.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[71px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000]">
                    {translations.ACCURACY_RATE}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    나의 정답률은?
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-center gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {userQuizRating ? (userQuizRating * 100) | 0 : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-[16px] top-[24px] w-[130px] h-[34px] flex">
          <img
            className=""
            width="99"
            height="30"
            src="_레이어_112_77.png"
          ></img>
          <div className="absolute left-0 top-[45px] w-[130px] flex flex-row items-center justify-center py-[6px] px-[8px] bg-[#fff] rounded-[12px]">
            <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] text-center whitespace-nowrap">
              {gender === "무관"
                ? "첫만남! 반가워요!"
                : `출석 ${attendanceCount ? attendanceCount : "-"}일 달성!`}
            </div>
          </div>
          <img
            className="absolute left-[92px] top-[67px]"
            width="12"
            height="12"
            src="Vector 13112_201.png"
          ></img>
        </div>
        {/* <div className="absolute left-[38px] top-[85px] w-[284px] h-[284px] flex">
          <div className="absolute -translate-x-1/2 left-1/2 top-0 w-[284px] h-[284px] border-[7px] border-dashed border-[#fff] rounded-full opacity-[.2]"></div>
          <div className="absolute inset-0 border-dashed border-4 border-[#fff] rounded-full circular-dashed"></div>
        </div> */}
        <div className="absolute right-[16px] top-[24px] w-[102px] h-[36px] flex flex-row items-center justify-between">
          {/* First item (text and images) */}
          <div className="flex flex-row items-center justify-start">
            <img width="10" height="28" src="Subtract12_207.png" alt="icon" />
            <div className="self-stretch bg-[#fff] flex flex-row items-center justify-center">
              <div
                style={{ fontSize: textSize }}
                className="leading-[100%] font-sans font-bold text-[#3677f6] whitespace-nowrap"
              >
                {gender === "무관" ? "이용권 구매" : "Basic"}
              </div>
            </div>
            <img width="10" height="28" src="Subtract12_213.png" alt="icon" />
          </div>
          <div className="w-[10px]"></div>
          {/* Second item (circle with text "Aa") */}
          <LanguageSwitcher2></LanguageSwitcher2>
        </div>
      </div>
    </div>
  );
};

export default Home;
