// actions.js
export const ADD_ITEMS = "ADD_ITEMS";
export const SET_ITEMS = "SET_ITEMS";
export const SET_USER_ID = "SET_USER_ID";
export const CLEAR_USER_ID = "CLEAR_USER_ID";

export const addItems = (items) => ({
  type: ADD_ITEMS,
  payload: items,
});

export const setItems = (items) => ({
  type: SET_ITEMS,
  payload: items,
});

// Action creators for userId
export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: userId,
});

export const clearUserId = () => ({
  type: CLEAR_USER_ID,
});
