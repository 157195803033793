import React from "react";
import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate, useLocation } from "react-router-dom";

const MbtiStudylist = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { keywords, lis } = location.state || {};
  console.log(lis);
  const { translations } = useContext(LanguageContext);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoStudy = (id) => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/customize_script/${id}`);
        const fetchedLis = await response.json();
        if (response.ok) {
          console.log(fetchedLis);
          console.log(id); // Store fetched data in state
          navigate("/studyonboard", {
            state: { script: fetchedLis, id: id },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if translations have the item with the given id
    if (node.translations) {
      const foundTranslation = node.translations.find(
        (translation) => translation.id === id
      );
      if (foundTranslation) return foundTranslation;
    }

    // Recursively search in children
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        {/* Updated Item list Section */}
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            MBTI
          </h1>
          <LanguageSwitcher></LanguageSwitcher>
        </header>
        {/* Top Bar */}
        <div className="w-full h-[44px] flex items-center justify-between px-4 bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)]">
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center justify-center py-[5px] px-[8px] bg-[#fff] rounded-full">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] whitespace-nowrap">
                KEYWORD
              </div>
            </div>
            <div className="flex items-center gap-[4px]">
              {keywords.slice(0, keywords.length).map((keyword, index) => (
                <React.Fragment key={index}>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                    {keyword}
                  </div>
                  {index < keywords.length - 1 && ( // Check if it's not the last item to add the dot
                    <div className="w-[2px] h-[2px] bg-[#fff] rounded-full"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        {/* Sorting Options */}
        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              최신순
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              알파벳순
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
              별점순
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="w-full flex flex-col items-start bg-[#fff] overflow-y-auto">
          {/* 카드 */}
          {lis &&
            lis.length > 0 &&
            lis.map((item, index) => {
              // Check if `customizes` is defined and has at least 2 elements
              if (item.customizes && item.customizes.length > 1) {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0"
                    onClick={() =>
                      handleGoStudy(
                        item.customizes[
                          item.customizes.findIndex(
                            (cust) => cust.language === "eng"
                          )
                        ].easyId
                      )
                    }
                  >
                    {/* Titles */}
                    <div className="flex flex-col gap-[17px]">
                      <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center">
                          {/* English title */}
                          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] break-words">
                            {item.customizes[0].title} {/* English title */}
                          </div>
                        </div>
                        {/* Korean title */}
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] break-words">
                          {item.customizes[1].title} {/* Korean title */}
                        </div>
                      </div>

                      {/* Tags */}
                      <div className="flex items-center gap-[3px]">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {data
                                  ? findItemById(tag.id, data)?.name
                                  : "Loading..."}
                                {/* Display the tag id */}
                              </div>
                              {tagIndex < item.tag.length - 1 && (
                                <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Rating */}
                    <div className="flex items-center gap-[4px]">
                      <img
                        width="15"
                        height="15"
                        src={`star.png`}
                        alt="rating star"
                      />
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] whitespace-nowrap">
                        {item.averageRating.toFixed(1)}{" "}
                        {/* Display the rating */}
                      </div>
                    </div>
                  </div>
                );
              } else {
                // Optionally handle cases where `item.customizes` is not as expected
                // return (
                //   <div
                //     key={index}
                //     className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0"
                //   >
                //     <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333]">
                //       Data missing
                //     </div>
                //   </div>
                // );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default MbtiStudylist;
