import { useMemo } from "react";
import Btn from "./Btn";
import PropTypes from "prop-types";
import styles from "./BottomButtons.module.css";

const BottomButtons = ({
  className = "",
  prop,
  divMinWidth,
  prop1,
  to,
  to1,
  onClick,
}) => {
  const div2Style = useMemo(() => {
    return {
      minWidth: divMinWidth,
    };
  }, [divMinWidth]);

  return (
    <section className={[styles.bottomButtons, className].join(" ")}>
      <div className={styles.findIdButton}>
        <div className={styles.div} style={div2Style}>
          <a href={to} style={{ textDecoration: "none", color: "inherit" }}>
            {prop}
          </a>
        </div>
      </div>
      <div className={styles.loginButton}>
        <Btn
          btnAlignSelf="unset"
          btnBorder="unset"
          btnFlex="1"
          btnBackground="linear-gradient(64.95deg, #61e3eb, #3677f6)"
          prop={prop1}
          divWidth="unset"
          divFontWeight="bold"
          divColor="#fff"
          divMinWidth="28px"
          to={to1}
          onClick={onClick}
        />
      </div>
    </section>
  );
};

BottomButtons.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,
  prop1: PropTypes.string,

  /** Style props */
  divMinWidth: PropTypes.any,
};

export default BottomButtons;
