import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
const VocabList = () => {
  const { translations } = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8&oq=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgzMDAzajBqN6gCALACAA&sourceid=chrome&ie=UTF-8";
  };

  const handleNextClick = () => {
    navigate("/lang");
  };
  const handleGoAssociated = () => {
    navigate("/associated");
  };

  const { item, type } = location.state || {}; // Destructure item from the location state
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] overflow-x-hidden">
      <div className="relative w-[100%] bg-[#faf8f6]">
        {/* Header */}
        <div className="relative flex items-center h-[50px]">
          {/* Icon 1 */}
          <div className="absolute left-[10px] top-1/2 transform -translate-y-[90%] w-[12px] h-[12px] z-50">
            <img
              width="15"
              height="15"
              src="back.png"
              alt="icon"
              onClick={handleGoBack}
            />
          </div>
          {/* Text */}
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-50 font-sans text-center font-bold">
            단어장
          </div>

          {/* Icon 2 */}
          <div
            className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-50"
            style={{ left: "78%" }}
          >
            <img
              width="40"
              height="40"
              src="link2.png"
              alt="icon"
              onClick={handleLink}
            />
          </div>

          {/* Icon 3 */}
          <div
            className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-50"
            style={{ left: "88%" }}
          >
            <img
              width="45"
              height="45"
              src="ddd2.png"
              alt="icon"
              onClick={handleGoBack}
            />
          </div>
        </div>
        <img
          className="absolute left-[80%] top-[615px]"
          width="71"
          height="72"
          src="Group 126115515426_205.png"
        ></img>
        <div className="absolute -translate-x-1/2 left-1/2 top-[110px] w-[91%] flex flex-col items-start justify-start gap-[18px]">
          <div className="relative self-stretch h-[253px] shrink-0">
            <div className="absolute left-0 top-0 w-[100%] h-[253px] bg-[#fff] rounded-lg shadow-lg"></div>
            <div className="absolute -translate-x-1/2 left-1/2 top-[14px] w-[100%] h-[220px] flex flex-col items-start justify-between">
              <div className="flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-center justify-start px-[16px] gap-[6px]">
                  <div className="flex flex-row items-center justify-center py-[4px] px-[6px] bg-[#ffeee7] rounded-[11px]">
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#f67239] text-right whitespace-nowrap">
                      Academic English{" "}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center py-[4px] px-[6px] bg-[#e9ecef] rounded-[11px]">
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#383c4b] text-right whitespace-nowrap">
                      {type}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[10px] px-[16px]">
                  <div className="text-[32px] leading-[100%] font-sans font-bold text-[#25272f] text-justify whitespace-nowrap">
                    {item}
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-justify whitespace-nowrap">
                    ~에 대해 고려하다
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col  items-start justify-start gap-[6px] px-[6px] py-[8px] w-[90%] border-[solid] border-#e9ecef border-t border-px_0_0] mx-auto">
                <div className="self-stretch text-[14px] leading-[22px] font-sans font-bold text-[#000]">
                  Have you thought about where you <br />
                  want to go this weekend?
                </div>
                <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                  이번 주말에 어디 가고 싶은지 생각해 봤어?
                </div>
              </div>
            </div>
            <div className="absolute right-0 top-0 flex flex-row items-center justify-start">
              <LanguageSwitcher></LanguageSwitcher>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[18px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000]">
                유의어
              </div>
              <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] rounded-[8px]">
                <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                  Consider
                </div>
                <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                  고려하다
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000]">
                반의어
              </div>
              <div className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] rounded-[8px]">
                <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
                  Ignore
                </div>
                <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                  무시하다
                </div>
              </div>
            </div>
            <div
              className="w-[100%] h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] border-[1px] border-solid border-[#383c4b] rounded-[12px] "
              onClick={handleGoAssociated}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#383c4b] whitespace-nowrap">
                연계학습하기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VocabList;
