import React from 'react';

const TermsAndCondigions = () => {
    return (
        <div className='container mx-auto p-4'>
            <h1 className="text-3xl font-bold mb-4 text-center">이용약관</h1>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제1장 총칙</h2>
                <textarea
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap" 
                    readOnly
                    value={`제1조 (목적)
이 약관은 (주)이지브레인소프트(이하 “회사”)가 온라인으로 제공하는 학습 콘텐츠 및 이에 부수된 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 

제2조 (용어의 정의) 
① 이 약관에서 사용하는 정의는 다음과 같습니다. 
1. “회사”라 함은 온라인을 통하여 교육 서비스를 제공하는 사업자를 의미합니다. 
2. “회원”이라 함은 본 약관에 동의하고 서비스 이용 자격을 부여받은 자를 의미합니다. 
3. “서비스”라 함은 회사가 회원에게 온라인으로 제공하는 콘텐츠 및 이에 부수된 제반 서비스를 의미합니다. 
4. “계정(ID)”이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다. 
5. “계정정보“라 함은 회원의 계정, 비밀번호, 성명 등 회원이 회사에 제공한 일반정보 및 이용정보, 이용요금 결제상태 등 생성정보를 통칭합니다. 
6. “비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수문자의 조합을 의미합니다. 
7. “게시물”이라 함은 회원이 서비스를 이용함에 있어 회원이 게시한 문자, 문서, 그림, 음성, 영상 또는 이들의 조합으로 이루어진 모든 정보를 말합니다. 
② 이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에 의합니다. 

제3조 (회사정보 등의 제공) 
회사는 다음 각 호의 사항을 회원가입 화면이나 홈페이지에 게시하여, 회원이 이를 쉽게 알 수 있도록 합니다. 다만, 개인정보취급방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 
1. 상호 및 대표자의 성명 
2. 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다) 및 전자우편주소 
3. 전화번호, 모사전송번호(Fax 번호) 
4. 사업자등록번호, 통신판매업 신고번호 
5. 개인정보취급방침 
6. 서비스 이용약관 

제4조 (약관의 명시와 개정) 
① 회사는 이 약관의 내용을 회원이 알 수 있도록  회원가입 화면이나 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다. 
② 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다. 
③ 회사는 서비스를 이용하고자 하는 자(이하 “이용자”라 한다)가 약관의 내용을 쉽게 알 수 있도록 작성하고 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 과오납금의 환급, 계약 해제ㆍ해지, 회사의 면책사항 및 회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 굵은 글씨 등으로 처리하거나 별도의 연결화면 또는 팝업화면 등을 제공하고 이용자의 동의를 얻도록 합니다. 
④ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 
⑤ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다. 
⑥ 회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일 까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다. 
⑦ 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다. 

제5조 (약관 외 준칙) 
이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의 규제에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」,「콘텐츠산업진흥법」 등 관련 법령에 따릅니다. 

제6조 (운영정책) 
① 약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 사이트 내 질서를 유지하기 위하여 회사는 약관에서 구체적 범위를 정하여 위임한 사항을 서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할 수 있습니다. 
② 회사는 운영정책의 내용을 회원이 알 수 있도록 초기 화면이나 서비스 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지하여야 합니다. 
③ 회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는  것과 동일한 효력이 발생하는 운영정책 개정의 경우에는 제4조의 절차에 따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의 방법으로 사전에 공지합니다. 
1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우 
2. 회원의 권리·의무와 관련 없는 사항을 개정하는 경우 
3. 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측 가능한 범위 내에서 운영정책을 개정하는 경우`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제2장 이용계약의 체결</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`제7조 (이용신청 및 방법) 
① 회사가 제공하는 서비스를 이용하고자 하는 자는 회사가 초기 화면이나 서비스 홈페이지에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다. 
② 이용자는 이용 신청 시 회사에서 요구하는 제반 정보를 제공하여야 합니다. 
③ 이용자는 제1항의 이용 신청 시 본인의 실명 및 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 환급 없이 이용계약을 취소하거나 해지할 수 있습니다. 
④ 청소년(18세 미만의 자로서 「초·중등교육법」제2조의 규정에 의한 고등학교에 재학 중인 학생을 포함한다)이 이용신청을 할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는 법률 및 시행령에 따라 회사가 제공하는 방법에 따르도록 합니다. 
 
제8조 (이용신청의 승낙과 제한) 
① 회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가 실명 및 실제 정보를 정확히 기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다. 
② 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다. 
1. 제7조에 위반하여 이용신청을 하는 경우 
2. 이용요금을 납부하지 않거나 잘못 납부하여 확인할 수 없는 경우 
3. 청소년(제7조 제4항의 청소년과 같다)이 법정대리인의 동의를 얻지 아니하거나 동의를 얻었음을 확인할 수 없는 경우 
4. 최근 3개월 내 이용제한 기록이 있는 이용자가 이용신청을 하는 경우 
5. 제3자의 신용카드, 유/무선 전화, 은행 계좌 등을 무단으로 이용 또는 도용하여 서비스 이용요금을 결제하는 경우 
6. 대한민국 이외의 국가 중 회사에서 아직 서비스를 제공할 것으로 결정하지 않은 국가에서 서비스를 이용하는 경우로 회사가 해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는 회원에 대한 서비스 제공과 관련하여 서비스제공을 제한할 필요가 있는 경우 
7.「정보통신망 이용촉진  및 정보보호 등에 관한 법률」 및 그 밖의 관계 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우 
8. 그 밖에 1호 내지 7호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우 
③ 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다. 
1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우 
2. 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우 
3. 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우 

제9조 (회원 계정(ID) 및 비밀번호) 
① 회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해 회원이 선정한 일정한 문자, 숫자 또는 특수문자의 조합을 계정으로 부여합니다. 
② 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원 관리업무를 수행합니다. 
③ 회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다. 
④ 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가능합니다. 
⑤ 회원은 정기적으로 비밀번호를 변경하여야 합니다. 

제10조 (회원 정보의 제공 및 변경) 
① 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다. 
② 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 계정(ID) 등은 수정이 불가능합니다. 
③ 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다. 
④ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다. 

제11조 (개인정보의 보호 및 관리) 
① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관계법령 및 회사가 별도로 고지하는 개인정보취급방침이 적용됩니다. 
② 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및 서비스별 웹사이트에서 단순히 링크된 제3자 제공의 서비스에 대하여는 회사의 개인정보취급방침이 적용되지 않습니다. 
③ 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제3장 계약 당사자의 의무</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`제12조 (회사의 의무) 
① 회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다. 
② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보취급방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다. 
③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다. 
 
제13조 (회원의 의무) 
① 회원은 다음 행위를 하여서는 안 됩니다. 
1. 신청 또는 변경 시 허위내용의 기재 
2. 타인의 정보도용 
3. 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위 
4. 회사가 게시한 정보의 변경 
5. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시 
6. 회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위 
7. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 
8. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 
9. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위 

10. 데이터(계정, 콘텐츠 등)를 유상으로 처분(양도, 매매 등)하거나 권리의 객체(담보제공, 대여 등)로 하는 행위 

11. 10호의 행위를 유도하거나 광고하는 행위 

12. 회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로 서비스를 사용하는 행위 

13. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위 
② 회원은 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다. 
③ 회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영정책에서 정할 수 있으며, 회원은 이를 준수할 의무가 있습니다. 
1. 회원의 계정명, 닉네임명에 대한 제한 
2. 채팅내용과 방법에 대한 제한 
3. 게시판이용에 대한 제한 
4. 이용방법에 대한 제한 
5. 기타 회원의 서비스 이용에 대한 본질적 권리를 침해하지 않는 범위  에서 회사가 서비스 운영상 필요하다고 인정되는 사항`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제4장 서비스 이용</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`제14조 (서비스의 변경 및 내용수정) 
① 회원은 회사가 제공하는 서비스를 이 약관, 운영정책 및 회사가 설정한 규칙에 따라 이용할 수 있습니다. 
② 회사가 서비스를 통하여 회원에게 제공하는 콘텐츠는 회사가 제작한 것으로서 회사는 콘텐츠 내용의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다. 
③ 회사는 콘텐츠를 보호하고 서비스의 질서를 유지하기 위하여 필요한 조치를 취합니다. 
④ 회사가 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 서비스 수정(패치)을 할 수 있으며, 서비스 수정(패치)을 하는 경우에는 변경 후 해당 사이트 등을 통하여 공지합니다. 
 
제15조 (서비스의 제공 및 중단 등) 
① 서비스는 회사의 영업방침에 따라 정해진 시간동안 제공합니다. 회사는 서비스 제공시간을 초기화면이나 서비스 홈페이지에 적절한 방법으로 안내합니다. 
② 제1항에도 불구하고, 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안 회사는 서비스를 제공할 의무가 없습니다. 
1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 콘텐츠 내용이나 서비스의 수정을 위하여 필요한 경우 
2. 해킹 등의 전자적 침해사고, 통신사고, 회원들의 비정상적인 서비스 이용행태, 미처 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우 
3. 관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는 경우 
4. 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우 
5. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우 
③ 회사는 제2항 제1호의 경우, 매주 또는 격주 단위로 일정 시간을 정하여 서비스를 중지할 수 있습니다. 이 경우 회사는 최소한 24시간 전에 그 사실을 회원에게 초기 화면이나 서비스 홈페이지에 고지합니다. 
④ 제2항 제2호의 경우, 회사는 사전 고지 없이 서비스를 일시 중지할 수 있습니다. 회사는 이러한 경우 그 사실을 초기 화면이나 서비스 홈페이지에 사후 고지할 수 있습니다. 
⑤ 회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다. 
⑥ 회사는 회사가 제공하는 유료서비스 이용과 관련하여 회사의 귀책사유로 사전고지 없이 1일 4시간(누적시간) 이상 연속하여 서비스가 중지되거나 장애가 발생한 경우 계속적 이용계약 계정에 한하여 서비스 중지ㆍ장애시간에 해당하는 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다. 다만, 회사가 서버점검 등의 사유로 서비스 중지ㆍ장애를 사전에 고지하였으나, 서비스 중지ㆍ장애시간이 10시간이 초과하는 경우에는 그 초과된 시간만큼 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다. 
⑦ 제2항 제3호 내지 제5호의 경우에 회사는 기술상?운영상 필요에 의해 서비스 전부를 중단할 수 있으며, 30일전에 홈페이지에 이를 공지하고 서비스의 제공을 중단할 수 있습니다. 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지를 할 수 있습니다. 
⑧ 회사가 제7항에 따라 서비스를 종료하는 경우 회원은 무료서비스 및 사용 기간이 남아 있지 않은 유료서비스?계속적 유료 이용계약?기간제 유료아이템에 대하여 손해배상을 청구할 수 없습니다. 
 
제16조 (정보의 제공) 
회사는 다음의 사항을 초기 화면이나 인터넷 웹사이트인 서비스 홈페이지에 회원이 알기 쉽게 표시합니다. 
1. 상호 
2. 서비스 명칭 
3. 연락처 
4. 주소 
5. 사업자등록번호 
6. 기타 회사가 필요하다고 인정하는 사항 
 
제17조 (정보의 수집 등) 
① 회사는 서비스 내에서 회원 간에 이루어지는 모든 게시물, 채팅내용을 저장ㆍ보관할 수 있습니다. 회사는 회원간의 분쟁 조정, 민원 처리 또는 질서의 유지를 위하여 회사가 필요하다고 판단하는 경우에 한하여 본 정보를 열람하도록 할 것이며, 본 정보는 회사만이 보유하고 법령으로 권한을 부여 받지 아니한 제3자는 절대로 열람할 수 없습니다. 회사는 해당 정보를 열람하기 전에 채팅정보의 열람이 필요한 사유 및 열람 범위를 개인에게 사전 고지하기로 합니다. 다만, 계정도용, 현금거래, 언어폭력, 서비스 내 사기 등 기망행위, 버그 악용, 기타 현행 법령 위반행위 및 이 약관 제13조에서 정하는 중대한 약관위반 행위의 조사, 처리, 확인 및 이의 구제와 관련하여 회원의 채팅 정보를 열람해야 할 필요가 있는 경우에는, 사후에 채팅정보가 열람된 개인들에 대하여 열람한 사유와 열람한 정보 중 본인과 관련된 부분을 고지하기로 합니다. 
② 회사는 서비스 운영 및 프로그램 안정화 등 서비스 품질 개선을 위하여 회원 PC 등 단말기 설정 및 사양 정보를 수집·활용할 수 있습니다. 
 
제18조 (이용상품) 
① 회사는 회원이 별도 비용지급 없이 이용할 수 있는 서비스(이하 “무료서비스”라 한다)와 회사가 미리 책정한 요금을 지불하고 이용하는 서비스(이하 “유료서비스”라 한다)를 제공할 수 있으며, 회원은 서비스를 선택하여 이용할 수 있습니다. 
② 서비스의 이용에 대한 대금지급방법은 회사가 미리 정한 방법을 통하여 할 수 있습니다. 
③ 제1항의 유료서비스 이용과 관련하여, 회원이 선택한 유료서비스 이용신청 후, 다음 각 호에 해당하는 사항을 준수하여야 합니다. 
1. 유료서비스 이용을 신청한 회원은 서비스 이용요금을 성실히 납부하여야 합니다. 
2. 유료서비스 이용을 신청한 회원은 서비스 이용요금을 신청 후 회사에서 제공하는 방법으로 지급하여야 합니다. 단, 무통장입금의 방식으로 신청한 회원은 신청 완료 후 3일 이내에 해당 금액을 입금하여야 하며, 3일 이내에 입금하지 않을 시에는 그 신청을 철회한 것으로 봅니다. 
④ 회사는 미성년자인 회원이 결제가 필요한 유료 서비스를 이용하고자 하는 경우 부모 등 법정 대리인의 동의를 얻어야 하고, 동의 없이 이루어진 유료 서비스 이용은 법정대리인이 취소할 수 있다는 내용을 유료 서비스 이용을 위한 결제 전에 고지하도록 합니다. 
 
제19조 (사이버 포인트) 
① 회사는 회원의 콘텐츠 이용실적에 따라 사이버 포인트를 제공할 수 있으며, 회사가 정한 유효기간 내에 사용되지 않은 사이버 포인트는 소멸될 수 있습니다. 
② 사이버 포인트의 적립에 오류가 있을 경우 회원은 상당기간 내에 정정 신청을 할 수 있으며, 회사는 확인절차를 거친 후 결과에 대하여 고지하고 필요한 경우 사이버 포인트에 정정 조치를 취할 수 있습니다. 
③ 회사는 경영상, 기술상 이유로 사전고지 후 사이버 포인트 서비스를 종료할 수 있으며, 회사는 사전고지를 최소한 1개월 전에 하여야 합니다. 이 경우 사전에 고지한 서비스 종료일까지 사용되지 않은 기적립된 사이버 포인트는 소멸됩니다. 
 
제20조 (저작권 등의 귀속) 
① 서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타 지적재산권은 회사의 소유입니다. 
② 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. 
③ 회원은 서비스 내에서 보여지거나 서비스와 관련하여 회원 또는 다른 이용자가 시스템 또는 서비스를 통해 업로드 또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및 정보(이하 이용자 콘텐츠 라 한다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다. 
1. 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음) 
2. 이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지 않음 
④ 서비스 내에서 보여 지지 않고 서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에의 게시물)에 대하여 회사는 회원의 명시적인 동의가 없이 상업적으로 이용하지 않으며, 회원은 언제든지 이러한 이용자 콘텐츠를 삭제할 수 있습니다. 
⑤ 회사는 회원이 게시하거나 등록하는 서비스 내의 게시물, 게시 내용에 대해 제 13조에서 규정하는 금지행위에 해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다. 
⑥회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고, 이를 신청인에게 통지합니다. 
⑦ 제3항은 회사가 서비스를 운영하는 동안 유효하며 회원탈퇴 후에도 지속적으로 적용됩니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제5장 청약철회, 계약 해제·해지 및 이용제한</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`제21조 (청약의 철회) 
① 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 구매일 또는 유료서비스 이용가능일로부터 7일 이내에는 청약의 철회를 할 수 있습니다. 
②회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회 등을 할 수 없습니다. 
1. 회원에게 책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우 
2. 회원이 재화를 사용 또는 일부 소비한 경우 
3. 시간이 지나 다시 판매하기 곤란할 경우 
4. 복제 가능한 재화 등의 포장을 훼손한 경우 
5. 그 밖에 거래의 안전을 위하여 법령으로 정하는 경우 
③ 회사는 제2항 제2호부터 4호까지 규정에 따라 청약철회 등이 불가능한 재화 등의 경우에는 그 사실을 재화 등의 포장이나 그밖에 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나 시험 사용 상품을 제공하는 등의 방법으로 청약철회 등의 권리 행사가 방해받지 아니하도록 조치하여야 합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 제2호 내지 제4호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다. 
④ 회원은 제1항 내지 제3항의 규정에도 불구하고 유료서비스의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 구매일 또는 유료서비스 이용가능일로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다. 
⑤ 회원은 구두 또는 서면(전자문서 포함), 전자우편으로 청약철회를 할 수 있습니다. 
 
제22조(청약 철회 등의 효과) 
① 회원이 청약철회를 한 경우 회사는 지체 없이 회원의 유료서비스를 회수 또는 삭제하고 유료서비스를 회수 또는 삭제한 날로부터 3영업일 이내에 지급받은 대금을 환급합니다. 
② 이 경우 회사가 회원에게 환급을 지연한 때에는 그 지연기간에 대하여 전자상거래 등에서의 소비자보호에 관한 법률 및 시행령에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다. 
③ 회사는 위 대금을 환급함에 있어서 회원이 신용카드나 그 밖에 전자상거래 등에서의 소비자보호에 관한 법률 시행령으로 정하는 결제수단으로 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만 회사가 결제업자로부터 이미 대금을 지급받은 때에는 이를 결제업자에게 환불하고 이를 소비자에게 통지합니다. 
④ 회사는 이미 재화 등이 일부 사용되거나 일부 소비된 경우에는 그 재화 등의 일부 사용 또는 일부 소비에 의하여 회원이 얻은 이익 또는 그 재화 등의 공급에 든 비용에 상당하는 금액을 회원에게 청구할 수 있습니다. 
⑤ 회원이 청약철회를 한 경우 재화 등의 반환에 필요한 비용은 회원이 부담하고, 회사는 회원에게 청약철회를 이유로 위약금 또는 손해배상을 청구할 수 없습니다. 

제23조 (회원의 해제 및 해지) 
① 회원은 서비스 이용계약을 해지(이하  회원탈퇴 라 한다)할 수 있습니다. 회원이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를 확인할 수 있으며, 해당 회원이 본인으로 확인되는 경우에 회원의 신청에 따른 조치를 취합니다. 
② 회원이 회원탈퇴를 원하는 경우에는 고객센터 및 서비스 내 회원탈퇴 절차를 통하여 회원탈퇴를 할 수 있습니다. 
 
제24조 (회사의 해제 및 해지) 
① 회사는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에 대한 사전 통보 후 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수 있습니다. 
② 회사가 이용계약을 해지하는 경우 회사는 회원에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다. 

1. 해지사유 

2. 해지일 
③ 제1항 단서의 경우, 회원은 유료서비스의 사용권한을 상실하고 이로 인한 환불 및 손해배상을 청구할 수 없습니다. 
 
제25조 (회원에 대한 서비스 이용제한) 
① 회사는 회원에게 다음 각 호의 구분에 따라 회원의 서비스 이용을 제한할 수 있습니다. 이용제한이 이루어지는 구체적인 회원의 의무위반 사유는 제27조에 따라 개별 서비스의 운영정책에서 정하기로 합니다. 
1. 일부 권한 제한 : 일정기간 채팅 등 일정 권한을 제한 
2. 이용제한 : 일정기간 또는 영구히 회원 이용을 제한 
3. 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한 
4. 회원 이용제한 : 일정기간 또는 영구히 회원의 서비스 이용을 제한 
② 회사의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다. 
 
제26조 (잠정조치로서의 이용제한) 
① 회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할 수 있습니다. 
1. 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우 
2. 불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는 경우 
3. 그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우 
② 제1항의 경우 회사는 조사가 완료된 후 서비스 이용 기간에 비례하여 일정액을 지급하여 이용하는 회원에게 정지된 기간만큼 회원의 서비스 이용기간을 연장합니다. 다만, 제1항에 의한 위법행위자로 판명된 경우에는 그러하지 아니합니다. 
 
제27조 (이용제한의 사유와 절차) 
① 회사는 위반행위의 내용, 정도, 횟수, 결과 등 제반사정을 고려하여 이용제한이 이루어지는 구체적인 사유 및 절차를 운영정책으로 정합니다. 
② 회사가 제25조에서 정한 이용제한을 하는 경우에는 회원에게 서면 또는 전자우편이나 초기 화면 또는 서비스 홈페이지에 게재하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다. 
1. 이용제한 사유 
2. 이용제한 유형 및 기간 
3. 이용제한에 대한 이의신청 방법 
 
제28조 (이용제한에 대한 이의신청 절차) 
① 회원이 회사의 이용제한에 불복하고자 할 때에는 통보를 받은 날로부터 15일 이내에 회사의 이용제한에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다. 
② 제1항의 이의신청서를 접수한 회사는 접수한 날로부터 15일 이내에 회원의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 15일 이내에 답변이 곤란한 경우 회원에게 그 사유와 처리일정을 통보합니다. 
③ 회사는 위 답변 내용에 따라 상응하는 조치를 취하여야 합니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>제6장 손해배상 및 환불 등</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`제29조 (손해배상) 
① 회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다. 
② 회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다 
 
제30조 (환불) 
① 회원이 직접 구매한 캐쉬의 환불을 요청하면 이를 환불 받을 수 있습니다. 환불 시에는 은행이체 및 결제대행 수수료 등의 사유로 현재 남아 있는 캐쉬 잔액의 10%이내 금액 또는 캐쉬잔액이 10,000원 이내인 경우에는 일정금액을 공제하고 남은 금액을 환불하여 드립니다. 일정금액을 공제하는 경우 공제 후 남은 금액이 공제금액보다 작은 경우에는 환불할 수 없습니다. 
② 계속적 이용계약의 경우에는 상품가액에서 이용자가 이미 사용한 부분에 해당하는 금액(각 상품별 사용금액 산정기준은 상품 구매 시 별도 고지함)을 제외하고 남은 금액에서 남은금액의 10% 이내 금액 또는 남은 금액이 10,000원 이내인 경우에는 일정금액을 공제한 후 나머지 금액을 환불해 드립니다. 일정금액을 공제하는 경우 공제 후 남은 금액이 공제금액보다 작은 경우에는 환불할 수 없습니다. 
③ 현행법령 및 중대한 약관 위반 등 회원의 귀책사유로 이용계약을 해지하는 경우 환불이 제한될 수 있습니다. 
 
제31조 (회사의 면책) 
① 회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다. 
②회사는 회원의 귀책사유로 인한 서비스의 중지, 이용장애 및 계약해지에 대하여 책임이 면제됩니다. 
③회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
④회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑤회사는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다. 
⑥회사는 회원 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑦회사는 회원 상호간 또는 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다. 
⑧회사가 제공하는 서비스 중 무료서비스의 경우에는 회사의 고의 또는 중대한 과실이 없는 한 회사는 손해배상을 하지 않습니다. 
⑨본 서비스 중 일부의 서비스는 다른 사업자가 제공하는 서비스를 통하여 제공될 수 있으며, 회사는 다른 사업자가 제공하는 서비스로 인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑩회사는 회원이 서비스를 이용하며 기대하는 성적 향상 등의 결과를 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑪회사는 회원의 서비스 상 사이버 자산(포인트), 등급/내공 손실에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑫회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다. 
⑬회사는 관련 법령, 정부 정책 등에 따라 서비스 또는 회원에 따라 서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다. 
 
제32조 (회원의 고충처리 및 분쟁해결) 
①회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 초기화면이나 서비스 홈페이지에서 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을 운영합니다. 
②회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 홈페이지에 공지하거나 전자우편, 전화 또는 서면 등으로 통보합니다. 
③ 회사와 회원 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다. 
 
제33조 (회원에 대한 통지) 
① 회사가 회원에게 통지를 하는 경우 회원이 지정한 전자우편주소, 전자메모 등으로 할 수 있습니다. 
② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 회사의 사이트의 초기화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다. 
 
제34조 (재판권 및 준거법) 본 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우, 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.`}
                />
            </section>
        </div>
    );
}

export default TermsAndCondigions;