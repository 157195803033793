import axios from 'axios';
import API_BASE_URL from '../../config';

// Tree 조회
export const fetchTreeNodes = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/tree-nodes/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching tree nodes:', error);
        throw error;
    }
};

// Tree 생성
export const createTreeNode = async (translations, parentId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/tree-nodes`, {
            translations,
            parentId
        });
        return response.data;
    } catch (error) {
        console.error('Error creating tree node:', error);
        throw error;
    }
};