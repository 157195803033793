import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Navigation from '../../components/admin/Navigation';
import API_BASE_URL from '../../config';

const InputJson = () => {
  const [dataList, setDataList] = useState([]);  // 데이터 목록
  const [title, setTitle] = useState('');        // 입력할 제목
  const [content, setContent] = useState('');    // 입력할 내용
  const [selectedData, setSelectedData] = useState(null);  // 선택된 데이터 상세 정보
  const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
  const [totalItems, setTotalItems] = useState(0);  // 전체 항목 수
  const itemsPerPage = 10;  // 페이지 당 항목 수

  useEffect(() => {
    fetchDataList(currentPage);  // 페이지네이션을 위한 데이터 불러오기
  }, [currentPage]);

  // 데이터 목록을 가져오는 함수, 페이지네이션 적용
  const fetchDataList = async (page) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/total-data?page=${page}&limit=${itemsPerPage}`);
      setDataList(response.data.data || []);
      setTotalItems(response.data.total || 0); 
    } catch (error) {
      console.error('데이터 로드 실패:', error);
      setDataList([]);
      setTotalItems(0);
    }
  };

  // 데이터 추가
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const jsonContent = JSON.stringify(JSON.parse(content), null, 2); // 입력 내용을 JSON으로 파싱하고 다시 문자열로 변환
      const jsonData = { title, content: jsonContent };
      await axios.post(`${API_BASE_URL}/total-data`, jsonData);
      alert('데이터가 성공적으로 저장되었습니다!');
      fetchDataList(currentPage);  // 데이터 목록 갱신
      setTitle('');  // 입력 필드 초기화
      setContent('');
    } catch (error) {
      console.error('데이터 저장 실패:', error);
      alert('데이터 저장 중 오류 발생. 올바른 JSON 형식인지 확인해주세요.');
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  // 데이터 상세 조회 함수
  const fetchDetailData = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/total-data/${id}`);
      const data = response.data;
      data.content = JSON.stringify(JSON.parse(data.content), null, 2); // JSON 포맷을 유지한 문자열로 변환
      setSelectedData(data);
    } catch (error) {
      console.error('세부 정보 로드 실패:', error);
    }
  };

  // 데이터 수정
  const handleUpdateData = async () => {
    try {
      const formattedContent = JSON.stringify(JSON.parse(selectedData.content), null, 2); // JSON 포맷을 유지한 문자열로 변환
      const updatedData = { title: selectedData.title, content: formattedContent };
      await axios.put(`${API_BASE_URL}/total-data/${selectedData.id}`, updatedData);
      alert('데이터 업데이트 성공!');
      fetchDataList(currentPage);  // 데이터 목록 갱신
      setSelectedData(null);  // 선택 해제
    } catch (error) {
      console.error('업데이트 실패:', error);
      alert('데이터 업데이트 중 오류가 발생했습니다. 올바른 JSON 형식인지 확인해주세요.');
    }
  };

  // 데이터 삭제
  const handleDeleteData = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/total-data/${id}`);
      alert('데이터 삭제 성공!');
      fetchDataList(currentPage);  // 데이터 목록 갱신
      setSelectedData(null);  // 선택 해제
    } catch (error) {
      console.error('삭제 실패:', error);
    }
  };

  // 페이지 클릭 처리
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1; // 페이지 번호는 0부터 시작하므로 1을 더함
    setCurrentPage(selectedPage);
  };

  return (
  <div>
    <Navigation />
    <div className='container mx-auto p-6'>
      <h1 className="text-3xl font-bold mb-6">Customize, Situation, Psychology Json</h1>
      <table className="w-full bg-white shadow-md rounded-md mb-6">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="p-3 text-left">No</th>
            <th className="p-3 text-left">Title</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((item, index) => (
            <tr key={item.id} className="border-b hover:bg-gray-50 cursor-pointer" onClick={() => fetchDetailData(item.id)}>
              <td className="p-3">{index + 1 + (currentPage - 1) * 10}</td>
              <td className="p-3">{item.title}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 페이지네이션 컴포넌트 */}
      <div className="flex justify-center mt-4">
        <ReactPaginate
          previousLabel={'<'} // 이전 페이지 버튼
          nextLabel={'>'} // 다음 페이지 버튼
          breakLabel={'...'} // 페이지 구분자
          pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
          marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
          pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
          onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
          containerClassName={'pagination flex space-x-2'} // 페이지네이션 컨테이너 스타일
          activeClassName={'active bg-gray-300'} // 현재 페이지 스타일
          pageClassName={'border border-gray-300 rounded-md px-3 py-1'} // 페이지 버튼 스타일
          previousClassName={'border border-gray-300 rounded-md px-3 py-1'} // 이전 버튼 스타일
          nextClassName={'border border-gray-300 rounded-md px-3 py-1'} // 다음 버튼 스타일
          breakClassName={'border border-gray-300 rounded-md px-3 py-1'} // 구분자 스타일
        />
      </div>

      {/*title content 입력 */}
      <form onSubmit={handleFormSubmit} className="bg-white shadow-md rounded-md p-6 mb-8">
        <div className="mb-4">
            <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                placeholder="제목 입력"
                className="w-full p-3 border rounded-md"
            />
        </div>
        <div className="mb-4">
            <textarea
                value={content}
                onChange={handleContentChange}
                rows="5"
                cols="30"
                placeholder="내용 입력 (JSON 형식)"
                className="w-full p-3 border rounded-md"
            />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md w-full">
            Submit Data
        </button>
      </form>

      {selectedData && (
        <div className="bg-white shadow-md rounded-md p-6">
          <h2 className="text-2xl font-semibold mb-4">Edit Data</h2>
          <div className="mb-4">
            <input
                type="text"
                value={selectedData.title}
                onChange={(e) => setSelectedData({ ...selectedData, title: e.target.value })}
                className="w-full p-3 border rounded-md"
            />
          </div>
          <div className='mb-4'>
            <textarea
                value={selectedData.content}
                onChange={(e) => setSelectedData({ ...selectedData, content: e.target.value })}
                rows="5"
                cols="30"
                className="w-full p-3 border rounded-md"
            />
          </div>
          <div className="flex justify-between">
            <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={() => handleDeleteData(selectedData.id)}>
                Delete Data
            </button>
            <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleUpdateData}>
                Update Data
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default InputJson;